import { QueryStringAddon } from "wretch/addons/queryString";
import { WretchError, WretchResponse, WretchResponseChain } from "wretch/types";
import { msalConfig } from "../auth/auth";
import { authMiddleware } from "../auth/middleware";
import { CustomError } from "../components/errors/Errors";
import { DefaultPagination, Pagination } from "../components/listview/Pagination";
import { SchemaFull } from "../home/administration/schemas/types";
import { Administration } from "../listsSettings/administration";
import { Api } from "../listsSettings/api";
import { Conventions } from "../listsSettings/conventions";
import { Functionaries } from "../listsSettings/functionaries";
import { Members } from "../listsSettings/members";
import {
  ErrorCode,
  FileInfo,
  Item,
  ListviewOptions,
  ListviewParameters,
  MenuItem,
  MenuOptionValue,
  PostPolicy,
  Result,
  SearchResult,
  UserInfo,
  UserPermissions,
} from "./types";
import { wretchWithAddons } from "./wretch";

const endpoint = "/__api/internal";

export const home: string = endpoint;
export const functionaries: string = `${endpoint}/${Functionaries.InternalName}`;
export const administration: string = `${endpoint}/${Administration.InternalName}`;
export const members: string = `${endpoint}/${Members.InternalName}`;
export const conventions: string = `${endpoint}/${Conventions.InternalName}`;
export const api: string = `${endpoint}/${Api.InternalName}`;

interface ErrorResult {
  error: CustomError;
}

const portal = wretchWithAddons.middlewares([
  authMiddleware({
    scopes: [msalConfig.auth.clientId + "/.default"],
  }),
]);

const getCustomError = (wretchError: WretchError): CustomError => {
  return {
    code: wretchError.status as ErrorCode,
    message: wretchError.text ?? "Error",
  };
};

const getErrorResult = <T>(error: CustomError): Result<T> => ({
  data: null,
  error: getGenericError(error),
});

const getGenericError = (res: object): CustomError => ({
  code: 500,
  message: res.toString(),
});

const getFromResponse = async <T>(
  response: WretchResponseChain<QueryStringAddon, unknown, undefined>
): Promise<Result<T>> =>
  response
    .res(async (response: WretchResponse) => {
      return {
        data: (await response.json()) as T,
        error: null,
      };
    })
    .catch((res: { text: string; status: number }) => {
      if (res.status != 409 && res.status != 422) {
        console.error(res);
      }
      if (res?.text && !res.text.startsWith("{")) {
        return {
          data: null,
          error: {
            code: Number(res.text.split(" ")[0]) as ErrorCode,
            message: res.text.split(" ")[1] ?? "",
          },
        };
      }
      const errorResult: ErrorResult = res?.text
        ? (JSON.parse(res.text) as ErrorResult)
        : {
            error: {
              code: 500,
              message: "Unknown error",
            },
          };
      return {
        data: null,
        error: errorResult.error,
      };
    });

const getVoidFromResponse = async (
  response: WretchResponseChain<QueryStringAddon, unknown, undefined>
): Promise<CustomError | null> =>
  response
    .res(async () => {
      return null;
    })
    .catch(async (res: WretchError) => {
      if (res.status != 409 && res.status != 422) {
        console.error(res);
      } else {
        const d = await getFromResponse(response);
        return d.error;
      }
      return getGenericError(res);
    });

const wrapErrors = <T>(
  response: WretchResponseChain<QueryStringAddon, unknown, undefined>
): WretchResponseChain<QueryStringAddon, unknown, undefined> => {
  const get = (x: WretchError) => getErrorResult<T>(getCustomError(x));

  return response.forbidden(get).unauthorized(get);
};

export const insertItem = async <T, U>(url: string, item: T, signal: AbortSignal): Promise<Result<U>> =>
  getFromResponse(wrapErrors(portal.options(signal).url(url).post(item)));

export const insertItems = async <T, U>(url: string, items: T[], signal: AbortSignal): Promise<Result<U>> =>
  getFromResponse(wrapErrors(portal.options(signal).url(`${url}/batchInsert`).post(items)));

export const getItem = async <T>(url: string, signal: AbortSignal): Promise<Result<T>> =>
  getFromResponse(wrapErrors(portal.options(signal).url(url).get()));

export const updateItem = async <T, U>(url: string, item: T, signal: AbortSignal): Promise<Result<U>> =>
  getFromResponse(wrapErrors(portal.options(signal).url(url).put(item)));

export const updateWithoutResult = async <T>(url: string, item: T, signal: AbortSignal): Promise<CustomError | null> =>
  getVoidFromResponse(wrapErrors(portal.options(signal).url(url).put(item)));

export const putReference = async (url: string, signal: AbortSignal): Promise<CustomError | null> =>
  getVoidFromResponse(wrapErrors(portal.options(signal).url(url).put()));

export const removeReferencedItems = async <T>(
  url: string,
  items: T[],
  signal: AbortSignal
): Promise<Result<{ [key: string]: boolean } | null>> =>
  getFromResponse(wrapErrors(portal.options(signal).url(`${url}/remove`).post(items)));

export const deleteItems = async (
  url: string,
  items: string[] | number[],
  signal: AbortSignal
): Promise<Result<{ [key: string]: boolean } | null>> =>
  getFromResponse(wrapErrors(portal.options(signal).url(`${url}/delete`).post(items)));

export const deleteItem = async (url: string, signal: AbortSignal): Promise<CustomError | null> =>
  getVoidFromResponse(wrapErrors(portal.options(signal).url(url).delete()));

export const getItems = async <T extends Item>(
  url: string,
  pagination: Pagination,
  signal?: AbortSignal
): Promise<Result<SearchResult<T>>> => {
  const searchParams = new URLSearchParams();
  if (pagination.itemsPerPage) {
    searchParams.append("pp", `${pagination.itemsPerPage}`);
  }
  if (pagination.page) {
    searchParams.append("p", `${pagination.page}`);
  }
  if (pagination.orderBy) {
    searchParams.append("o", pagination.orderBy);
  }
  if (pagination.orderByDescending) {
    searchParams.append("desc", "1");
  }
  if (pagination.searchKey) {
    searchParams.append("q", pagination.searchKey);
  }
  if (pagination.filters && pagination.filters.length > 0) {
    searchParams.append("f", JSON.stringify(pagination.filters));
  }
  const fullUrl = Array.from(searchParams).length > 0 ? `${url}?${searchParams.toString()}` : url;
  return getFromResponse(wrapErrors((signal ? portal.options(signal) : portal).url(fullUrl).get()));
};

export const getData = async <T>(url: string, signal?: AbortSignal): Promise<Result<T>> => {
  return getFromResponse(wrapErrors((signal ? portal.options(signal) : portal).url(url).get()));
};

export const postData = async <T>(
  url: string,
  signal?: AbortSignal,
  data?: object | string | number
): Promise<Result<T> | null> => {
  return getFromResponse(wrapErrors((signal ? portal.options(signal) : portal).url(url).post(data)));
};

export const searchItems = <T extends Item>(
  url: string,
  value: string,
  signal?: AbortSignal
): Promise<Result<SearchResult<T>>> => {
  const pagination: Pagination = {
    ...DefaultPagination,
    itemsPerPage: 100,
    searchKey: value,
  };
  return getItems(url, pagination, signal);
};

export const getFilters = async (
  url: string,
  fieldName: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[]>> =>
  getFromResponse(wrapErrors(portal.options(signal).url(`${url}/filters?f=${fieldName}`).get()));

export const getCurrentUserInfo = async (signal: AbortSignal): Promise<Result<UserInfo | null>> =>
  getFromResponse(wrapErrors(portal.options(signal).url(`${endpoint}/options/user_info`).get()));

export const getGlobalMenuItems = async (signal: AbortSignal): Promise<Result<MenuItem[] | null>> =>
  getFromResponse(wrapErrors(portal.options(signal).url(`${endpoint}/options/global_menu_items`).get()));

export const getCurrentMenuItems = async (
  schemaId: number | null,
  signal: AbortSignal
): Promise<Result<MenuItem[] | null>> => {
  const param = schemaId ? `?schema_id=${schemaId}` : "";
  return getFromResponse(
    wrapErrors(portal.options(signal).url(`${endpoint}/options/current_menu_items${param}`).get())
  );
};

export const getListviewOptions = async (
  params: ListviewParameters,
  signal: AbortSignal
): Promise<ListviewOptions | null> =>
  portal
    .options(signal)
    .url(`${endpoint}/options/view`)
    .post(params)
    .res(async (x) => {
      if (x.status === 204) {
        return null;
      }
      return (await x.json()) as ListviewOptions;
    });

export const getUserPermissions = (
  list: string | null,
  schema: string | null,
  signal: AbortSignal
): Promise<UserPermissions> => {
  const schemaParam = schema ? `schema=${schema}` : "";
  const listParam = list ? `list=${list}` : "";
  const url = `${endpoint}/options/user_permissions?${listParam}${schemaParam || listParam ? "&" : ""}${schemaParam}`;
  return portal.options(signal).url(url).get().json<UserPermissions>();
};

export const getSchemaByInternalName = async (
  internalName: string,
  signal: AbortSignal
): Promise<Result<SchemaFull | null>> =>
  getItem<SchemaFull>(`${endpoint}/options/schema?internal_name=${internalName}`, signal);

export const exportExcel = async (
  url: string,
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<FileInfo | null>> => {
  const searchParams = new URLSearchParams();
  if (pagination.itemsPerPage) {
    searchParams.append("pp", `${pagination.itemsPerPage}`);
  }
  if (pagination.page) {
    searchParams.append("p", `${pagination.page}`);
  }
  if (pagination.orderBy) {
    searchParams.append("o", pagination.orderBy);
  }
  if (pagination.orderByDescending) {
    searchParams.append("desc", "1");
  }
  if (pagination.searchKey) {
    searchParams.append("q", pagination.searchKey);
  }
  if (pagination.filters && pagination.filters.length > 0) {
    searchParams.append("f", JSON.stringify(pagination.filters));
  }
  const fullUrl = Array.from(searchParams).length > 0 ? `${url}?${searchParams.toString()}` : url;
  return getFromResponse(
    wrapErrors(
      portal
        .options(signal)
        .url(fullUrl)
        .headers({
          Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
        .get()
    )
  );
};

export const createFileUploadUrl = async (
  url: string,
  signal: AbortSignal,
  data: object
): Promise<Result<PostPolicy | null>> =>
  getFromResponse(wrapErrors(portal.options(signal).url(`${url}/create_file_upload_url`).post(data)));

export const uploadFile = async (policy: PostPolicy, _: AbortSignal, file: File): Promise<Result<boolean | null>> => {
  const formData = new FormData();

  for (const field in policy.fields) {
    const value = policy.fields[field];
    value && formData.append(field, value);
  }

  // Must be the last child of the form
  // https://stackoverflow.com/questions/16378713/unable-to-upload-file-to-google-cloud-storage-using-post-method
  formData.append("file", file);

  try {
    await fetch(policy.url, {
      method: "POST",
      body: formData,
    });
    return {
      data: true,
      error: null,
    };
  } catch (err) {
    return {
      data: null,
      error: {
        code: 500,
        message: "Unknown error",
      },
    };
  }
};

export const downloadFileFromUrl = async (url: string, fileName: string): Promise<Result<boolean>> => {
  const success = await fetch(url)
    .then((resp) => {
      if (resp.status !== 200) {
        const error: CustomError = {
          code: resp.status as ErrorCode,
          message: resp.statusText,
        };
        throw error;
      }
      return resp.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      return {
        data: true,
        error: null,
      };
    })
    .catch((err: CustomError) => {
      return {
        data: false,
        error: err,
      };
    });
  return success;
};
