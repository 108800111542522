import create from "zustand";
import { SchemaFull } from "../home/administration/schemas/types";
import { MenuItem } from "../services/types";

type State = {
  isUserAdmin: boolean;
  itemsGlobalMenu: MenuItem[];
  itemsCurrentMenu: MenuItem[];
  page: string | null;
  listName: string | null;
  schema: { item: SchemaFull | null; loaded: boolean };
  setIsUserAdmin: (isUserAdmin: boolean) => void;
  setItemsGlobalMenu: (itemsGlobalMenu: MenuItem[]) => void;
  setItemsCurrentMenu: (itemsCurrentMenu: MenuItem[]) => void;
  setPage: (page: string | null) => void;
  setListName: (listName: string | null) => void;
  setCurrentSchema: (schema: SchemaFull | null) => void;
};

const useGlobalState = create<State>((set) => ({
  isUserAdmin: false,
  itemsGlobalMenu: [],
  itemsCurrentMenu: [],
  page: null,
  listName: null,
  schema: { item: null, loaded: false },
  setIsUserAdmin: (isUserAdmin: boolean) => set({ isUserAdmin }),
  setItemsGlobalMenu: (itemsGlobalMenu: MenuItem[]) => set({ itemsGlobalMenu }),
  setItemsCurrentMenu: (itemsCurrentMenu: MenuItem[]) => set({ itemsCurrentMenu }),
  setPage: (page: string | null) => set({ page }),
  setListName: (listName: string | null) => set({ listName }),
  setCurrentSchema: (schema: SchemaFull | null) => set({ schema: { item: schema, loaded: true } }),
}));

export default useGlobalState;
