import { FC } from "react";
import Button from "./Button";

interface CloseButtonProps {
  onClose: (event: React.MouseEvent) => void;
}

const CloseButton: FC<CloseButtonProps> = (props: CloseButtonProps): JSX.Element => {
  return <Button onclick={props.onClose}>Schließen</Button>;
};

export default CloseButton;
