import { addDays, format } from "date-fns";
import { CalendarCell, CalendarDay, CalendarItem, CalenderDataItem as CalendarDataItem } from "./types";

const isSlotFree = (row: CalendarCell[], from: number, to: number, column: number): boolean => {
  let free = true;
  while (from < to) {
    const d = row[column];
    free = free && !d?.value.item;
    from += 30;
  }
  return free;
};

const getHalfAnHourTime = (date: Date) => {
  const minFrom = Math.floor((date.getMinutes() * 2) / 60) * 30;
  return date.getHours() * 60 + minFrom;
};

const getFromToHalfAnHour = (item: CalendarItem): [number, number] => {
  const from = getHalfAnHourTime(item.from);
  const toTmp = getHalfAnHourTime(item.to);

  const to = toTmp - from < 60 ? from + 60 : toTmp;
  return [from, to];
};

const fillSlots = (day: CalendarDay, from: number, to: number, column: number, item: CalendarItem) => {
  from += 30;
  while (from < to) {
    const fromStr = from.toString().padStart(3, "0");
    if (!day[fromStr]) {
      day[fromStr] = [];
    }
    const row = day[fromStr];
    if (row?.[column]) {
      console.error("Data already present", row?.[column]?.value);
    }
    if (row && !row[column]) {
      row[column] = { value: { item: item } };
    }
    from += 30;
  }
};

export const setItem = (day: CalendarDay, value: CalendarDataItem, column: number = 0) => {
  const [from, to] = getFromToHalfAnHour(value.item);

  const fromStr = from.toString().padStart(3, "0");
  if (!day[fromStr]) {
    day[fromStr] = [];
  }

  const row = day[fromStr];
  if (row) {
    if (isSlotFree(row, from, to, column)) {
      if (!row[column]) {
        row[column] = { value: value };
      }
      fillSlots(day, from, to, column, value.item);
    } else {
      setItem(day, value, column + 1);
    }
  }
};

const setWidthToBusyCells = (day: { [key: string]: [CalendarCell, number][] }, dailyData: CalendarCell) => {
  Object.keys(day).forEach((hourlyKey) => {
    const row = day[hourlyKey];
    if (row) {
      row.forEach(([c1, _]) => {
        if (c1.value.item === dailyData.value?.item) {
          c1.width = dailyData.width;
        }
      });
    }
  });
};

export const setWidths = (day: { [key: string]: [CalendarCell, number][] }) => {
  Object.keys(day).forEach((hourlyKey) => {
    const row = day[hourlyKey];
    if (row) {
      row.reduce((rv, [c, fractor]) => {
        const width = rv / fractor;
        c.width = width;
        setWidthToBusyCells(day, c);
        return rv - width;
      }, 100);
    }
  });
};

export const getFractors = (day: CalendarDay) => {
  return Object.keys(day).reduce((rv2: { [key: string]: [CalendarCell, number][] }, hourlyKey) => {
    const row = day[hourlyKey];
    if (row) {
      rv2[hourlyKey] = row.reduce((rv1: [CalendarCell, number][], c, index) => {
        let fractor = 1;
        if (c.value) {
          let [from, to] = getFromToHalfAnHour(c.value.item);

          while (from < to) {
            const fromStr = from.toString().padStart(3, "0");
            const rs = day[fromStr];
            const l = rs && rs.filter((x, i) => i >= index && x).length;
            if (l && l > fractor) {
              fractor = l;
            }
            from += 30;
          }
        }
        return [...rv1, [c, fractor]];
      }, []);
    }
    return rv2;
  }, {});
};

export const sortItems = (valueA: CalendarDataItem, valueB: CalendarDataItem) => {
  const res = valueA.item.from.valueOf() - valueB.item.from.valueOf();
  return res !== 0 ? res : valueB.item.to.valueOf() - valueA.item.to.valueOf();
};

export const getWeeklyTitle = (date?: Date): string => {
  if (!date) {
    return "";
  }
  const prevYear = date.getFullYear() != addDays(date, 6).getFullYear() ? `${date.getFullYear()} ` : "";
  return `${format(date, "dd. MMMM")} ${prevYear}- ${format(addDays(date, 6), "dd. MMMM yyyy")}`;
};

export const getDailyTitle = (date?: Date): string => (date ? format(date, "EEEE, dd. MMMM yyyy") : "");
