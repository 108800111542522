import Link from "../../../components/Link";
import Number from "../../../components/Number";
import { IDialogField, IListviewField } from "../../../services/types";
import { getCurrentUrlAsSource } from "../../../services/utils";
import { getItemTitle, LegislatureFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<LegislatureFull> & IDialogField<LegislatureFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: LegislatureFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{getItemTitle(item)}</Link>
  ),
  renderDialogValue: (item: LegislatureFull) => getItemTitle(item),
};

export const year: IListviewField<LegislatureFull> = {
  title: "Jahr",
  fieldName: "year",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: LegislatureFull) => <Number value={item.year} />,
};

export const duration: IListviewField<LegislatureFull> = {
  title: "Dauer (Jahre)",
  fieldName: "durationYears",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: LegislatureFull) => <Number value={item.durationYears} />,
};
