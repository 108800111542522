import Link from "../../../components/Link";
import { IListviewField } from "../../../services/types";
import { dispForm as dispFormGroups } from "../groups/urls";
import { dispForm as dispFormLists } from "../lists/urls";
import { ListGroupPermissionFull } from "./types";

export const list: IListviewField<ListGroupPermissionFull> = {
  title: "List",
  fieldName: "List.Title",
  isSortable: true,
  renderListValue: (item: ListGroupPermissionFull) => (
    <Link href={`${dispFormLists}/${item.list?.id}`}>{item.list?.name}</Link>
  ),
};

export const group: IListviewField<ListGroupPermissionFull> = {
  title: "Gruppe",
  fieldName: "Group.Title",
  isSortable: true,
  renderListValue: (item: ListGroupPermissionFull) => (
    <Link href={`${dispFormGroups}/${item.group?.id}`}>{item.group?.name}</Link>
  ),
};

export const permission: IListviewField<ListGroupPermissionFull> = {
  title: "Berechtigung",
  fieldName: "permission.name",
  isSortable: true,
  renderListValue: (item: ListGroupPermissionFull) => item.permission?.name,
};

export const permissionDescription: IListviewField<ListGroupPermissionFull> = {
  title: "Berechtigung",
  fieldName: "permission.description",
  isSortable: true,
  renderListValue: (item: ListGroupPermissionFull) => item.permission?.description,
};
