import { FC } from "react";
import { formatEuro } from "../services/utils";
import styles from "./Euro.module.css";

interface EuroProps {
  value?: number | null;
}

const Euro: FC<EuroProps> = ({ value }: EuroProps) => <div className={styles["align-right"]}>{formatEuro(value)}</div>;

export default Euro;
