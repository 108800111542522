import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import Errors, { getString, isConflict } from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Functionaries } from "../../../listsSettings/functionaries";
import { ErrorCode, IAction, UserPermissions } from "../../../services/types";
import { getCloseAction, getCurrentUrlAsSource, getSourceFromUrl } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import * as Fields from "./Fields";
import { ChapterEventCategoryFull } from "./types";
import { allItems, editForm } from "./urls";
import { deletechapterEventCategory, getChapterEventCategory } from "./webapi";

const DispForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<ChapterEventCategoryFull>();
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const statusBar = useStatusBarState();
  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(Number(id));

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Functionaries.Lists.ChapterEventsCategories.InternalName,
      Functionaries.InternalName,
      abortController.signal
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getChapterEventCategory(id, abortController.signal);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);
          result.data && setState(result.data);
        }
      }
    }
  };

  const onDelete = async () => {
    if (state && confirm(strings.confirmDeleteItem)) {
      const abortController = resetAbortController();
      const error = await deletechapterEventCategory(state.id, abortController.signal);
      if (!abortController.signal.aborted) {
        if (error) {
          isConflict(error)
            ? statusBar.addError(strings.deleteItemRestrictedByRelatedElements)
            : statusBar.addError(getString(error));
        } else {
          navigateTo(getSourceFromUrl() ?? allItems);
        }
      }
    }
  };

  const editAction: IAction = {
    title: strings.edit,
    icon: Edit,

    onClick: () => state && navigateTo(`${editForm}/${state.id}?${getCurrentUrlAsSource()}`),
    disabled: !permissions || !permissions.listPermissions.includes("edit-all"),
  };
  const closeAction: IAction = getCloseAction(navigateTo, allItems);
  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: onDelete,
    disabled: !permissions || !permissions.listPermissions.includes("delete-all"),
  };

  useEffect(() => {
    if (state) {
      setListName(Functionaries.Lists.ChapterEventsCategories.Title);
      setPage(state.name);
    }
  }, [state]);

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Kategorie"} />
          <Actions actions={[editAction, closeAction, deleteAction]} />
          <FormTable>
            <FormTr>
              <FormLabel>{Fields.name.title}</FormLabel>
              <FormData disp>{state?.name}</FormData>
            </FormTr>
          </FormTable>
          <CreatedModifiedInfo info={state} />
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;
