import { FC, useEffect, useState } from "react";
import { getString } from "../../../components/errors/Errors";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import NumberInput from "../../../components/forms/NumberInput";
import SelectBox from "../../../components/forms/SelectBox";
import { DefaultPagination, Pagination } from "../../../components/listview/Pagination";
import useStatusBarState from "../../../hooks/useStatusBar";
import { ValidationErrors } from "../../../services/types";
import { DistrictFull } from "../districts/types";
import { getDistricts } from "../districts/webapi";
import * as Fields from "./Fields";
import { AreaForm } from "./types";

interface NewEditTableProps {
  state: AreaForm;
  setState: React.Dispatch<React.SetStateAction<AreaForm | undefined>>;
  errors: ValidationErrors;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  const statusBar = useStatusBarState();
  const [districts, setDistricts] = useState<DistrictFull[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const result = await getDistricts(paging, abortController.signal);
      result.data && setDistricts(result.data?.items);
      result.error && statusBar.addError(getString(result.error));
    })();
    return () => {
      abortController.abort();
    };
  }, []);

  const paging: Pagination = {
    ...DefaultPagination,
    itemsPerPage: 100,
  };

  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.name.title}</FormLabel>
        <FormData>
          <Input
            value={state.name}
            onChange={(value) =>
              setState({
                ...state,
                name: value,
              })
            }
          />
          <FormError error={errors[Fields.name.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.district.title}</FormLabel>
        <FormData>
          <SelectBox
            value={state.district}
            options={districts}
            onChange={(value) =>
              setState({
                ...state,
                district: value,
              })
            }
            getId={(item) => `${item.id}`}
            getValue={(item) => item.name}
          />
          <FormError error={errors[Fields.district.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.order.title}</FormLabel>
        <FormData>
          <NumberInput
            value={state.order}
            onChange={(value) =>
              setState({
                ...state,
                order: value,
              })
            }
          />
          <FormError error={errors[Fields.order.fieldName]} />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;
