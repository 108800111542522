import { FC } from "react";
import styles from "./DialogBorder.module.css";

interface DialogBorderProps {
  children: React.ReactNode;
}

export const DialogBorder: FC<DialogBorderProps> = ({ children }: DialogBorderProps) => (
  <div className={styles.border}>{children}</div>
);
