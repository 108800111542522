import { FC, useEffect, useState } from "react";
import { getString } from "../../../components/errors/Errors";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Lookup from "../../../components/forms/Lookup";
import SelectBox from "../../../components/forms/SelectBox";
import { DefaultPagination } from "../../../components/listview/Pagination";
import useAbortController from "../../../hooks/useAbortController";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Administration } from "../../../listsSettings/administration";
import { ValidationErrors } from "../../../services/types";
import { PermissionFull } from "../permissions/types";
import { getPermissions } from "../permissions/webapi";
import * as SchemaFields from "../schemas/Fields";
import { searchSchemas } from "../schemas/webapi";
import * as UserFields from "../users/Fields";
import { searchUsers } from "../users/webapi";
import * as Fields from "./Fields";
import { SchemaUserPermissionForm } from "./types";

interface NewEditTableProps {
  state: SchemaUserPermissionForm;
  setState: React.Dispatch<React.SetStateAction<SchemaUserPermissionForm | undefined>>;
  errors: ValidationErrors;
}
const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  const statusBar = useStatusBarState();
  const [abortController, resetAbortController] = useAbortController();
  const [permissions, setPermissions] = useState<PermissionFull[]>([]);

  const loadPermissions = async () => {
    const abortController = resetAbortController();
    const result = await getPermissions(DefaultPagination, abortController.signal);
    if (!abortController.signal.aborted) {
      if (result.error) {
        console.error(result.error);
        statusBar.addError(getString(result.error));
      }
      result.data && setPermissions(result.data.items);
    }
  };

  useEffect(() => {
    loadPermissions();
    return () => {
      abortController && abortController.abort();
    };
  }, []);

  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.schema.title}</FormLabel>
        <FormData>
          <Lookup
            disabled={!!state.schema?.id}
            value={state.schema?.name ?? ""}
            onChange={(schema) =>
              setState({
                ...state,
                schema: schema,
              })
            }
            searchItems={searchSchemas}
            searchDialogTitle={Administration.Lists.Schemas.Title}
            searchDialogFields={[SchemaFields.name, SchemaFields.internalName]}
            getTitle={(item) => item.name ?? ""}
          />
          <FormError error={errors[Fields.schema.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.user.title}</FormLabel>
        <FormData>
          <Lookup
            disabled={!!state.user?.id}
            value={state.user?.name ?? ""}
            onChange={(user) =>
              setState({
                ...state,
                user: user,
              })
            }
            searchItems={searchUsers}
            searchDialogTitle={Administration.Lists.Users.Title}
            searchDialogFields={[UserFields.name, UserFields.department, UserFields.email]}
            getTitle={(item) => item.name ?? ""}
          />
          <FormError error={errors[Fields.user.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.permission.title}</FormLabel>
        <FormData>
          <SelectBox
            value={state.permission}
            onChange={(permission) =>
              setState({
                ...state,
                permission: permission,
              })
            }
            options={permissions}
            getId={(item) => item.name ?? ""}
            getValue={(item) => item.name ?? ""}
          />
          <FormError error={errors[Fields.permission.fieldName]} />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;
