import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import * as Fields from "./Fields";

export interface CategoryFull extends CreationInformationItem {
  id: number;
  name: string;
}

export interface CategoryForm {
  name: string | null;
}

export interface CategoryPost {
  name: string;
}

export const mapToPOST = (item: CategoryForm): CategoryPost => {
  return {
    name: item.name || "",
  };
};

export const validate = (
  state: CategoryForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.name) {
    errors[Fields.name.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};
