import classNames from "classnames";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Actions from "../../../components/controls/Actions";
import { SearchDialog } from "../../../components/dialogs/SearchDialog";
import Errors, { getString } from "../../../components/errors/Errors";
import CheckBox from "../../../components/forms/CheckBox";
import DatePicker from "../../../components/forms/DatePickerWithInput";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Lookup from "../../../components/forms/Lookup";
import { DefaultPagination, Pagination } from "../../../components/listview/Pagination";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Functionaries } from "../../../listsSettings/functionaries";
import { Members } from "../../../listsSettings/members";
import { Public } from "../../../listsSettings/public";
import { ErrorCode, IAction, UserPermissions, ValidationErrors } from "../../../services/types";
import { getCancelAction } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Cross from "../../../svg/Cross.svg?react";
import Save from "../../../svg/Save.svg?react";
import * as CompanyFields from "../../members/companies/Fields";
import { Company } from "../../members/companies/types";
import { searchCompanies } from "../../members/companies/webapi";
import * as PersonFields from "../../public/persons/Fields";
import { getItemTitle, PersonFull } from "../../public/persons/types";
import { searchPersons } from "../../public/persons/webapi";
import * as ChapterFields from "../chapters/Fields";
import { searchChapters } from "../chapters/webapi";
import * as FunctionariesFields from "../functionaries/Fields";
import { allItems as allItemsFunctionaries } from "../functionaries/urls";
import * as LegislatureFields from "../legislatures/Fields";
import SelectBoxLegislature from "../legislatures/SelectBoxLegislature";
import { LegislatureFull } from "../legislatures/types";
import { getLegislatures } from "../legislatures/webapi";
import * as RoleFields from "../roles/Fields";
import { RoleFull } from "../roles/types";
import { getRoles } from "../roles/webapi";
import * as Fields from "./Fields";
import styles from "./NewForm.module.css";
import { ChapterElectionForm, ChapterElectionFunctionary, mapToPOST, validate } from "./types";
import { getChapterElections, insertChapterElection } from "./webapi";

const NewForm = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const navigateTo = useNavigate();
  const statusBar = useStatusBarState();

  const [abortController, resetAbortController] = useAbortController();

  const [state, setState] = useState<ChapterElectionForm>({
    legislature: null,
    chapter: null,
    electionDate: null,
    functionaries: [],
  });

  const [errors, setErrors] = useState<ValidationErrors>({});
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [showPersonsDialog, setShowPersonsDialog] = useState(false);
  const [showCompaniesDialog, setShowCompaniesDialog] = useState(false);
  const [legislatures, setLegislature] = useState<LegislatureFull[]>([]);
  const [roles, setRoles] = useState<RoleFull[]>([]);

  const defaultPagination: Pagination = {
    ...DefaultPagination,
    itemsPerPage: 100,
  };

  const update = (index: number, functionary: ChapterElectionFunctionary) => {
    const newFunctionaries = state.functionaries.map((x) => ({ ...x }));
    newFunctionaries[index] = functionary;
    setState((prevstate) => ({
      ...prevstate,
      functionaries: newFunctionaries,
    }));
  };

  useEffect(() => {
    (async () => {
      const abortController = resetAbortController();
      const [legislaturesResult, rolesResult, permissions] = await Promise.all([
        getLegislatures(
          {
            ...defaultPagination,
            orderBy: LegislatureFields.year.fieldName,
            orderByDescending: true,
          },
          abortController.signal
        ),
        getRoles(
          {
            ...defaultPagination,
            orderBy: RoleFields.name.fieldName,
            filters: [
              {
                fieldname: RoleFields.forChapters.fieldName,
                values: [
                  {
                    value: true,
                  },
                ],
              },
            ],
          },
          abortController.signal
        ),
        getUserPermissions(
          Functionaries.Lists.Functionaries.InternalName,
          Functionaries.InternalName,
          abortController.signal
        ),
      ]);
      if (!abortController.signal.aborted) {
        setPermissions(permissions);
        legislaturesResult.data && setLegislature(legislaturesResult.data.items);
        rolesResult.data && setRoles(rolesResult.data.items);
        !permissions && setErrorCode(403);
      }
    })();

    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const addPerson = (person: PersonFull) => {
    setShowPersonsDialog(false);

    const persons = state.functionaries.filter((x) => x.person?.id == person.id);
    if (persons.length > 0) {
      statusBar.addError(`<b>${getItemTitle(person)}</b> ist schon vorhanden.`);
      return;
    }
    setState((prevstate) => ({
      ...prevstate,
      functionaries: [
        ...prevstate.functionaries,
        {
          person: person,
          company: null,
          district: null,
          area: null,
          chapter: null,
          roles: roles.map((x) => ({ ...x })),
        },
      ],
    }));
  };

  const setCompany = (company: Company) => {
    setShowCompaniesDialog(false);

    setState((prevstate) => ({
      ...prevstate,
      company: company,
    }));
  };

  const save = async (): Promise<void> => {
    statusBar.clear();
    if (!validate(state, setErrors, statusBar)) return;
    if (state.chapter?.id && state.legislature?.id && state.electionDate) {
      const postItem = mapToPOST(state);
      const abortController = resetAbortController();
      const error = await insertChapterElection(postItem, abortController.signal);

      if (error) {
        if (error.code == 409 || error.code == 422) {
          const newErrors = error.details?.reduce((rv: ValidationErrors, d) => {
            rv[d.field] = strings.getMessageFromTypeError(d.type);
            return rv;
          }, {});

          newErrors && setErrors(newErrors);
        } else {
          statusBar.addError(getString(error));
        }
      } else {
        navigateTo(
          `${allItemsFunctionaries}?queries=${encodeURIComponent(
            `functionaries-all-items=${(() => {
              const p: Pagination = {
                page: 1,
                filters: [
                  {
                    fieldname: "chapter.id",
                    values: [
                      {
                        value: state.chapter?.id ?? 0,
                      },
                    ],
                  },
                  {
                    fieldname: "legislature.id",
                    values: [
                      {
                        value: state.legislature?.id ?? 0,
                      },
                    ],
                  },
                ],
              };
              return JSON.stringify(p);
            })()}`
          )}`
        );
      }
    }
  };

  const actions: IAction[] = [
    {
      title: "Elemente speichern",
      icon: Save,
      onClick: save,
    },
    getCancelAction(navigateTo, allItemsFunctionaries),
  ];

  useEffect(() => {
    if (permissions) {
      setListName(Functionaries.Lists.ChapterElections.Title);
      setPage(strings.newElement);
    }
  }, [permissions]);

  useEffect(() => {
    const abortController = new AbortController();
    statusBar.clear();
    if (state.legislature?.id && state.chapter?.id) {
      const pagination: Pagination = {
        page: 1,
        itemsPerPage: 1,
        filters: [
          {
            fieldname: "chapter.id",
            values: [
              {
                value: state.chapter?.id ?? 0,
              },
            ],
          },
          {
            fieldname: "legislature.id",
            values: [
              {
                value: state.legislature?.id ?? 0,
              },
            ],
          },
        ],
      };
      (async () => {
        const result = await getChapterElections(pagination, abortController.signal);
        result.error && statusBar.addError(getString(result.error));

        result.data &&
          result.data.items.length > 0 &&
          statusBar.addWarning("Für die ausgewählte Legislatur und Ortsgruppe wurden schon Neuwahlen angegeben.");
      })();
    }
    return () => abortController.abort();
  }, [state.legislature?.id, state.chapter?.id]);

  return (
    <>
      {permissions &&
        (!errorCode ? (
          <>
            <Title text={strings.new} />
            <Actions actions={actions} />
            {showPersonsDialog && (
              <SearchDialog
                isOpen={showPersonsDialog}
                searchItems={(searchValue, abortController) => searchPersons(searchValue, abortController.signal)}
                onItemSelected={addPerson}
                cancelClicked={() => setShowPersonsDialog(false)}
                title={Public.Persons.Title}
                fields={[PersonFields.lastName, PersonFields.firstName]}
                getTitle={getItemTitle}
              />
            )}
            {showCompaniesDialog && (
              <SearchDialog
                isOpen={showCompaniesDialog}
                searchItems={(searchValue, abortController) => searchCompanies(searchValue, abortController.signal)}
                onItemSelected={setCompany}
                cancelClicked={() => setShowCompaniesDialog(false)}
                title={Members.Lists.Companies.Title}
                fields={[
                  CompanyFields.name,
                  CompanyFields.companyNumber,
                  CompanyFields.member,
                  CompanyFields.memberNumber,
                ]}
                getTitle={(item) => item.name}
              />
            )}
            <FormTable>
              <FormTr>
                <FormLabel required>{Fields.legislature.title}</FormLabel>
                <FormData>
                  <SelectBoxLegislature
                    value={state.legislature}
                    options={legislatures}
                    onChange={(value) =>
                      setState((prevstate) => ({
                        ...prevstate,
                        legislature: value,
                      }))
                    }
                  />
                  <FormError error={errors[Fields.legislature.fieldName]} />
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel required>{Fields.chapter.title}</FormLabel>
                <FormData>
                  <Lookup
                    value={state.chapter?.membersChapter?.name || null}
                    disabled={!!state.chapter?.id}
                    onChange={(value) =>
                      setState((prevstate) => ({
                        ...prevstate,
                        chapter: value,
                      }))
                    }
                    searchItems={searchChapters}
                    getTitle={(item) => item.membersChapter?.name ?? ""}
                    searchDialogTitle={Functionaries.Lists.Chapters.Title}
                    searchDialogFields={[ChapterFields.name, ChapterFields.area, ChapterFields.district]}
                  />
                  <FormError error={errors[Fields.chapter.fieldName]} />
                </FormData>
              </FormTr>
              <FormTr>
                <FormLabel required>{Fields.electionDate.title}</FormLabel>
                <FormData>
                  <DatePicker
                    value={state.electionDate}
                    onSelect={(value) =>
                      setState({
                        ...state,
                        electionDate: value,
                      })
                    }
                  />
                  <FormError error={errors[Fields.electionDate.fieldName]} />
                </FormData>
              </FormTr>
            </FormTable>

            <FormTable>
              <FormTr>
                <FormLabel />
                <FormLabel>{FunctionariesFields.person.title}</FormLabel>
                <FormLabel required>Mitgliedsbetrieb</FormLabel>
                <FormLabel required>Rollen</FormLabel>
              </FormTr>
              {state.functionaries.map((functionary, fIndex) => (
                <FormTr key={fIndex}>
                  <FormLabel>
                    <div
                      className={styles.svg}
                      onClick={() =>
                        setState((prevstate) => ({
                          ...prevstate,
                          functionaries: [
                            ...prevstate.functionaries.filter((x) => x !== functionary).map((x) => ({ ...x })),
                          ],
                        }))
                      }
                    >
                      <Cross />
                    </div>
                  </FormLabel>
                  <FormLabel>
                    <div className={styles.functionary}>{getItemTitle(functionary.person)}</div>
                  </FormLabel>
                  <FormData>
                    <div
                      className={classNames({
                        [styles.error]: !!errors[`${FunctionariesFields.company.fieldName}_${fIndex}`],
                      })}
                    >
                      <Lookup
                        disabled={!!functionary.company?.id}
                        value={functionary.company?.name ?? ""}
                        onChange={(value) => {
                          const newFunctionary = { ...functionary, company: value };
                          update(fIndex, newFunctionary);
                        }}
                        searchItems={searchCompanies}
                        searchDialogTitle={Members.Lists.Companies.Title}
                        searchDialogFields={[
                          CompanyFields.name,
                          CompanyFields.companyNumber,
                          CompanyFields.member,
                          CompanyFields.memberNumber,
                        ]}
                        getTitle={(item) => item.name}
                      />
                    </div>
                  </FormData>
                  <FormData fitContent>
                    <div
                      className={classNames(styles.roles, {
                        [styles.error]: !!errors[`${FunctionariesFields.role.fieldName}_${fIndex}`],
                      })}
                    >
                      {functionary.roles.map((role, rIndex) => (
                        <CheckBox
                          key={role.name}
                          value={role.selected || false}
                          label={role.name ?? ""}
                          onChange={(value) => {
                            const newRoles = functionary.roles.map((x) => ({ ...x }));
                            const newRole = { ...role, selected: value };
                            newRoles[rIndex] = newRole;
                            const newFunctionary = { ...functionary, roles: newRoles };
                            update(fIndex, newFunctionary);
                          }}
                        />
                      ))}
                    </div>
                  </FormData>
                </FormTr>
              ))}
              <FormTr>
                <FormLabel>
                  <div
                    className={styles.svg}
                    onClick={() => {
                      setShowPersonsDialog(true);
                    }}
                  >
                    <Add />
                  </div>
                </FormLabel>
              </FormTr>
            </FormTable>
          </>
        ) : (
          errorCode && <Errors errorCode={errorCode} />
        ))}
    </>
  );
};

export default NewForm;
