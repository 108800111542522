import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import Errors, { getString } from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import ReferencedItemsView from "../../../components/listview/ReferencedItemsView";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Administration } from "../../../listsSettings/administration";
import { ErrorCode, IAction, SearchResult, UserPermissions } from "../../../services/types";
import { getCloseAction, getCurrentUrlAsSource, getSelectedItems, getSourceFromUrl } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import EditSchemaGroupPermissionDialogForm from "../schemaGroupPermissions/EditDialogForm";
import * as GroupFields from "../schemaGroupPermissions/Fields";
import NewSchemaGroupPermissionDialogForm from "../schemaGroupPermissions/NewDialogForm";
import { SchemaGroupPermissionFull } from "../schemaGroupPermissions/types";
import { deleteSchemaGroupPermissions } from "../schemaGroupPermissions/webapi";
import EditSchemaUserPermissionDialogForm from "../schemaUserPermissions/EditDialogForm";
import * as UserFields from "../schemaUserPermissions/Fields";
import NewSchemaUserPermissionDialogForm from "../schemaUserPermissions/NewDialogForm";
import { SchemaUserPermissionFull } from "../schemaUserPermissions/types";
import { deleteSchemaUserPermissions } from "../schemaUserPermissions/webapi";
import * as Fields from "./Fields";
import { SchemaFull } from "./types";
import { allItems, editForm } from "./urls";
import {
  deleteSchema,
  getSchema,
  getSchemaGroupsPermissions,
  getSchemaGroupsPermissionsFilters,
  getSchemaUsersPermissions,
  getSchemaUsersPermissionsFilters,
} from "./webapi";

const DispForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<SchemaFull>();
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [userPermissions, setUserPermissions] = useState<UserPermissions>();
  const [groupPermissions, setGroupPermissions] = useState<UserPermissions>();
  const statusBar = useStatusBarState();
  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(Number(id));

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Administration.Lists.Schemas.InternalName,
      Administration.InternalName,
      abortController.signal
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const [userPermissions, groupPermissions, result] = await Promise.all([
          getUserPermissions(
            Administration.Lists.Users.InternalName,
            Administration.InternalName,
            abortController.signal
          ),
          getUserPermissions(
            Administration.Lists.Groups.InternalName,
            Administration.InternalName,
            abortController.signal
          ),
          getSchema(id, abortController.signal),
        ]);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);

          if (result.data) {
            setUserPermissions(userPermissions);
            setGroupPermissions(groupPermissions);
            setState(result.data);
            userPermissions?.listPermissions.includes("read-all") &&
              (await loadSchemaGroupPermissions(result.data.id, groupPermissionPagination, abortController));
            groupPermissions?.listPermissions.includes("read-all") &&
              (await loadSchemaUserPermissions(result.data.id, userPermissionPagination, abortController));
          }
        }
      }
    }
  };

  const userFields = [UserFields.user, UserFields.permission, UserFields.permissionDescription];
  const groupFields = [GroupFields.group, GroupFields.permission, GroupFields.permissionDescription];

  const onDelete = async () => {
    if (state && confirm(strings.confirmDeleteItem)) {
      const abortController = resetAbortController();
      const error = await deleteSchema(state.id, abortController.signal);
      if (!abortController.signal.aborted) {
        error && statusBar.addError(getString(error));
        !error && navigateTo(getSourceFromUrl() ?? allItems);
      }
    }
  };

  const listIdUserPermissions = "list-users-permissions";
  const [listUserPermissions, setListUserPermissions] = useState<SearchResult<SchemaUserPermissionFull> | null>(null);

  const userPermissionPagination = getPagination(listIdUserPermissions) || {
    ...DefaultPagination,
  };

  const loadSchemaUserPermissions = async (
    schemaId: number,
    pagination: Pagination,
    abortController: AbortController
  ) => {
    const result = await getSchemaUsersPermissions(schemaId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setListUserPermissions(result.data);
    }
  };

  const listIdGroupPermissions = "list-groups-permissions";
  const [listGroupPermissions, setListGroupPermissions] = useState<SearchResult<SchemaGroupPermissionFull> | null>(
    null
  );

  const groupPermissionPagination = getPagination(listIdGroupPermissions) || {
    ...DefaultPagination,
  };

  const loadSchemaGroupPermissions = async (
    schemaId: number,
    pagination: Pagination,
    abortController: AbortController
  ) => {
    const result = await getSchemaGroupsPermissions(schemaId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setListGroupPermissions(result.data);
    }
  };
  const editAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => state && navigateTo(`${editForm}/${state.id}?${getCurrentUrlAsSource()}`),
    disabled: !permissions || !permissions.listPermissions.includes("edit-all"),
  };
  const closeAction: IAction = getCloseAction(navigateTo, allItems);
  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: onDelete,
    disabled: !permissions || !permissions.listPermissions.includes("delete-all"),
  };

  const usersPagination = getPagination(listIdUserPermissions) || {
    ...DefaultPagination,
  };

  const groupsPagination = getPagination(listIdGroupPermissions) || {
    ...DefaultPagination,
  };

  const [showNewUserPermissionDialog, setShowNewUserPermissionDialog] = useState(false);
  const [editSchemaUserPermission, setEditSchemaUserPermission] = useState<SchemaUserPermissionFull>();
  const addUserPermissions: IAction = {
    title: strings.add,
    icon: Add,
    onClick: () => setShowNewUserPermissionDialog(true),
    disabled:
      !userPermissions?.listPermissions.includes("read-all") || !permissions?.listPermissions.includes("edit-all"),
  };

  const deleteUserPermission: IAction = {
    title: strings.remove,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(listUserPermissions);
        if (selectedItems.length > 0 && confirm(strings.confirmDeleteItems)) {
          const abortController = resetAbortController();
          const result = await deleteSchemaUserPermissions(
            selectedItems.map((x) => x.id),
            abortController.signal
          );
          if (!abortController.signal.aborted) {
            if (result.error) {
              statusBar.addError(getString(result.error));
            } else if (result.data) {
              const s = selectedItems.filter((x) => x.id && result.data?.[x.id]);

              if (s.length != selectedItems.length) {
                statusBar.addWarning(strings.notAllElementsDeleted);
              }
              if (s.length > 0) {
                await loadSchemaUserPermissions(state.id, userPermissionPagination, abortController);
              }
            }
          }
        }
      }
    },
    disabled:
      getSelectedItems(listUserPermissions).length === 0 ||
      !permissions ||
      !permissions.listPermissions.includes("edit-all"),
  };

  const userPermissionActions = [addUserPermissions, deleteUserPermission];

  const [showNewGroupPermissionDialog, setShowNewGroupPermissionDialog] = useState(false);
  const [editSchemaGroupPermission, setEditSchemaGroupPermission] = useState<SchemaGroupPermissionFull>();
  const addGroupPermissions: IAction = {
    title: strings.add,
    icon: Add,
    onClick: () => setShowNewGroupPermissionDialog(true),
    disabled:
      !groupPermissions?.listPermissions.includes("read-all") || !permissions?.listPermissions.includes("edit-all"),
  };

  const editGroupPermission: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => {
      const item = getSelectedItems(listGroupPermissions)[0];
      item && setEditSchemaGroupPermission(item);
    },
    disabled:
      getSelectedItems(listGroupPermissions).length !== 1 || !groupPermissions?.listPermissions.includes("edit-all"),
  };

  const deleteGroupPermission: IAction = {
    title: strings.remove,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(listGroupPermissions);
        if (selectedItems.length > 0 && confirm(strings.confirmDeleteItems)) {
          const abortController = resetAbortController();
          const result = await deleteSchemaGroupPermissions(
            selectedItems.map((x) => x.id),
            abortController.signal
          );
          if (!abortController.signal.aborted) {
            if (result.error) {
              statusBar.addError(getString(result.error));
            } else if (result.data) {
              const s = selectedItems.filter((x) => x.id && result.data?.[x.id]);

              if (s.length != selectedItems.length) {
                statusBar.addWarning(strings.notAllElementsDeleted);
              }
              if (s.length > 0) {
                await loadSchemaGroupPermissions(state.id, groupPermissionPagination, abortController);
              }
            }
          }
        }
      }
    },
    disabled:
      getSelectedItems(listGroupPermissions).length === 0 ||
      !permissions ||
      !permissions.listPermissions.includes("edit-all"),
  };
  const groupPermissionActions = [addGroupPermissions, editGroupPermission, deleteGroupPermission];

  useEffect(() => {
    if (state) {
      setListName(Administration.Lists.Schemas.Title);
      setPage(state.name);
    }
  }, [state]);

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Schema"} />
          <Actions actions={[editAction, closeAction, deleteAction]} />
          <FormTable>
            <FormTr>
              <FormLabel>{Fields.name.title}</FormLabel>
              <FormData disp>{state?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.internalName.title}</FormLabel>
              <FormData disp>{state?.internalName}</FormData>
            </FormTr>
          </FormTable>
          <CreatedModifiedInfo info={state} />

          {showNewUserPermissionDialog && (
            <NewSchemaUserPermissionDialogForm
              schema={state}
              isOpen={showNewUserPermissionDialog}
              onCancel={() => setShowNewUserPermissionDialog(false)}
              onSave={() => {
                const abortController = resetAbortController();
                setShowNewUserPermissionDialog(false);
                loadSchemaUserPermissions(state.id, userPermissionPagination, abortController);
              }}
            />
          )}

          {editSchemaUserPermission && (
            <EditSchemaUserPermissionDialogForm
              item={editSchemaUserPermission}
              isOpen={!!editSchemaUserPermission}
              onCancel={() => setEditSchemaUserPermission(undefined)}
              onSave={() => {
                const abortController = resetAbortController();
                setEditSchemaUserPermission(undefined);
                loadSchemaUserPermissions(state.id, userPermissionPagination, abortController);
              }}
            />
          )}
          <ReferencedItemsView
            itemId={state.id}
            id={listIdUserPermissions}
            actions={userPermissionActions}
            title={Administration.Lists.Users.Title}
            fields={userFields}
            allowSelectRows
            reload={(id, pagination) => {
              const abortController = resetAbortController();
              return loadSchemaUserPermissions(id, pagination, abortController);
            }}
            searchResult={listUserPermissions}
            getFilters={getSchemaUsersPermissionsFilters}
            pagination={usersPagination}
            updateItems={(items) =>
              listUserPermissions &&
              setListUserPermissions({
                ...listUserPermissions,
                items: items,
              })
            }
          />

          {showNewGroupPermissionDialog && (
            <NewSchemaGroupPermissionDialogForm
              schema={state}
              isOpen={showNewGroupPermissionDialog}
              onCancel={() => setShowNewGroupPermissionDialog(false)}
              onSave={() => {
                const abortController = resetAbortController();
                setShowNewGroupPermissionDialog(false);
                loadSchemaGroupPermissions(state.id, groupPermissionPagination, abortController);
              }}
            />
          )}

          {editSchemaGroupPermission && (
            <EditSchemaGroupPermissionDialogForm
              item={editSchemaGroupPermission}
              isOpen={!!editSchemaGroupPermission}
              onCancel={() => setEditSchemaGroupPermission(undefined)}
              onSave={() => {
                const abortController = resetAbortController();
                setEditSchemaGroupPermission(undefined);
                loadSchemaGroupPermissions(state.id, groupPermissionPagination, abortController);
              }}
            />
          )}

          <ReferencedItemsView
            itemId={state.id}
            id={listIdGroupPermissions}
            actions={groupPermissionActions}
            title={Administration.Lists.Groups.Title}
            fields={groupFields}
            allowSelectRows
            reload={(id, pagination) => {
              const abortController = resetAbortController();
              return loadSchemaGroupPermissions(id, pagination, abortController);
            }}
            searchResult={listGroupPermissions}
            getFilters={getSchemaGroupsPermissionsFilters}
            pagination={groupsPagination}
            updateItems={(items) =>
              listGroupPermissions &&
              setListGroupPermissions({
                ...listGroupPermissions,
                items: items,
              })
            }
          />
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;
