import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { FC } from "react";

interface DispPhoneNumberProps {
  value?: string | null;
}

const DispPhoneNumber: FC<DispPhoneNumberProps> = ({ value }: DispPhoneNumberProps) => {
  if (!value) {
    return <>{value}</>;
  }

  if (!isValidPhoneNumber(value) && !isValidPhoneNumber(`+39${value}`)) {
    return <>{value}</>;
  }
  const phoneNumber = value && parsePhoneNumber(value?.substring(0, 15), "IT");
  return <>{phoneNumber && <a href={`tel:${phoneNumber.number}`}>{phoneNumber.formatInternational()}</a>}</>;
};

export default DispPhoneNumber;
