import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import Errors, { getString } from "../../../components/errors/Errors";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import ReferencedItemsView from "../../../components/listview/ReferencedItemsView";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Administration } from "../../../listsSettings/administration";
import { ErrorCode, IAction, SearchResult } from "../../../services/types";
import { getCloseAction } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import * as UserFields from "../users/Fields";
import { UserFull } from "../users/types";
import * as Fields from "./Fields";
import { OfficeFull } from "./types";
import { allItems } from "./urls";
import { getOffice, getOfficeUsers, getOfficeUsersFilters } from "./webapi";

const DispForm: FC = () => {
  const navigateTo = useNavigate();
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<OfficeFull>();
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const statusBar = useStatusBarState();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(id);

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: string) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Administration.Lists.Offices.InternalName,
      Administration.InternalName,
      abortController.signal
    );
    if (!abortController.signal.aborted) {
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getOffice(id, abortController.signal);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);

          if (result.data) {
            setState(result.data);
            loadUsers(result.data.id, pagination, abortController);
          }
        }
      }
    }
  };

  const userFields = [UserFields.name, UserFields.email];

  const closeAction: IAction = getCloseAction(navigateTo, allItems);

  const listviewId = "office-users";
  const [users, setUsers] = useState<SearchResult<UserFull> | null>(null);

  const pagination = getPagination(listviewId) || {
    ...DefaultPagination,
  };

  const loadUsers = async (officeId: string, pagination: Pagination, abortController: AbortController) => {
    const result = await getOfficeUsers(officeId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setUsers(result.data);
    }
  };

  useEffect(() => {
    if (state) {
      setListName(Administration.Lists.Offices.Title);
      setPage(state.name);
    }
  }, [state]);

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Büro"} />
          <Actions actions={[closeAction]} />
          <FormTable>
            <FormTr>
              <FormLabel>{Fields.name.title}</FormLabel>
              <FormData disp>{state.name}</FormData>
            </FormTr>
          </FormTable>

          <ReferencedItemsView
            itemId={state.id}
            id={listviewId}
            title={Administration.Lists.Users.Title}
            fields={userFields}
            reload={(id, pagination) => {
              const abortController = resetAbortController();
              return loadUsers(id, pagination, abortController);
            }}
            searchResult={users}
            getFilters={getOfficeUsersFilters}
            pagination={pagination}
            updateItems={(items) =>
              users &&
              setUsers({
                ...users,
                items: items,
              })
            }
          />
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;
