import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import Errors, { getString } from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Public } from "../../../listsSettings/public";
import { CreationInformation, ErrorCode, IAction, ValidationErrors } from "../../../services/types";
import { getCancelAction, getSaveAction, getSourceFromUrl } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import NewEditTable from "./NewEditTable";
import { mapToPOST, PersonForm, validate } from "./types";
import { dispForm } from "./urls";
import { getPerson, updatePerson } from "./webapi";

import { UserPermissions } from "../../../services/types";

const EditForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const statusBar = useStatusBarState();
  const [state, setState] = useState<PersonForm>();
  const [crationInformation, setCreationInformation] = useState<CreationInformation>();
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(Number(id));

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(Public.Persons.InternalName, null, abortController.signal);
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canEdit = permissions.schemaPermission && permissions.listPermissions.includes("edit-all");
      !canEdit && setErrorCode(403);
      if (canEdit) {
        const result = await getPerson(id, abortController.signal);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);
          if (result.data) {
            setCreationInformation(result.data);
            setState(result.data);
          }
        }
      }
    }
  };

  const save = async () => {
    if (id && state && validate(state, setErrors)) {
      const abortController = resetAbortController();
      const result = await updatePerson(Number(id), mapToPOST(state), abortController.signal);

      if (!abortController.signal.aborted) {
        if (result.error) {
          if (result.error.code == 409 || result.error.code == 422) {
            const newErrors = result.error.details?.reduce((rv: ValidationErrors, d) => {
              rv[d.field] = strings.getMessageFromTypeError(d.type);
              return rv;
            }, {});

            newErrors && setErrors(newErrors);
          } else {
            statusBar.addError(getString(result.error));
          }
        }
        result.data && navigateTo(getSourceFromUrl() ?? `${dispForm}/${result.data.id}`);
      }
    }
  };

  const actions: IAction[] = [getSaveAction(save), getCancelAction(navigateTo, `${dispForm}/${id}`)];

  useEffect(() => {
    if (permissions) {
      setListName(Public.Persons.Title);
      setPage(state ? `${state.lastName} ${state.firstName}` : null);
    }
  }, [permissions]);

  return (
    <>
      {permissions &&
        (state && !errorCode ? (
          <>
            <Title text={"Person"} />
            <Actions actions={actions} />
            <NewEditTable state={state} setState={setState} errors={errors} />
            <CreatedModifiedInfo info={crationInformation} />
          </>
        ) : (
          errorCode && <Errors errorCode={errorCode} />
        ))}
    </>
  );
};

export default EditForm;
