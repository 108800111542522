import classNames from "classnames";
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  getWeek,
  isAfter,
  isSunday,
  isToday,
  startOfMonth,
  startOfWeek,
} from "date-fns";

import { FC, Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { weekDaysLong } from "../../services/types";
import {
  dateToString,
  getCurrentUrlAsSource,
  getDateFromUrlParams,
  setCalendarParamsToUrl,
} from "../../services/utils";
import ArrowLeft from "../../svg/ArrowLeft.svg?react";
import ArrowRight from "../../svg/ArrowRight.svg?react";
import Link from "../Link";
import styles from "./MonthlyCalendar.module.css";
import MonthlyCell from "./MonthlyCell";
import { CalendarItem, CalendarListData, CalendarProps } from "./types";
import { getDailyTitle, getWeeklyTitle } from "./utils";

interface MonthlyCalendarProps extends CalendarProps {
  defaultMonth?: Date;
  denySelectDay?: true;
  showWeeks?: true;
}

const MonthlyCalendar: FC<MonthlyCalendarProps> = (props: MonthlyCalendarProps) => {
  const navigateTo = useNavigate();
  const aDate = getDateFromUrlParams();
  const [currentMonth, setCurrentMonth] = useState<Date>(aDate ?? props.defaultMonth ?? new Date());
  const [monthlyItems, setMonthlyItems] = useState<{ [key: string]: [CalendarListData, CalendarItem][] }>();

  const firstDayOfMonth = startOfMonth(currentMonth);
  const firstMonday = startOfWeek(firstDayOfMonth);
  const lastDayOfMonth = endOfMonth(currentMonth);
  const lastSunday = endOfWeek(lastDayOfMonth);

  let tmpDate = new Date(firstMonday);
  let allDays: Date[][] = [];
  let week = 0;
  while (!isAfter(tmpDate, lastSunday)) {
    if (!allDays[week]) {
      allDays[week] = [];
    }
    allDays[week]?.push(tmpDate);
    if (isSunday(tmpDate)) {
      week++;
    }
    tmpDate = addDays(tmpDate, 1);
  }

  const update = (date: Date) => {
    setCalendarParamsToUrl(navigateTo, date);
    props.updateCalendar(format(date, "MMMM yyyy"), "month");
  };

  const prevMonth = () => {
    const prevMonth = addMonths(currentMonth, -1);
    setCurrentMonth(prevMonth);
    update(prevMonth);
  };

  const nextMonth = () => {
    const nextMonth = addMonths(currentMonth, 1);
    setCurrentMonth(nextMonth);
    update(nextMonth);
  };

  useEffect(() => {
    (async () => {
      update(currentMonth);

      const allItems = await props.data.reduce(
        async (rv1: Promise<{ [key: string]: [CalendarListData, CalendarItem][] }>, d) => {
          const [rvResult, itemsResult] = await Promise.all([rv1, d.getItems(firstMonday, lastSunday)]);
          itemsResult.reduce((rv2, item) => {
            const key = format(item.from, "yyyy-MM-dd");
            (rv2[key] = rv2[key] || []).push([d, item]);
            return rv2;
          }, rvResult);
          return rv1;
        },
        Promise.resolve<{ [key: string]: [CalendarListData, CalendarItem][] }>({})
      );

      setMonthlyItems(allItems);
    })();
  }, [format(currentMonth, "yyyy-MM-dd")]);

  const showWeek = (date?: Date) => {
    if (date) {
      setCalendarParamsToUrl(navigateTo, date);
      props.updateCalendar(getWeeklyTitle(date), "week");
    }
  };

  const onDayClick = (date: Date) => {
    setCalendarParamsToUrl(navigateTo, date);
    !props.denySelectDay && props.updateCalendar(getDailyTitle(date), "day");
  };

  const getLinkToDay = (date: Date) => {
    const span = <span className={styles["cal-month-day-number"]}>{date.getDate()}</span>;
    return (
      <div
        className={classNames(styles["cal-month-day-title"], {
          [styles["cal-month-not-current"]]: date.getMonth() !== currentMonth.getMonth(),
        })}
      >
        <div onClick={() => onDayClick(date)}>{span}</div>
        {(props.addHref || props.onAddItem) && (
          <div
            onClick={() => !props.addHref && props.onAddItem && props.onAddItem(date)}
            className={styles["cal-month-add-item"]}
          >
            {props.addHref ? (
              <Link href={`${props.addHref}?date=${dateToString(date)}&${getCurrentUrlAsSource()}`}>+</Link>
            ) : (
              "+"
            )}
          </div>
        )}
      </div>
    );
  };

  const renderDay = (dailyData?: [CalendarListData, CalendarItem][]) => {
    if (!dailyData || dailyData.length == 0) return null;

    return dailyData
      .sort(([_, itemA], [__, itemB]) => itemA.from.valueOf() - itemB.from.valueOf())
      .map(([data, item], index) => {
        return (
          <Fragment key={`daily-entry-${item.from.toISOString()}-${index}`}>
            <MonthlyCell
              item={item}
              dispUrl={`${data.dispUrl}/${item.id}?${getCurrentUrlAsSource()}`}
              single={dailyData.length == 1}
            />
          </Fragment>
        );
      });
  };

  return (
    <table className={styles["cal-month"]} cellPadding={0} cellSpacing={0}>
      <tbody>
        <tr>
          <th></th>
          {props.showWeeks && (
            <th className={styles["cal-month-top"]}>
              <i>KW</i>
            </th>
          )}
          {weekDaysLong.map((x) => (
            <th key={`month-cal-header_${x}`} className={classNames(styles["cal-month-top"], styles["cal-month-day"])}>
              {x}
            </th>
          ))}
        </tr>
        {allDays.map((week, i) => (
          <Fragment key={`month-cal-row_${i}`}>
            <tr>
              {i == 0 && (
                <td
                  onClick={prevMonth}
                  rowSpan={allDays.length * 2}
                  title={`${format(addMonths(currentMonth, -1), "MMMM yyyy")}`}
                  className={styles["cal-month-nav"]}
                >
                  <div className={styles["cal-month-monthsel"]}>
                    <ArrowLeft />
                  </div>
                </td>
              )}
              {props.showWeeks && (
                <td
                  className={styles["cal-month-weeksel"]}
                  title={getWeeklyTitle(week[0])}
                  onClick={() => showWeek(week[0])}
                >
                  <span>
                    <i>
                      {(() => {
                        const w = week[0];
                        return w ? getWeek(w) : "";
                      })()}
                    </i>
                  </span>
                </td>
              )}
              {week.map((day) => (
                <td
                  key={`month-cal-row-${i}-dayrow-${day.getDate()}`}
                  className={classNames(styles["cal-month-cell"], {
                    [styles["cal-month-today"]]: isToday(day),
                  })}
                >
                  {getLinkToDay(day)}
                  <div className={styles["cal-month-day-items"]}>
                    {renderDay(monthlyItems?.[format(day, "yyyy-MM-dd")])}
                  </div>
                </td>
              ))}
              {i == 0 && (
                <td
                  onClick={nextMonth}
                  rowSpan={allDays.length * 2}
                  title={`${format(addMonths(currentMonth, 1), "MMMM yyyy")}`}
                  className={styles["cal-month-nav"]}
                >
                  <div className={styles["cal-month-monthsel"]}>
                    <ArrowRight />
                  </div>
                </td>
              )}
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default MonthlyCalendar;
