import { FC } from "react";
import { disableMouseDown, getInlineRotateStyle, getRotateStyle } from "./utils";

interface PickerPointProps {
  index: number;
  angle: number;
  onClick: (value: number, pointerRotate: number) => void;
  pointClass: string;
  pointerRotate: number;
}

const PickerPoint: FC<PickerPointProps> = (props: PickerPointProps) => {
  const inlineStyle = getInlineRotateStyle(props.angle);
  const wrapperStyle = getRotateStyle(-props.angle);

  const getRelativeRotate = (): number => {
    let relativeRotate = props.angle - (props.pointerRotate % 360);
    if (relativeRotate >= 180) {
      relativeRotate -= 360;
    } else if (relativeRotate < -180) {
      relativeRotate += 360;
    }
    return relativeRotate;
  };

  return (
    <div
      style={inlineStyle}
      className={props.pointClass}
      onClick={() => props.onClick(props.index, getRelativeRotate() + props.pointerRotate)}
      onMouseDown={(e) => disableMouseDown(e)}
    >
      <div style={wrapperStyle}>{props.index}</div>
    </div>
  );
};

export default PickerPoint;
