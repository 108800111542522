import { FC, useEffect } from "react";
import { Route } from "react-router";
import { Routes } from "react-router-dom";
import NotFound from "../../components/errors/NotFound";
import useGlobalState from "../../hooks/useGlobalState";
import { Conventions } from "../../listsSettings/conventions";
import { getSchemaByInternalName } from "../../services/webapi";
import AllItemsAttachments from "./attachments/AllItems";
import DispFormAttachments from "./attachments/DispForm";
import EditFormAttachments from "./attachments/EditForm";
import NewFormAttachments from "./attachments/NewForm";
import AllItemsCategories from "./categories/AllItems";
import DispFormCategories from "./categories/DispForm";
import EditFormCategories from "./categories/EditForm";
import NewFormCategories from "./categories/NewForm";
import AllItemsContacts from "./contacts/AllItems";
import DispFormContacts from "./contacts/DispForm";
import EditFormContacts from "./contacts/EditForm";
import NewFormContacts from "./contacts/NewForm";
import AllItemsConventions from "./conventions/AllItems";
import DispFormConventions from "./conventions/DispForm";
import EditFormConventions from "./conventions/EditForm";
import NewFormConventions from "./conventions/NewForm";
import AllItemsPartners from "./partners/AllItems";
import DispFormPartners from "./partners/DispForm";
import EditFormPartners from "./partners/EditForm";
import NewFormPartners from "./partners/NewForm";

const Home: FC = () => {
  const setCurrentSchema = useGlobalState((state) => state.setCurrentSchema);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const schema = await getSchemaByInternalName(Conventions.InternalName, abortController.signal);
      schema.data && setCurrentSchema(schema.data);
    })();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Routes>
      <Route path="/" element={<AllItemsConventions />} />

      <Route path={`_lists/${Conventions.Lists.Attachments.InternalName}`}>
        <Route index element={<AllItemsAttachments />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsAttachments />} />
          <Route path="new" element={<NewFormAttachments />} />
          <Route path="disp/:id" element={<DispFormAttachments />} />
          <Route path="edit/:id" element={<EditFormAttachments />} />
        </Route>
      </Route>

      <Route path={`_lists/${Conventions.Lists.Contacts.InternalName}`}>
        <Route index element={<AllItemsContacts />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsContacts />} />
          <Route path="new" element={<NewFormContacts />} />
          <Route path="disp/:id" element={<DispFormContacts />} />
          <Route path="edit/:id" element={<EditFormContacts />} />
        </Route>
      </Route>

      <Route path={`_lists/${Conventions.Lists.Categories.InternalName}`}>
        <Route index element={<AllItemsCategories />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsCategories />} />
          <Route path="new" element={<NewFormCategories />} />
          <Route path="disp/:id" element={<DispFormCategories />} />
          <Route path="edit/:id" element={<EditFormCategories />} />
        </Route>
      </Route>

      <Route path={`_lists/${Conventions.Lists.Conventions.InternalName}`}>
        <Route index element={<AllItemsConventions />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsConventions />} />
          <Route path="new" element={<NewFormConventions />} />
          <Route path="disp/:id" element={<DispFormConventions />} />
          <Route path="edit/:id" element={<EditFormConventions />} />
        </Route>
      </Route>

      <Route path={`_lists/${Conventions.Lists.Partners.InternalName}`}>
        <Route index element={<AllItemsPartners />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsPartners />} />
          <Route path="new" element={<NewFormPartners />} />
          <Route path="disp/:id" element={<DispFormPartners />} />
          <Route path="edit/:id" element={<EditFormPartners />} />
        </Route>
      </Route>

      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default Home;
