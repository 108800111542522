import { Item, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import { GroupFull } from "../groups/types";
import { ListFull } from "../lists/types";
import { PermissionFull } from "../permissions/types";
import * as Fields from "./Fields";

export interface ListGroupPermissionFull extends Item {
  id: number;
  list: ListFull;
  group: GroupFull;
  permission: PermissionFull;
}

export interface ListGroupPermissionPost {
  listId: number;
  groupId: string;
  permission: string;
}

export interface ListGroupPermissionForm {
  list: ListFull | null;
  group: GroupFull | null;
  permission: PermissionFull | null;
}

export const mapToPOST = (item: ListGroupPermissionForm): ListGroupPermissionPost => {
  return {
    listId: item.list?.id ?? 0,
    groupId: item.group?.id ?? "",
    permission: item.permission?.name ?? "",
  };
};

export const validate = (
  state: ListGroupPermissionForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.list) {
    errors[Fields.list.fieldName] = strings.required;
  }
  if (!state.group) {
    errors[Fields.group.fieldName] = strings.required;
  }
  if (!state.permission) {
    errors[Fields.permission.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};
