import { FC } from "react";
import { UserLight } from "../../home/administration/users/types";
import { CreationInformation } from "../../services/types";
import { formatDateFromISOString } from "../../services/utils";
import styles from "./CreatedModifiedInfo.module.css";

const renderLine = (
  text: "Erstellt" | "Zuletzt geändert",
  value?: UserLight | null,
  day?: string | null
): JSX.Element => (
  <div>
    <span>
      {text}&nbsp;am&nbsp;
      {formatDateFromISOString(day, true)}
    </span>
    {value?.name && <span className={styles.bold}>&nbsp;von&nbsp;{value?.name}</span>}
  </div>
);

interface CreatedModifiedInfoProps {
  info?: CreationInformation | null;
}
const CreatedModifiedInfo: FC<CreatedModifiedInfoProps> = ({ info }: CreatedModifiedInfoProps) => (
  <>
    {info && (
      <div className={styles.info}>
        <div style={{ display: "none" }}>Version: 12.0 -- TODO</div>
        {renderLine("Erstellt", info.author, info.createdAt)}
        {renderLine("Zuletzt geändert", info.editor || info.author, info.updatedAt || info.createdAt)}
      </div>
    )}
    <div>&nbsp;</div>
  </>
);

export default CreatedModifiedInfo;
