import { Item } from "../../../services/types";

export const getChapterTitle = (item: Chapter | null) => (item ? `${item.code} - ${item.name}` : null);

export interface Chapter extends Item {
  id: number;
  name: string;
  code: string;
  createdAt: string;
  updatedAt: string;
}
