import { FC } from "react";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import NumberInput from "../../../components/forms/NumberInput";
import { ValidationErrors } from "../../../services/types";
import * as Fields from "./Fields";
import { LegislatureForm } from "./types";

interface NewEditTableProps {
  state: LegislatureForm;
  setState: React.Dispatch<React.SetStateAction<LegislatureForm | undefined>>;
  errors: ValidationErrors;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.year.title}</FormLabel>
        <FormData>
          <NumberInput
            value={state.year}
            onChange={(value) =>
              setState({
                ...state,
                year: value,
              })
            }
          />
          <FormError error={errors[Fields.year.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.duration.title}</FormLabel>
        <FormData>
          <NumberInput
            value={state.durationYears}
            onChange={(value) =>
              setState({
                ...state,
                durationYears: value,
              })
            }
          />
          <FormError error={errors[Fields.duration.fieldName]} />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;
