import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Administration } from "../../../listsSettings/administration";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  administration,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  updateItem,
} from "../../../services/webapi";
import { PermissionFull, PermissionPost } from "./types";

const permissionsUrl: string = `${administration}/${Administration.Lists.Permissions.InternalName}`;
export const insertPermission = async (
  permission: PermissionPost,
  signal: AbortSignal
): Promise<Result<PermissionFull | null>> => insertItem(permissionsUrl, permission, signal);

export const updatePermission = async (
  permissionName: string,
  permission: PermissionPost,
  signal: AbortSignal
): Promise<Result<PermissionFull | null>> => updateItem(`${permissionsUrl}/${permissionName}`, permission, signal);

export const deletePermission = (name: string, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${permissionsUrl}/${name}`, signal);

export const deletePermissions = (
  names: string[],
  signal: AbortSignal
): Promise<Result<{ [key: string]: boolean } | null>> => deleteItems(permissionsUrl, names, signal);

export const getPermission = async (id: string, signal: AbortSignal): Promise<Result<PermissionFull | null>> =>
  getItem<PermissionFull>(`${permissionsUrl}/${id}`, signal);

export const getPermissions = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<PermissionFull> | null>> => getItems<PermissionFull>(permissionsUrl, pagination, signal);

export const getPermissionFilters = (
  fieldName: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(permissionsUrl, fieldName, signal);
