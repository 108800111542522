import { Item, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import { PermissionFull } from "../permissions/types";
import { SchemaFull } from "../schemas/types";
import { UserFull } from "../users/types";
import * as Fields from "./Fields";

export interface SchemaUserPermissionFull extends Item {
  id: number;
  schema: SchemaFull;
  user: UserFull;
  permission: PermissionFull;
}

export interface SchemaUserPermissionForm {
  schema: SchemaFull | null;
  user: UserFull | null;
  permission: PermissionFull | null;
}

export interface SchemaUserPermissionPost {
  schemaId: number;
  userId: string;
  permission: string;
}

export const mapToPOST = (item: SchemaUserPermissionForm): SchemaUserPermissionPost => {
  return {
    schemaId: item.schema?.id || 0,
    userId: item.user?.id || "",
    permission: item.permission?.name || "",
  };
};

export const validate = (
  state: SchemaUserPermissionForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.schema) {
    errors[Fields.schema.fieldName] = strings.required;
  }
  if (!state.user) {
    errors[Fields.user.fieldName] = strings.required;
  }
  if (!state.permission) {
    errors[Fields.permission.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};
