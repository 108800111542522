import { FC } from "react";
import { MenuOptionValue } from "../../services/types";
import { formatBoolean } from "../../services/utils";
import styles from "./HeaderMenuOption.module.css";

export interface Option {
  value: MenuOptionValue;
  selected?: boolean;
}

interface HeaderMenuOptionProps {
  option: Option;
  updateFilters: (option: Option) => void;
  formatter?: (value: MenuOptionValue) => string;
}

const HeaderMenuOption: FC<HeaderMenuOptionProps> = (props: HeaderMenuOptionProps) => {
  const onChange = () => {
    const option: Option = {
      value: props.option.value,
      selected: !props.option.selected,
    };

    props.updateFilters(option);
  };

  const value = props.formatter
    ? props.formatter(props.option.value)
    : props.option.value === null || props.option.value === undefined || props.option.value === ""
    ? "(Leer)"
    : props.option.value === true || props.option.value === false
    ? formatBoolean(props.option.value)
    : props.option.value;

  const optionId = `${Math.random()}`;

  return (
    <label>
      <div className={styles.option}>
        <input type="checkbox" id={optionId} checked={props.option.selected} onChange={onChange} />
        <div className={styles.unselectable}>{value}</div>
      </div>
    </label>
  );
};

export default HeaderMenuOption;
