import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import { isEmailValid } from "../../../services/utils";
import strings from "../../../strings";
import * as Fields from "./Fields";

export interface GroupFull extends CreationInformationItem, GroupPost {
  id: string;
}

export interface GroupPost {
  name: string;
  description: string | null;
  email: string | null;
  isActiveDirectoryGroup: boolean;
}

export interface GroupForm {
  name: string | null;
  description: string | null;
  email: string | null;
  isActiveDirectoryGroup: boolean;
}

export const mapToPOST = (item: GroupForm): GroupPost => {
  return {
    name: item.name || "",
    description: item.description,
    email: item.email,
    isActiveDirectoryGroup: item.isActiveDirectoryGroup,
  };
};

export const validate = (
  state: GroupForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.name) {
    errors[Fields.name.fieldName] = strings.required;
  }
  if (state.email && !isEmailValid(state.email)) {
    errors[Fields.email.fieldName] = strings.invalidText;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};
