import * as React from "react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import DropDownContext from "./DropDownItemContext";
import styles from "./DropDownItems.module.css";

interface DropDownItemsProps {
  dropDownRef: React.Ref<HTMLDivElement>;
  onClose: () => void;
}

const DropDownItems: FC<React.PropsWithChildren<DropDownItemsProps>> = ({ children, dropDownRef, onClose }) => {
  const [items, setItems] = useState<React.RefObject<HTMLDivElement>[]>();
  const [highlightedItem, setHighlightedItem] = useState<React.RefObject<HTMLDivElement>>();

  const registerItem = useCallback(
    (itemRef: React.RefObject<HTMLDivElement>) => {
      setItems((prev) => (prev ? [...prev, itemRef] : [itemRef]));
    },
    [setItems]
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!items) return;

    const key = event.key;

    if (["Escape", "ArrowUp", "ArrowDown", "Tab"].includes(key)) {
      event.preventDefault();
    }

    if (key === "Escape" || key === "Tab") {
      onClose();
    } else if (key === "ArrowUp") {
      setHighlightedItem((prev) => {
        if (!prev) return items[0];
        const index = items.indexOf(prev) - 1;
        return items[index === -1 ? items.length - 1 : index];
      });
    } else if (key === "ArrowDown") {
      setHighlightedItem((prev) => {
        if (!prev) return items[0];
        return items[items.indexOf(prev) + 1];
      });
    }
  };

  const contextValue = useMemo(
    () => ({
      registerItem,
    }),
    [registerItem]
  );

  useEffect(() => {
    if (items && !highlightedItem) {
      setHighlightedItem(items[0]);
    }

    if (highlightedItem && highlightedItem.current) {
      highlightedItem.current.focus();
    }
  }, [items, highlightedItem]);

  return (
    <DropDownContext.Provider value={contextValue}>
      <div className={styles.items} ref={dropDownRef} onKeyDown={handleKeyDown}>
        {children}
      </div>
    </DropDownContext.Provider>
  );
};

export default DropDownItems;
