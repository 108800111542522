import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Errors, { getString } from "../../../components/errors/Errors";
import Listview from "../../../components/listview/Listview";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import useAbortController from "../../../hooks/useAbortController";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Administration } from "../../../listsSettings/administration";
import { ErrorCode, IAction, IListviewField, SearchResult, UserPermissions } from "../../../services/types";
import { getCurrentUrlAsSource, getSelectedItems } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import * as Fields from "./Fields";
import { ViewFull } from "./types";
import { editForm, newForm } from "./urls";
import { deleteViews, getViewFilters, getViews } from "./webapi";

const AllItems: FC = () => {
  const [abortController, resetAbortController] = useAbortController();
  const listviewId = "views-all-items";
  const fields: IListviewField<ViewFull>[] = [
    Fields.name,
    Fields.internalName,
    Fields.showSearchBox,
    Fields.itemsPerPage,
    Fields.list,
    Fields.schema,
  ];

  const statusBar = useStatusBarState();
  const [views, setViews] = useState<SearchResult<ViewFull> | null>(null);
  const [errorCode, setErrorCode] = useState<ErrorCode>();

  const [permissions, setPermissions] = useState<UserPermissions>();
  const navigateTo = useNavigate();

  const pagination = getPagination(listviewId) || {
    ...DefaultPagination,
  };

  useEffect(() => {
    loadViews(pagination);

    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const loadViews = async (pagination: Pagination) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Administration.Lists.Views.InternalName,
      Administration.InternalName,
      abortController.signal
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getViews(pagination, abortController.signal);
        if (!abortController.signal.aborted) {
          result.data && setViews(result.data);
          result.error && setErrorCode(result.error.code);
        }
      }
    }
  };

  const newAction: IAction = {
    title: strings.new,
    icon: Add,

    onClick: () => navigateTo(`${newForm}?${getCurrentUrlAsSource()}`),
    disabled: !permissions || !permissions.listPermissions.includes("create"),
  };
  const editAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => {
      const selectedItem = getSelectedItems(views)[0];
      selectedItem && navigateTo(`${editForm}/${selectedItem.id}?${getCurrentUrlAsSource()}`);
    },
    disabled: getSelectedItems(views).length !== 1 || !permissions?.listPermissions.includes("edit-all"),
  };
  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: async () => {
      const selectedItems = getSelectedItems(views);
      if (selectedItems.length > 0 && confirm(strings.confirmDeleteItems)) {
        const abortController = resetAbortController();
        const result = await deleteViews(
          selectedItems.map((x) => x.id),
          abortController.signal
        );

        if (result.error) {
          statusBar.addError(getString(result.error));
        } else if (result.data) {
          const s = selectedItems.filter((x) => x.id && result.data?.[x.id]);

          if (s.length != selectedItems.length) {
            statusBar.addWarning(strings.notAllElementsDeleted);
          }
          if (s.length > 0) {
            await loadViews(pagination);
          }
        }
      }
    },
    disabled: getSelectedItems(views).length === 0 || !permissions?.listPermissions.includes("delete-all"),
  };

  const actions = [newAction, editAction, deleteAction];

  return (
    <>
      {permissions &&
        (!errorCode ? (
          <Listview
            id={listviewId}
            actions={actions}
            viewProperties={{
              schema: Administration.InternalName,
              list: Administration.Lists.Views.InternalName,
              name: "all_items",
              setListAndPage: true,
            }}
            fields={fields}
            allowSelectRows
            reload={loadViews}
            searchResult={views}
            getFilters={getViewFilters}
            pagination={pagination}
            updateItems={(items) =>
              views &&
              setViews({
                ...views,
                items: items,
              })
            }
          />
        ) : (
          errorCode && <Errors errorCode={errorCode} />
        ))}
    </>
  );
};

export default AllItems;
