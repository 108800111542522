import { FC } from "react";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Lookup from "../../../components/forms/Lookup";
import { Api } from "../../../listsSettings/api";
import { ValidationErrors } from "../../../services/types";
import * as UserFields from "../users/Fields";
import { searchUsers } from "../users/webapi";
import * as Fields from "./Fields";
import { UserFunctionaryPermissionForm } from "./types";

interface NewEditTableProps {
  state: UserFunctionaryPermissionForm;
  setState: React.Dispatch<React.SetStateAction<UserFunctionaryPermissionForm | undefined>>;
  errors: ValidationErrors;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.userName.title}</FormLabel>
        <FormData>
          <Lookup
            disabled={!!state.user?.id}
            value={state.user?.name ?? ""}
            onChange={(value) =>
              setState({
                ...state,
                user: value,
              })
            }
            searchItems={searchUsers}
            searchDialogTitle={Api.Lists.UserFunctionaryPermissions.Title}
            searchDialogFields={[UserFields.name, UserFields.key]}
            getTitle={(item) => item.name ?? ""}
          />
          <FormError error={errors[Fields.userName.fieldName]} />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;
