import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import * as Fields from "./Fields";

export interface SchemaFull extends CreationInformationItem, SchemaPost {
  id: number;
}

export interface SchemaPost {
  name: string;
  internalName: string;
}

export interface SchemaForm {
  name: string | null;
  internalName: string | null;
}

export const mapToPOST = (item: SchemaForm): SchemaPost => {
  return {
    name: item.name || "",
    internalName: item.internalName || "",
  };
};

export const dispUrl = (item: SchemaForm) => (item.internalName ? `/${item.internalName.trim()}` : "/");

export const validate = (
  state: SchemaForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.name?.trim()) {
    errors[Fields.name.fieldName] = strings.required;
  }
  if (!state.internalName?.trim()) {
    errors[Fields.internalName.fieldName] = strings.required;
  } else if (state.internalName?.indexOf(" ") >= 0) {
    errors[Fields.internalName.fieldName] = strings.invalidText;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};
