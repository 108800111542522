import { FC } from "react";
import styles from "./NavigationButtons.module.css";

interface NavigationButtonsProps {
  children: React.ReactNode;
}

const NavigationButtons: FC<NavigationButtonsProps> = ({ children }: NavigationButtonsProps) => (
  <div className={styles.buttons}>{children}</div>
);

export default NavigationButtons;
