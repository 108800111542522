import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Administration } from "../../../listsSettings/administration";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  administration,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  putReference,
  removeReferencedItems,
  updateItem,
} from "../../../services/webapi";
import { GroupFull } from "../groups/types";
import { UserFull } from "../users/types";
import { ViewFull, ViewPost } from "./types";

const viewsUrl: string = `${administration}/${Administration.Lists.Views.InternalName}`;
export const insertView = async (view: ViewPost, signal: AbortSignal): Promise<Result<ViewFull | null>> =>
  insertItem(viewsUrl, view, signal);

export const updateView = async (
  viewId: number,
  view: ViewPost,
  signal: AbortSignal
): Promise<Result<ViewFull | null>> => updateItem(`${viewsUrl}/${viewId}`, view, signal);

export const deleteView = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${viewsUrl}/${id}`, signal);

export const deleteViews = (ids: number[], signal: AbortSignal): Promise<Result<{ [key: number]: boolean } | null>> =>
  deleteItems(viewsUrl, ids, signal);

export const getView = async (id: number, signal: AbortSignal): Promise<Result<ViewFull | null>> =>
  getItem<ViewFull>(`${viewsUrl}/${id}`, signal);

export const getViews = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<ViewFull> | null>> => getItems<ViewFull>(viewsUrl, pagination, signal);

export const getViewFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(viewsUrl, fieldName, signal);

export const getViewViews = async (
  viewId: number,
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<ViewFull> | null>> =>
  getItems<ViewFull>(`${viewsUrl}/${viewId}/views`, pagination, signal);

export const getViewViewsFilters = (
  viewId: number,
  fieldname: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${viewsUrl}/${viewId}/views`, fieldname, signal);

export const removeViews = (
  parent: ViewFull,
  views: ViewFull[],
  signal: AbortSignal
): Promise<Result<{ [key: string]: boolean } | null>> => {
  const url = `${viewsUrl}/${parent.id}/views`;
  return removeReferencedItems(
    url,
    views.reduce((rv: number[], x) => {
      x.id && rv.push(x.id);
      return rv;
    }, []),
    signal
  );
};

export const getViewUsers = async (
  viewId: number,
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<UserFull> | null>> =>
  getItems<UserFull>(`${viewsUrl}/${viewId}/users`, pagination, signal);

export const getViewUsersFilters = (
  viewId: number,
  fieldname: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${viewsUrl}/${viewId}/users`, fieldname, signal);

export const getViewGroups = async (
  viewId: number,
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<GroupFull> | null>> =>
  getItems<GroupFull>(`${viewsUrl}/${viewId}/groups`, pagination, signal);

export const getViewGroupsFilters = (
  viewId: number,
  fieldname: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${viewsUrl}/${viewId}/groups`, fieldname, signal);

export const addUser = (view: ViewFull, user: UserFull, signal: AbortSignal): Promise<CustomError | null> => {
  const url = `${viewsUrl}/${view.id}/user/${user.id}`;
  return putReference(url, signal);
};

export const addGroup = (view: ViewFull, group: GroupFull, signal: AbortSignal): Promise<CustomError | null> => {
  const url = `${viewsUrl}/${view.id}/group/${group.id}`;
  return putReference(url, signal);
};

export const removeGroups = (
  view: ViewFull,
  groups: GroupFull[],
  signal: AbortSignal
): Promise<Result<{ [key: string]: boolean } | null>> => {
  const url = `${viewsUrl}/${view.id}/groups`;
  return removeReferencedItems(
    url,
    groups.map((x) => x.id),
    signal
  );
};

export const removeUsers = (
  view: ViewFull,
  users: UserFull[],
  signal: AbortSignal
): Promise<Result<{ [key: string]: boolean } | null>> => {
  const url = `${viewsUrl}/${view.id}/users`;
  return removeReferencedItems(
    url,
    users.map((x) => x.id),
    signal
  );
};

export const addViewView = (parent: ViewFull, view: ViewFull, signal: AbortSignal): Promise<CustomError | null> => {
  const url = `${viewsUrl}/${parent.id}/view/${view.id}`;
  return putReference(url, signal);
};
