import Link from "../../../components/Link";
import { IListviewField } from "../../../services/types";
import { getCurrentUrlAsSource } from "../../../services/utils";
import { UserFunctionaryPermissionFull } from "./types";
import { dispForm } from "./urls";

export const userName: IListviewField<UserFunctionaryPermissionFull> = {
  title: "Benutzer",
  fieldName: "userName",
  isSortable: true,
  renderListValue: (item: UserFunctionaryPermissionFull) => (
    <Link href={`${dispForm}/${item.user.id}?${getCurrentUrlAsSource()}`}>{item.user.name}</Link>
  ),
};

export const userKey: IListviewField<UserFunctionaryPermissionFull> = {
  title: "Schlüssel",
  fieldName: "userKey",
  isSortable: true,
  renderListValue: (item: UserFunctionaryPermissionFull) => item.user.key,
};
