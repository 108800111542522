import { FC } from "react";
import CheckBox from "../../../components/forms/CheckBox";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import Lookup from "../../../components/forms/Lookup";
import NumberInput from "../../../components/forms/NumberInput";
import { Administration } from "../../../listsSettings/administration";
import { ValidationErrors } from "../../../services/types";
import * as Fields from "./Fields";
import { GlobalNavigationItemForm } from "./types";
import { searchGlobalNavigationItems } from "./webapi";

interface NewEditTableProps {
  state: GlobalNavigationItemForm;
  setState: React.Dispatch<React.SetStateAction<GlobalNavigationItemForm | undefined>>;
  errors: ValidationErrors;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.name.title}</FormLabel>
        <FormData>
          <Input
            value={state.name}
            onChange={(value) =>
              setState({
                ...state,
                name: value,
              })
            }
          />
          <FormError error={errors[Fields.name.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.url.title}</FormLabel>
        <FormData>
          <Input
            value={state.url}
            onChange={(value) =>
              setState({
                ...state,
                url: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.order.title}</FormLabel>
        <FormData>
          <NumberInput
            value={state.order}
            onChange={(value) =>
              setState({
                ...state,
                order: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.openNewTab.title}</FormLabel>
        <FormData>
          <CheckBox
            value={state.openNewTab}
            onChange={(value) =>
              setState({
                ...state,
                openNewTab: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.parent.title}</FormLabel>
        <FormData>
          <Lookup
            disabled={!!state.parent?.id}
            value={state.parent?.name ?? ""}
            onChange={(value) =>
              setState({
                ...state,
                parent: value,
              })
            }
            searchItems={searchGlobalNavigationItems}
            searchDialogTitle={Administration.Lists.GlobalNavigationItems.Title}
            searchDialogFields={[Fields.name, Fields.url]}
            getTitle={(item) => item.name ?? ""}
          />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;
