import { FC, Fragment, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { IAction } from "../../services/types";
import Menu from "../../svg/Menu.svg?react";
import Action from "./Action";
import styles from "./Actions.module.css";

interface ActionsProps {
  actions?: IAction[];
  otherActions?: IAction[];
}

const Actions: FC<ActionsProps> = ({ actions, otherActions }: ActionsProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const openMenu = () => setShowMenu(true);
  const closeMenu = () => setShowMenu(false);
  const ref = useOutsideClick<HTMLDivElement>(closeMenu);

  return (
    <div className={styles.actions}>
      {actions?.map(
        (x, i) =>
          x && (
            <Fragment key={`action_${i}`}>
              <Action disabled={x.disabled} active={x.active} title={x.title} icon={x.icon} onClick={x.onClick} />
            </Fragment>
          )
      )}
      {otherActions?.length && (
        <div ref={ref} className={styles["other-actions"]}>
          <Action title={"Mehr"} icon={Menu} onClick={openMenu} />
          {showMenu && (
            <div className={styles.menu}>
              {otherActions.map(
                (x, i) =>
                  x && (
                    <Fragment key={`action_${i}`}>
                      <Action
                        disabled={x.disabled}
                        title={x.title}
                        icon={x.icon}
                        onClick={() => {
                          closeMenu();
                          x.onClick();
                        }}
                      />
                    </Fragment>
                  )
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Actions;
