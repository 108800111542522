import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import * as Fields from "./Fields";

export interface RoleFull extends CreationInformationItem {
  id: number;
  name: string;
  forDistricts: boolean;
  forAreas: boolean;
  forChapters: boolean;
}

export interface RoleForm {
  name: string | null;
  forDistricts: boolean;
  forAreas: boolean;
  forChapters: boolean;
}

export interface RolePost {
  name: string;
  forDistricts: boolean;
  forAreas: boolean;
  forChapters: boolean;
}

export const mapToPOST = (item: RoleForm): RolePost => {
  return {
    name: item.name || "",
    forDistricts: !!item.forDistricts,
    forAreas: !!item.forAreas,
    forChapters: !!item.forChapters,
  };
};

export const validate = (state: RoleForm, setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>) => {
  const errors: ValidationErrors = {};
  if (!state.name) {
    errors[Fields.name.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};
