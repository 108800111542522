import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Api } from "../../../listsSettings/api";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  api,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  updateItem,
} from "../../../services/webapi";
import { UserFunctionaryPermissionFull, UserFunctionaryPermissionPost } from "./types";

const userFunctionaryPermissionUrl: string = `${api}/${Api.Lists.UserFunctionaryPermissions.InternalName}`;
export const insertUserFunctionaryPermission = async (
  userFunctionaryPermission: UserFunctionaryPermissionPost,
  signal: AbortSignal
): Promise<Result<UserFunctionaryPermissionFull | null>> =>
  insertItem(userFunctionaryPermissionUrl, userFunctionaryPermission, signal);

export const updateUserFunctionaryPermission = async (
  userFunctionaryPermissionId: number,
  userFunctionaryPermission: UserFunctionaryPermissionPost,
  signal: AbortSignal
): Promise<Result<UserFunctionaryPermissionFull | null>> =>
  updateItem(`${userFunctionaryPermissionUrl}/${userFunctionaryPermissionId}`, userFunctionaryPermission, signal);

export const deleteUserFunctionaryPermission = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${userFunctionaryPermissionUrl}/${id}`, signal);

export const deleteUserFunctionaryPermissions = (
  ids: number[],
  signal: AbortSignal
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(userFunctionaryPermissionUrl, ids, signal);

export const getUserFunctionaryPermission = async (
  id: number,
  signal: AbortSignal
): Promise<Result<UserFunctionaryPermissionFull | null>> =>
  getItem<UserFunctionaryPermissionFull>(`${userFunctionaryPermissionUrl}/${id}`, signal);

export const getUserFunctionaryPermissions = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<UserFunctionaryPermissionFull> | null>> =>
  getItems<UserFunctionaryPermissionFull>(userFunctionaryPermissionUrl, pagination, signal);

export const getUserFunctionaryPermissionFilters = (
  fieldName: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(userFunctionaryPermissionUrl, fieldName, signal);
