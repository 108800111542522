import { IDialogField, IListviewField } from "../../../services/types";
import { Chapter } from "./types";

export const code: IListviewField<Chapter> & IDialogField<Chapter> = {
  title: "Kode",
  fieldName: "Code",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: Chapter) => item.code,
  renderDialogValue: (item: Chapter) => item.code,
};

export const name: IListviewField<Chapter> & IDialogField<Chapter> = {
  title: "Name",
  fieldName: "Name",
  isSortable: true,
  renderListValue: (item: Chapter) => item.name,
  renderDialogValue: (item: Chapter) => item.name,
};
