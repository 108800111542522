import { FC, useEffect, useState } from "react";
import Errors from "../../../components/errors/Errors";
import Listview from "../../../components/listview/Listview";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import useAbortController from "../../../hooks/useAbortController";
import { Members } from "../../../listsSettings/members";
import { ErrorCode, IListviewField, SearchResult, UserPermissions } from "../../../services/types";
import { getUserPermissions } from "../../../services/webapi";
import * as Fields from "./Fields";
import { AccountingItem } from "./types";
import { getAccountingOpenItemFilters, getAccountingOpenItems } from "./webapi";

const OpenItems: FC = () => {
  const [abortController, resetAbortController] = useAbortController();
  const listviewId = "acocunting-open-items";
  const fields: IListviewField<AccountingItem>[] = [
    Fields.documentNumber,
    Fields.documentDate,
    Fields.member,
    Fields.dunningLevel,
    Fields.dunningDate,
    Fields.company,
    Fields.dueDate,
    Fields.amount,
    Fields.debited,
    Fields.balance,
    Fields.paymentMethod,
  ];

  const [chapters, setAccountingItems] = useState<SearchResult<AccountingItem> | null>(null);
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();

  const pagination = getPagination(listviewId) || {
    ...DefaultPagination,
    orderBy: Fields.documentDate.fieldName,
    orderByDescending: true,
  };

  useEffect(() => {
    loadAccountingItems(pagination);

    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const loadAccountingItems = async (pagination: Pagination) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Members.Lists.AccountingItems.InternalName,
      Members.InternalName,
      abortController.signal
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getAccountingOpenItems(pagination, abortController.signal);
        if (!abortController.signal.aborted) {
          result.data && setAccountingItems(result.data);
          result.error && setErrorCode(result.error.code);
        }
      }
    }
  };

  return (
    <>
      {permissions &&
        (!errorCode ? (
          <Listview
            id={listviewId}
            viewProperties={{
              schema: Members.InternalName,
              list: Members.Lists.AccountingItems.InternalName,
              name: "open_items",
              setListAndPage: true,
            }}
            fields={fields}
            reload={loadAccountingItems}
            searchResult={chapters}
            getFilters={getAccountingOpenItemFilters}
            pagination={pagination}
            updateItems={(items) =>
              chapters &&
              setAccountingItems({
                ...chapters,
                items: items,
              })
            }
          />
        ) : (
          errorCode && <Errors errorCode={errorCode} />
        ))}
    </>
  );
};

export default OpenItems;
