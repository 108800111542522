import { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const useOutsideClick = <T extends HTMLElement>(onOutsideClick: () => void): React.RefObject<T> => {
  const ref = useRef<T>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ReactDOM.findDOMNode(ref.current)?.contains(event.target as Node)) {
      onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return ref;
};

export default useOutsideClick;
