import classNames from "classnames";
import { FC, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { formatTimeFromMinutes } from "../../services/utils";
import Clock from "../../svg/Clock.svg?react";
import DialPlate from "../timePicker/DialPlate";
import styles from "./TimePickerWithInput.module.css";

export interface TimePickerWithInputProps {
  value: number | null;
  onSelect: (value: number | null, close?: boolean) => void;
  autoMode?: boolean;
  borderLeft?: boolean;
}

const TimePickerWithInput: FC<TimePickerWithInputProps> = (props: TimePickerWithInputProps) => {
  const [state, setState] = useState({
    show: false,
    value: formatTimeFromMinutes(props.value),
  });

  const getHoursOrNow = (value: string): number => {
    const splitted = value.split(":");
    const n = Number(splitted[0]);
    return (splitted[0] && !isNaN(n) ? n : new Date().getHours()) * 60;
  };

  const getMinutesOrNow = (value: string): number => {
    const splitted = value.split(":");
    const n = Number(splitted[1]);
    return splitted[1] && !isNaN(n) ? n : new Date().getMinutes();
  };

  const getTimeOrNow = (value: string): number => {
    return getMinutesOrNow(value) + getHoursOrNow(value);
  };

  const onChange = (value: string) => {
    setState((prevstate) => {
      return {
        ...prevstate,
        value: value,
      };
    });
  };

  const onSelect = (value: number | null, close: boolean) => {
    close && props.onSelect(value);
    setState({
      show: !close,
      value: formatTimeFromMinutes(value),
    });
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (state.value.indexOf(":") >= 0 && event.key === ":") {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      state.value.length === 3 &&
      state.value.substring(2, 3) === ":"
    ) {
      event.preventDefault();
      event.stopPropagation();
      setState({
        ...state,
        value: state.value.substring(0, 1),
      });
    }
  };

  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    //const keyInNumber = Number(event.key);

    if (
      event.key !== " " &&
      //&& !isNaN(keyInNumber)
      state.value.length == 2 &&
      state.value.indexOf(":") < 0
    ) {
      setState({
        ...state,
        value: state.value + ":",
      });
    }

    const splitted = state.value.split(":");
    const h = Number(splitted[0]);

    if (splitted.length === 1) {
      props.onSelect(h);
      return;
    }
    const m = Number(splitted[1]);

    const time = h * 60 + m;
    props.onSelect(time);
  };

  const ref = useOutsideClick<HTMLDivElement>(() =>
    setState((prevstate) => {
      return {
        ...prevstate,
        show: false,
      };
    })
  );

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles["inner-container"], {
          [styles["border-left"]]: props.borderLeft,
          [styles["border-left-transparent"]]: !props.borderLeft,
        })}
      >
        <input
          type="text"
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          value={state.value}
          maxLength={5}
          onChange={(event) => onChange(event.target.value)}
          className={classNames(styles.input, styles.small)}
        />
        <div
          onClick={() =>
            setState({
              ...state,
              show: true,
            })
          }
          className={styles["svg-container"]}
        >
          <Clock />
        </div>
        {state.show && (
          <div className={styles["time-picker"]}>
            <div className={styles["time-picker-wrapper"]} ref={ref}>
              <div className={styles["time-picker-container"]}>
                <DialPlate
                  type={"hour"}
                  autoMode={props.autoMode}
                  minutes={getTimeOrNow(state.value)}
                  onChange={onSelect}
                  autoClose={true}
                  timeMode={24}
                  minuteStep={1}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimePickerWithInput;
