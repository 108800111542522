import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Functionaries } from "../../../listsSettings/functionaries";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  deleteItem,
  deleteItems,
  functionaries,
  getFilters,
  getItem,
  getItems,
  insertItem,
  searchItems,
  updateItem,
} from "../../../services/webapi";
import { ChapterFull } from "../chapters/types";
import { AreaFull, AreaPost } from "./types";

const areasUrl: string = `${functionaries}/${Functionaries.Lists.Areas.InternalName}`;
export const insertArea = async (area: AreaPost, signal: AbortSignal): Promise<Result<AreaFull | null>> =>
  insertItem(areasUrl, area, signal);

export const updateArea = async (
  areaId: number,
  area: AreaPost,
  signal: AbortSignal
): Promise<Result<AreaFull | null>> => updateItem(`${areasUrl}/${areaId}`, area, signal);

export const deleteArea = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${areasUrl}/${id}`, signal);

export const deleteAreas = (ids: number[], signal: AbortSignal): Promise<Result<{ [key: number]: boolean } | null>> =>
  deleteItems(areasUrl, ids, signal);

export const getArea = async (id: number, signal: AbortSignal): Promise<Result<AreaFull | null>> =>
  getItem<AreaFull>(`${areasUrl}/${id}`, signal);

export const getAreas = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<AreaFull> | null>> => getItems<AreaFull>(areasUrl, pagination, signal);

export const searchAreas = async (
  searchValue: string,
  signal: AbortSignal
): Promise<Result<SearchResult<AreaFull> | null>> => searchItems<AreaFull>(areasUrl, searchValue, signal);

export const getAreaFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(areasUrl, fieldName, signal);

export const getAreaChapters = async (
  areaId: number,
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<ChapterFull> | null>> =>
  getItems<ChapterFull>(`${areasUrl}/${areaId}/chapters`, pagination, signal);

export const getAreaChaptersFilters = (
  areaId: number,
  fieldname: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${areasUrl}/${areaId}/chapters`, fieldname, signal);
