import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import { Company } from "../../members/companies/types";
import { PersonFull } from "../../public/persons/types";
import { AreaFull } from "../areas/types";
import { ChapterFull } from "../chapters/types";
import { DistrictFull } from "../districts/types";
import { LegislatureFull } from "../legislatures/types";
import { RoleFull } from "../roles/types";
import * as Fields from "./Fields";

export interface FunctionaryFull extends CreationInformationItem {
  id: number;
  person: PersonFull;
  legislature: LegislatureFull;
  chapter: ChapterFull | null;
  area: AreaFull | null;
  district: DistrictFull | null;
  role: RoleFull;
  electionDate: string;
  company: Company | null;
  endDate: string | null;
}

export interface FunctionaryForm {
  person: PersonFull | null;
  legislature: LegislatureFull | null;
  chapter: ChapterFull | null;
  area: AreaFull | null;
  district: DistrictFull | null;
  role: RoleFull | null;
  electionDate: string | null;
  company: Company | null;
  endDate: string | null;
}

export interface FunctionaryPost {
  personId: number;
  legislatureId: number;
  chapterId: number | null;
  areaId: number | null;
  districtId: number | null;
  roleId: number;
  electionDate: string | null;
  companyId: number | null;
  endDate: string | null;
}

export const mapToPOST = (item: FunctionaryForm): FunctionaryPost => {
  return {
    personId: item.person?.id || 0,
    legislatureId: item.legislature?.id || 0,
    chapterId: item.chapter?.id || null,
    areaId: item.area?.id || null,
    districtId: item.district?.id || null,
    roleId: item.role?.id || 0,
    electionDate: item.electionDate || "",
    companyId: item.company?.id || null,
    endDate: item.endDate || null,
  };
};

export const validate = (
  state: FunctionaryForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.legislature) {
    errors[Fields.legislature.fieldName] = strings.required;
  }
  if (!state.person) {
    errors[Fields.person.fieldName] = strings.required;
  }
  if (!state.role) {
    errors[Fields.role.fieldName] = strings.required;
  }
  if (!state.electionDate) {
    errors[Fields.electionDate.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};

export interface OrganizationChart {
  president: FunctionaryFull | null;
  countryCommittee: FunctionaryFull[];
  districtCommittee: FunctionaryFull[];
  areaCommittee: FunctionaryFull[];
  chapters: number;
  members: number;
}
