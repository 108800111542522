import { FC, useEffect, useState } from "react";
import DatePicker from "../../../components/forms/DatePickerWithInput";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Lookup from "../../../components/forms/Lookup";
import SelectBox from "../../../components/forms/SelectBox";
import { DefaultPagination } from "../../../components/listview/Pagination";
import { Functionaries } from "../../../listsSettings/functionaries";
import { Members } from "../../../listsSettings/members";
import { Public } from "../../../listsSettings/public";
import { ValidationErrors } from "../../../services/types";
import * as CompanyFields from "../../members/companies/Fields";
import { searchCompanies } from "../../members/companies/webapi";
import * as PersonFields from "../../public/persons/Fields";
import { getItemTitle } from "../../public/persons/types";
import { searchPersons } from "../../public/persons/webapi";
import * as ChapterFields from "../chapters/Fields";
import { searchChapters } from "../chapters/webapi";
import * as LegislatureFields from "../legislatures/Fields";
import SelectBoxLegislature from "../legislatures/SelectBoxLegislature";
import { LegislatureFull } from "../legislatures/types";
import { getLegislatures } from "../legislatures/webapi";
import * as RoleFields from "../roles/Fields";
import { RoleFull } from "../roles/types";
import { getRoles } from "../roles/webapi";
import * as Fields from "./Fields";
import { FunctionaryForm } from "./types";

interface NewEditTableProps {
  state: FunctionaryForm;
  setState: React.Dispatch<React.SetStateAction<FunctionaryForm | undefined>>;
  errors: ValidationErrors;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  const [legislatures, setLegislature] = useState<LegislatureFull[]>([]);
  const [roles, setRoles] = useState<RoleFull[]>([]);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const [legislatures, roles] = await Promise.all([
        getLegislatures(
          {
            ...DefaultPagination,
            orderBy: LegislatureFields.year.fieldName,
            orderByDescending: true,
          },
          abortController.signal
        ),
        getRoles(
          {
            ...DefaultPagination,
            orderBy: RoleFields.name.fieldName,
          },
          abortController.signal
        ),
      ]);
      legislatures.data && setLegislature(legislatures.data.items);
      roles.data && setRoles(roles.data.items);
    })();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.legislature.title}</FormLabel>
        <FormData>
          <SelectBoxLegislature
            value={state.legislature}
            onChange={(value) =>
              setState({
                ...state,
                legislature: value,
              })
            }
            options={legislatures}
          />
          <FormError error={errors[Fields.legislature.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.person.title}</FormLabel>
        <FormData>
          <Lookup
            disabled={!!state.person?.id}
            value={getItemTitle(state.person)}
            onChange={(value) =>
              setState({
                ...state,
                person: value,
              })
            }
            searchItems={searchPersons}
            searchDialogTitle={Public.Persons.Title}
            searchDialogFields={[PersonFields.lastName, PersonFields.firstName]}
            getTitle={getItemTitle}
          />
          <FormError error={errors[Fields.person.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>Betrieb</FormLabel>
        <FormData>
          <Lookup
            disabled={!!state.company}
            value={state.company?.name ?? ""}
            onChange={(value) =>
              setState({
                ...state,
                company: value,
              })
            }
            searchItems={searchCompanies}
            searchDialogTitle={Members.Lists.Companies.Title}
            searchDialogFields={[
              CompanyFields.name,
              CompanyFields.companyNumber,
              CompanyFields.member,
              CompanyFields.memberNumber,
            ]}
            getTitle={(item) => item.name}
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.chapter.title}</FormLabel>
        <FormData>
          <Lookup
            value={state.chapter?.membersChapter?.name || null}
            disabled={!!state.chapter?.id}
            onChange={(value) =>
              setState({
                ...state,
                chapter: value,
                area: value?.area || null,
                district: value?.area?.district || null,
              })
            }
            searchItems={searchChapters}
            getTitle={(item) => item.membersChapter?.name ?? ""}
            searchDialogTitle={Functionaries.Lists.Chapters.Title}
            searchDialogFields={[ChapterFields.name, ChapterFields.area, ChapterFields.district]}
          />
          <FormError error={errors[Fields.chapter.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.role.title}</FormLabel>
        <FormData>
          <SelectBox
            value={state.role}
            onChange={(value) =>
              setState({
                ...state,
                role: value,
              })
            }
            options={roles}
            getId={(item) => `${item.id}`}
            getValue={(item) => item.name}
          />
          <FormError error={errors[Fields.role.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.electionDate.title}</FormLabel>
        <FormData>
          <DatePicker
            value={state.electionDate}
            onSelect={(value) =>
              setState({
                ...state,
                electionDate: value,
              })
            }
          />
          <FormError error={errors[Fields.electionDate.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.endDate.title}</FormLabel>
        <FormData>
          <DatePicker
            value={state.endDate}
            onSelect={(value) =>
              setState({
                ...state,
                endDate: value,
              })
            }
          />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;
