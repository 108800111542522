import Link from "../../../components/Link";
import { IListviewField } from "../../../services/types";
import { dispForm as dispFormGroups } from "../groups/urls";
import { dispForm as dispFormSchemas } from "../schemas/urls";
import { SchemaGroupPermissionFull } from "./types";

export const schema: IListviewField<SchemaGroupPermissionFull> = {
  title: "Schema",
  fieldName: "s.name",
  isSortable: true,
  renderListValue: (item: SchemaGroupPermissionFull) => (
    <Link href={`${dispFormSchemas}/${item.schema?.id}`}>{item.schema?.name}</Link>
  ),
};

export const group: IListviewField<SchemaGroupPermissionFull> = {
  title: "Gruppe",
  fieldName: "g.name",
  isSortable: true,
  renderListValue: (item: SchemaGroupPermissionFull) => (
    <Link href={`${dispFormGroups}/${item.group?.id}`}>{item.group?.name}</Link>
  ),
};

export const permission: IListviewField<SchemaGroupPermissionFull> = {
  title: "Berechtigung",
  fieldName: "permission.name",
  isSortable: true,
  renderListValue: (item: SchemaGroupPermissionFull) => item.permission?.name,
};

export const permissionDescription: IListviewField<SchemaGroupPermissionFull> = {
  title: "Berechtigung",
  fieldName: "permission.description",
  isSortable: true,
  renderListValue: (item: SchemaGroupPermissionFull) => item.permission?.description,
};
