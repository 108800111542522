import Link from "../../../components/Link";
import Number from "../../../components/Number";
import { IDialogField, IListviewField } from "../../../services/types";
import { formatBoolean, getCurrentUrlAsSource } from "../../../services/utils";
import { GlobalNavigationItemFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<GlobalNavigationItemFull> & IDialogField<GlobalNavigationItemFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: GlobalNavigationItemFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
  renderDialogValue: (item: GlobalNavigationItemFull) => item.name,
};

export const url: IListviewField<GlobalNavigationItemFull> & IDialogField<GlobalNavigationItemFull> = {
  title: "Url",
  fieldName: "url",
  isSortable: true,
  renderListValue: (item: GlobalNavigationItemFull) => <>{item.url && <Link href={item.url} />}</>,
  renderDialogValue: (item: GlobalNavigationItemFull) => item.url,
};

export const order: IListviewField<GlobalNavigationItemFull> = {
  title: "Reihenfolge",
  fieldName: "order",
  isSortable: true,
  renderListValue: (item: GlobalNavigationItemFull) => <Number value={item.order} />,
};

export const openNewTab: IListviewField<GlobalNavigationItemFull> = {
  title: "Neuem Tab",
  fieldName: "openNewTab",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: GlobalNavigationItemFull) => formatBoolean(item.openNewTab),
};

export const parent: IListviewField<GlobalNavigationItemFull> & IDialogField<GlobalNavigationItemFull> = {
  title: "Übergeordnetes",
  fieldName: "parent.name",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: GlobalNavigationItemFull) => item.parent?.name,
  renderDialogValue: (item: GlobalNavigationItemFull) => item.parent?.name,
};
