import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection";
import { mergeRegister } from "@lexical/utils";
import classNames from "classnames";
import type { LexicalCommand, LexicalNode, NodeKey, SerializedLexicalNode } from "lexical";
import {
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  DecoratorNode,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  createCommand,
} from "lexical";
import { FC, useCallback, useEffect, useRef } from "react";
import styles from "./HorizontalRuleNode.module.css";

type SerializedHorizontalRuleNode = SerializedLexicalNode & {
  type: "horizontalrule";
  version: 1;
};

export const INSERT_HORIZONTAL_RULE_COMMAND: LexicalCommand<void> = createCommand();

interface HorizontalRuleComponentProps {
  nodeKey: NodeKey;
}

const HorizontalRuleComponent: FC<HorizontalRuleComponentProps> = ({ nodeKey }) => {
  const [editor] = useLexicalComposerContext();
  const hrRef = useRef(null);
  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey);

  const onDelete = useCallback(
    (payload: KeyboardEvent) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        const event: KeyboardEvent = payload;
        event.preventDefault();
        const node = $getNodeByKey(nodeKey);
        if ($isHorizontalRuleNode(node)) {
          node.remove();
        }
        setSelected(false);
      }
      return false;
    },
    [isSelected, nodeKey, setSelected]
  );

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        CLICK_COMMAND,
        (event: MouseEvent) => {
          const hrElem = hrRef.current;

          if (event.target === hrElem) {
            if (!event.shiftKey) {
              clearSelection();
            }
            setSelected(!isSelected);
            return true;
          }

          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(KEY_DELETE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, onDelete, COMMAND_PRIORITY_LOW)
    );
  }, [clearSelection, editor, isSelected, onDelete, setSelected]);

  return <hr ref={hrRef} className={classNames(styles.hr, { [styles.selected]: isSelected })} />;
};

export class HorizontalRuleNode extends DecoratorNode<JSX.Element> {
  static override getType(): string {
    return "horizontalrule";
  }

  static override clone(node: HorizontalRuleNode): HorizontalRuleNode {
    return new HorizontalRuleNode(node.__key);
  }

  static override importJSON(_: SerializedHorizontalRuleNode): HorizontalRuleNode {
    return $createHorizontalRuleNode();
  }

  override exportJSON(): SerializedLexicalNode {
    return {
      type: "horizontalrule",
      version: 1,
    };
  }

  override createDOM(): HTMLElement {
    const div = document.createElement("div");
    div.style.display = "contents";
    return div;
  }

  override getTextContent(): "\n" {
    return "\n";
  }

  isTopLevel(): true {
    return true;
  }

  override updateDOM(): false {
    return false;
  }

  override decorate(): JSX.Element {
    return <HorizontalRuleComponent nodeKey={this.__key} />;
  }
}

export function $createHorizontalRuleNode(): HorizontalRuleNode {
  return new HorizontalRuleNode();
}

function $isHorizontalRuleNode(node: LexicalNode | null | undefined): node is HorizontalRuleNode {
  return node instanceof HorizontalRuleNode;
}
