import { FC, useEffect, useState } from "react";
import { CurrentNavigationMenuItemPost } from "../../../home/administration/currentNavigationItems/types";
import {
  deleteCurrentNavigationItems,
  upsertCurrentMenuItems,
} from "../../../home/administration/currentNavigationItems/webapi";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { MenuItem } from "../../../services/types";
import { getCurrentMenuItems } from "../../../services/webapi";
import Button from "../../Button";
import { getString } from "../../errors/Errors";
import LoadingSpinnerSmall from "../../LoadingSpinnerSmall";
import EditableMenu from "../common/EditableMenu";
import NavigationButtons from "../common/NavigationButtons";
import styles from "./CurrentNavigation.module.css";

const CurrentNavigation: FC = () => {
  const [abortController, resetAbortController] = useAbortController();
  const [isSaving, setIsSaving] = useState(false);
  const statusBar = useStatusBarState();
  const itemsCurrentMenu = useGlobalState((state) => state.itemsCurrentMenu);
  const [errorLoadingMenu, setErrorLoadingMenu] = useState(false);
  const setItemsCurrentMenu = useGlobalState((state) => state.setItemsCurrentMenu);
  const isUserAdmin = useGlobalState((state) => state.isUserAdmin);
  const schema = useGlobalState((state) => state.schema);
  const [originalItems, setOriginalItems] = useState<MenuItem[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const loadItems = async () => {
    const abortController = resetAbortController();
    const result = await getCurrentMenuItems(schema.item?.id || null, abortController.signal);
    if (result.error) {
      statusBar.addError(getString(result.error));
      setErrorLoadingMenu(true);
    } else if (result.data) {
      const getSortedItems = (items: MenuItem[]): MenuItem[] => {
        return items.map((x) => ({ ...x, children: getSortedItems(x.children ?? []) }));
      };
      const sortedItems = getSortedItems(result.data);
      setItemsCurrentMenu(sortedItems);
      setOriginalItems(sortedItems);
      setErrorLoadingMenu(false);
    }

    setIsEditMode(false);
  };

  useEffect(() => {
    (async () => {
      if (schema.loaded) {
        await loadItems();
      }
    })();

    return () => abortController && abortController.abort();
  }, [schema]);

  const setEditMode = () => {
    setIsEditMode(true);
  };

  const cancelEditMode = () => {
    setIsEditMode(false);
    setItemsCurrentMenu(originalItems);
  };

  const getParentId = (items: MenuItem[], item: MenuItem): number | null => {
    for (let i = 0; i < items.length; i++) {
      const parent = items[i];
      if (parent?.children)
        for (let j = 0; j < parent?.children.length; j++) {
          if (parent.children[j] === item) {
            return parent.id ?? null;
          }
        }
    }
    return null;
  };

  const saveItems = async () => {
    if (schema.loaded) {
      setIsSaving(true);
      const data = itemsCurrentMenu.reduce(
        (
          rv: {
            itemsToUpsert: CurrentNavigationMenuItemPost[];
            itemsIdsToDelete: number[];
          },
          item
        ) => {
          if (item.deleted && item.id) {
            rv.itemsIdsToDelete.push(item.id);
          } else if (!item.deleted) {
            const itemPost: CurrentNavigationMenuItemPost = {
              id: item.id,
              name: item.title,
              url: item.url,
              openNewTab: item.openNewTab,
              order: item.order,
              schemaId: schema.item?.id || null,
              parentId: getParentId(itemsCurrentMenu, item),
            };
            rv.itemsToUpsert.push(itemPost);
          }
          return rv;
        },
        {
          itemsToUpsert: [],
          itemsIdsToDelete: [],
        }
      );
      const abortController = resetAbortController();
      const [errorUpsert, upsertDelete] = await Promise.all([
        upsertCurrentMenuItems(schema.item?.id || null, data.itemsToUpsert, abortController.signal),
        deleteCurrentNavigationItems(data.itemsIdsToDelete, abortController.signal),
      ]);
      errorUpsert && statusBar.addError(getString(errorUpsert));
      upsertDelete.error && statusBar.addError(getString(upsertDelete.error));
      if (!errorUpsert) {
        setOriginalItems(itemsCurrentMenu.filter((x) => !x.deleted));
        setIsEditMode(false);
      }
      await loadItems();
      setIsSaving(false);
    }
  };

  return (
    <div>
      <EditableMenu menuItems={itemsCurrentMenu} updateMenu={setItemsCurrentMenu} isEditMode={isEditMode} />
      {!errorLoadingMenu && schema.loaded && isUserAdmin && (
        <>
          <hr className={styles.separator} />
          {!isEditMode && (
            <div className={styles["edit-menu"]}>
              <Button onclick={setEditMode}>Menü bearbeiten</Button>
            </div>
          )}
          {isEditMode && (
            <NavigationButtons>
              <Button onclick={cancelEditMode}>Abbrechen</Button>
              <div>&nbsp;</div>
              <Button onclick={saveItems} disabled={isSaving}>
                Speichern
              </Button>
              {isSaving && (
                <>
                  <div>&nbsp;</div>
                  <LoadingSpinnerSmall />
                </>
              )}
            </NavigationButtons>
          )}
        </>
      )}
    </div>
  );
};

export default CurrentNavigation;
