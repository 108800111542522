import Link from "../../../components/Link";
import { IListviewField } from "../../../services/types";
import { getCurrentUrlAsSource } from "../../../services/utils";
import { OfficeFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<OfficeFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: OfficeFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
};
