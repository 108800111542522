import classNames from "classnames";
import { CSSProperties, FC, PropsWithChildren } from "react";
import styles from "./FormData.module.css";
import FormTd from "./FormTd";

interface FormDataProps {
  disp?: boolean;
  fitContent?: boolean;
  colSpan?: number;
  rowSpan?: number;
  props?: CSSProperties;
}

const FormData: FC<PropsWithChildren<FormDataProps>> = ({
  children,
  disp,
  fitContent,
  colSpan,
  rowSpan,
  props,
}: PropsWithChildren<FormDataProps>) => (
  <FormTd colSpan={colSpan} rowSpan={rowSpan} props={props}>
    <div className={classNames({ [styles.width]: !fitContent, [styles.padding]: disp })}>{children}</div>
  </FormTd>
);

export default FormData;
