import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import { DialogOptions, SearchDialog } from "../../../components/dialogs/SearchDialog";
import Errors, { getString } from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import ReferencedItemsView from "../../../components/listview/ReferencedItemsView";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Administration } from "../../../listsSettings/administration";
import { ErrorCode, IAction, SearchResult, UserPermissions } from "../../../services/types";
import {
  formatBoolean,
  getCloseAction,
  getCurrentUrlAsSource,
  getSelectedItems,
  getSourceFromUrl,
} from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import * as GroupFields from "../groups/Fields";
import { GroupFull } from "../groups/types";
import { editForm as editFormGroup } from "../groups/urls";
import { searchGroups } from "../groups/webapi";
import * as UserFields from "../users/Fields";
import { UserFull } from "../users/types";
import { searchUsers } from "../users/webapi";
import * as Fields from "./Fields";
import { ViewFull } from "./types";
import { allItems, editForm } from "./urls";
import {
  addGroup,
  addUser,
  addViewView,
  deleteView,
  deleteViews,
  getView,
  getViewGroups,
  getViewGroupsFilters,
  getViews,
  getViewUsers,
  getViewUsersFilters,
  getViewViews,
  getViewViewsFilters,
  removeGroups,
  removeUsers,
  removeViews,
} from "./webapi";

const DispForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<ViewFull>();
  const statusBar = useStatusBarState();
  const [views, setViews] = useState<SearchResult<ViewFull> | null>(null);
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [userPermissions, setUserPermissions] = useState<UserPermissions>();
  const [groupPermissions, setGroupPermissions] = useState<UserPermissions>();
  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(Number(id));

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Administration.Lists.Views.InternalName,
      Administration.InternalName,
      abortController.signal
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const [userPermissions, groupPermissions, result] = await Promise.all([
          getUserPermissions(
            Administration.Lists.Users.InternalName,
            Administration.InternalName,
            abortController.signal
          ),
          getUserPermissions(
            Administration.Lists.Groups.InternalName,
            Administration.InternalName,
            abortController.signal
          ),
          getView(id, abortController.signal),
        ]);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);
          result.data && setState(result.data);

          if (result.data) {
            setUserPermissions(userPermissions);
            setGroupPermissions(groupPermissions);
            loadViews(result.data.id, pagination, abortController);
            loadUsers(result.data.id, usersPagination, abortController);
            loadGroups(result.data.id, groupsPagination, abortController);
          }
        }
      }
    }
  };

  const userFields = [UserFields.name, UserFields.email];
  const groupFields = [GroupFields.name, GroupFields.description];

  const [showUsersDialog, setShowUsersDialog] = useState(false);
  const usersDialogOptions: DialogOptions<UserFull> = {
    title: Administration.Lists.Users.Title,
    fields: [UserFields.name, UserFields.email, UserFields.department],
    searchItems: (value: string, abortController: AbortController) => searchUsers(value, abortController.signal),
    onItemSelected: async (user: UserFull) => {
      if (state) {
        const abortController = resetAbortController();
        await addUser(state, user, abortController.signal);
        await loadUsers(state.id, usersPagination, abortController);
      }
    },
    cancelClicked: () => setShowUsersDialog(false),
    isOpen: showUsersDialog,
  };

  const [showGroupsDialog, setShowGroupsDialog] = useState(false);
  const groupDialogOptions: DialogOptions<GroupFull> = {
    title: Administration.Lists.Groups.Title,
    fields: [GroupFields.name, GroupFields.description],
    searchItems: (value: string, abortController: AbortController) => searchGroups(value, abortController.signal),
    onItemSelected: async (group: GroupFull) => {
      if (state) {
        const abortController = resetAbortController();
        await addGroup(state, group, abortController.signal);
        await loadGroups(state.id, groupsPagination, abortController);
      }
    },
    cancelClicked: () => setShowGroupsDialog(false),
    isOpen: showGroupsDialog,
  };

  const viewFields = [Fields.name, Fields.internalName];

  const viewDialogFields = [Fields.name, Fields.internalName, Fields.list];

  const [showViewsDialog, setShowViewsDialog] = useState(false);
  const viewsDialogOptions: DialogOptions<ViewFull> = {
    title: Administration.Lists.Views.Title,
    fields: viewDialogFields,
    searchItems: async (searchValue: string, abortController: AbortController) => {
      const pagination: Pagination = {
        ...DefaultPagination,
      };
      pagination.searchKey = searchValue;
      pagination.filters = [
        {
          fieldname: "list.id",
          values: [{ value: state?.list?.id ?? "" }],
        },
      ];
      return await getViews(pagination, abortController.signal);
    },
    onItemSelected: async (view: ViewFull) => {
      if (state) {
        const abortController = resetAbortController();
        await addViewView(state, view, abortController.signal);
        await loadViews(state.id, pagination, abortController);
      }
      setShowViewsDialog(false);
    },
    cancelClicked: () => setShowViewsDialog(false),
    isOpen: showViewsDialog,
  };

  const onDelete = async () => {
    if (state && confirm(strings.confirmDeleteItem)) {
      const abortController = resetAbortController();
      const error = await deleteView(state.id, abortController.signal);
      if (!abortController.signal.aborted) {
        error && statusBar.addError(getString(error));
        !error && navigateTo(getSourceFromUrl() ?? allItems);
      }
    }
  };

  const listviewId = "view-views";

  const pagination = getPagination(listviewId) || {
    ...DefaultPagination,
  };

  const loadViews = async (viewId: number, pagination: Pagination, abortController: AbortController) => {
    const result = await getViewViews(viewId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setViews(result.data);
    }
  };
  const editAction: IAction = {
    title: strings.edit,
    icon: Edit,

    onClick: () => state && navigateTo(`${editForm}/${state.id}?${getCurrentUrlAsSource()}`),
    disabled: !permissions || !permissions.listPermissions.includes("edit-all"),
  };
  const closeAction: IAction = getCloseAction(navigateTo, allItems);
  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: onDelete,
    disabled: !permissions || !permissions.listPermissions.includes("delete-all"),
  };

  const newViewAction: IAction = {
    title: strings.new,
    icon: Add,

    onClick: () => state && navigateTo(`${editForm}/${state.id}?${getCurrentUrlAsSource()}`),
    disabled:
      !permissions ||
      !permissions.listPermissions.includes("read-all") ||
      !permissions ||
      !permissions.listPermissions.includes("edit-all"),
  };

  const addViewAction: IAction = {
    title: strings.add,
    icon: Add,
    onClick: () => setShowViewsDialog(true),
    disabled: !permissions || !permissions.listPermissions.includes("edit-all"),
  };

  const editViewAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => {
      const selectedItem = getSelectedItems(views)[0];
      selectedItem && navigateTo(`${editForm}/${selectedItem.id}?${getCurrentUrlAsSource()}`);
    },
    disabled: getSelectedItems(views).length !== 1 || !permissions?.listPermissions.includes("edit-all"),
  };

  const deleteViewAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(views);
        if (selectedItems.length > 0 && confirm(strings.confirmDeleteItems)) {
          const abortController = resetAbortController();
          const result = await deleteViews(
            selectedItems.map((x) => x.id),
            abortController.signal
          );
          if (!abortController.signal.aborted) {
            if (result.error) {
              statusBar.addError(getString(result.error));
            } else if (result.data) {
              const s = selectedItems.filter((x) => x.id && result.data?.[x.id]);

              if (s.length != selectedItems.length) {
                statusBar.addWarning(strings.notAllElementsDeleted);
              }
              if (s.length > 0) {
                await loadViews(state.id, pagination, abortController);
              }
            }
          }
        }
      }
    },
    disabled: getSelectedItems(views).length === 0 || !permissions?.listPermissions.includes("delete-all"),
  };

  const removeViewAction: IAction = {
    title: strings.remove,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(views);
        if (selectedItems.length > 0 && confirm(strings.confirmRemoveItems)) {
          const abortController = resetAbortController();
          await removeViews(state, selectedItems, abortController.signal);
          await loadViews(state.id, pagination, abortController);
        }
      }
    },
    disabled: getSelectedItems(views).length === 0 || !permissions?.listPermissions.includes("edit-all"),
  };
  const viewActions = [newViewAction, editViewAction, removeViewAction];

  const listIdViewUsers = "view-users";
  const [users, setUsers] = useState<SearchResult<UserFull> | null>(null);

  const usersPagination = getPagination(listIdViewUsers) || {
    ...DefaultPagination,
  };

  const loadUsers = async (viewId: number, pagination: Pagination, abortController: AbortController) => {
    const result = await getViewUsers(viewId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setUsers(result.data);
    }
  };

  const listIdViewGroups = "view-groups";
  const [groups, setGroups] = useState<SearchResult<GroupFull> | null>(null);

  const groupsPagination = getPagination(listIdViewGroups) || {
    ...DefaultPagination,
  };

  const loadGroups = async (viewId: number, pagination: Pagination, abortController: AbortController) => {
    const result = await getViewGroups(viewId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setGroups(result.data);
    }
  };

  const addGroupAciton: IAction = {
    title: strings.add,
    icon: Add,
    onClick: () => setShowGroupsDialog(true),
    disabled:
      !groupPermissions?.listPermissions.includes("read-all") || !permissions?.listPermissions.includes("edit-all"),
  };

  const editGroupAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => state && navigateTo(`${editFormGroup}/${state.id}?${getCurrentUrlAsSource()}`),
    disabled: getSelectedItems(groups).length !== 1 || !groupPermissions?.listPermissions.includes("edit-all"),
  };

  const removeGroupAction: IAction = {
    title: strings.remove,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(groups);
        if (selectedItems.length > 0 && confirm(strings.confirmRemoveItems)) {
          const abortController = resetAbortController();
          await removeGroups(state, selectedItems, abortController.signal);
          await loadGroups(state.id, groupsPagination, abortController);
        }
      }
    },
    disabled: !(getSelectedItems(groups).length != 0) || !permissions?.listPermissions.includes("edit-all"),
  };
  const groupActions = [addGroupAciton, editGroupAction, removeGroupAction];

  const addUserAction: IAction = {
    title: strings.add,
    icon: Add,
    onClick: () => setShowUsersDialog(true),
    disabled:
      !userPermissions?.listPermissions.includes("read-all") || !permissions?.listPermissions.includes("edit-all"),
  };

  const removeUserAction: IAction = {
    title: strings.remove,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(users);
        if (selectedItems.length > 0 && confirm(strings.confirmRemoveItems)) {
          const abortController = resetAbortController();
          await removeUsers(state, selectedItems, abortController.signal);
          await loadUsers(state.id, usersPagination, abortController);
        }
      }
    },
    disabled: getSelectedItems(users).length === 0 || !permissions?.listPermissions.includes("edit-all"),
  };
  const userActions = [addUserAction, removeUserAction];

  useEffect(() => {
    if (state) {
      setListName(Administration.Lists.Views.Title);
      setPage(state.name);
    }
  }, [state]);

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Ansicht"} />
          <Actions actions={[editAction, closeAction, deleteAction]} />
          <FormTable>
            <FormTr>
              <FormLabel>{Fields.name.title}</FormLabel>
              <FormData disp>{state?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.internalName.title}</FormLabel>
              <FormData disp>{state?.internalName}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.showSearchBox.title}</FormLabel>
              <FormData disp>{formatBoolean(state?.showSearchBox)}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.itemsPerPage.title}</FormLabel>
              <FormData disp>{state?.itemsPerPage}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.list.title}</FormLabel>
              <FormData disp>{state?.list?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.schema.title}</FormLabel>
              <FormData disp>{state?.list?.schema?.name}</FormData>
            </FormTr>
          </FormTable>
          <CreatedModifiedInfo info={state} />

          {showViewsDialog && (
            <SearchDialog
              title="Suche Ansicht"
              fields={viewsDialogOptions.fields}
              searchItems={viewsDialogOptions.searchItems}
              onItemSelected={viewsDialogOptions.onItemSelected}
              cancelClicked={viewsDialogOptions.cancelClicked}
              isOpen={viewsDialogOptions.isOpen}
            />
          )}
          <ReferencedItemsView
            itemId={state.id}
            id={listviewId}
            actions={viewActions}
            otherActions={[addViewAction, deleteViewAction]}
            title={Administration.Lists.Views.Title}
            fields={viewFields}
            allowSelectRows
            reload={(viewId, pagination) => loadViews(viewId, pagination, resetAbortController())}
            searchResult={views}
            getFilters={getViewViewsFilters}
            pagination={pagination}
            updateItems={(items) =>
              views &&
              setViews({
                ...views,
                items: items,
              })
            }
          />

          {usersDialogOptions.isOpen && (
            <SearchDialog
              isOpen={usersDialogOptions.isOpen}
              searchItems={usersDialogOptions.searchItems}
              onItemSelected={usersDialogOptions.onItemSelected}
              cancelClicked={() => setShowUsersDialog(false)}
              title={usersDialogOptions.title}
              fields={usersDialogOptions.fields}
            />
          )}
          <ReferencedItemsView
            itemId={state.id}
            id={listIdViewUsers}
            actions={userActions}
            title={Administration.Lists.Users.Title}
            fields={userFields}
            allowSelectRows
            reload={(userId, pagination) => loadUsers(userId, pagination, resetAbortController())}
            searchResult={users}
            getFilters={getViewUsersFilters}
            pagination={usersPagination}
            updateItems={(items) =>
              users &&
              setUsers({
                ...users,
                items: items,
              })
            }
          />
          {groupDialogOptions.isOpen && (
            <SearchDialog
              isOpen={groupDialogOptions.isOpen}
              searchItems={groupDialogOptions.searchItems}
              onItemSelected={groupDialogOptions.onItemSelected}
              cancelClicked={() => setShowGroupsDialog(false)}
              title={groupDialogOptions.title}
              fields={groupDialogOptions.fields}
            />
          )}

          <ReferencedItemsView
            itemId={state.id}
            id={listIdViewGroups}
            actions={groupActions}
            title={Administration.Lists.Groups.Title}
            fields={groupFields}
            allowSelectRows
            reload={(groupId, pagination) => loadGroups(groupId, pagination, resetAbortController())}
            searchResult={groups}
            getFilters={getViewGroupsFilters}
            pagination={groupsPagination}
            updateItems={(items) =>
              groups &&
              setGroups({
                ...groups,
                items: items,
              })
            }
          />
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;
