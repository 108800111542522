import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Errors, { getString } from "../../../components/errors/Errors";
import Listview from "../../../components/listview/Listview";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import useAbortController from "../../../hooks/useAbortController";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Functionaries } from "../../../listsSettings/functionaries";
import { ErrorCode, IAction, IListviewField, SearchResult, UserPermissions } from "../../../services/types";
import { downloadFileFromData, getCurrentUrlAsSource, getSelectedItems } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import Excel from "../../../svg/Excel.svg?react";
import { newForm as newFormChapterElections } from "../chapterElections/urls";
import * as Fields from "./Fields";
import { ChapterFull } from "./types";
import { editForm, newForm } from "./urls";
import { deleteChapters, exportChaptersExcel, getChapterFilters, getChapters } from "./webapi";

const AllItems: FC = () => {
  const [abortController, resetAbortController] = useAbortController();
  const listviewId = "chapters-all-items";
  const fields: IListviewField<ChapterFull>[] = [
    Fields.name,
    Fields.area,
    Fields.district,
    Fields.membersChapterCode,
    Fields.taxCode,
    Fields.vatNumber,
    Fields.iban,
  ];

  const statusBar = useStatusBarState();
  const [chapters, setChapters] = useState<SearchResult<ChapterFull> | null>(null);
  const [errorCode, setErrorCode] = useState<ErrorCode>();

  const [chapterPermissions, setChapterPermissions] = useState<UserPermissions>();
  const [functionaryPermissions, setFunctionaryPermissions] = useState<UserPermissions>();
  const navigateTo = useNavigate();

  const pagination = getPagination(listviewId) || {
    ...DefaultPagination,
    orderBy: Fields.name.fieldName,
  };

  useEffect(() => {
    loadChapters(pagination);

    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const loadChapters = async (pagination: Pagination) => {
    const abortController = resetAbortController();
    const [chapterPermissions, functionaryPermissions] = await Promise.all([
      getUserPermissions(Functionaries.Lists.Chapters.InternalName, Functionaries.InternalName, abortController.signal),
      getUserPermissions(
        Functionaries.Lists.Functionaries.InternalName,
        Functionaries.InternalName,
        abortController.signal
      ),
    ]);

    if (!abortController.signal.aborted) {
      setChapterPermissions(chapterPermissions);
      setFunctionaryPermissions(functionaryPermissions);
      const canRead = chapterPermissions.schemaPermission && chapterPermissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getChapters(pagination, abortController.signal);
        if (!abortController.signal.aborted) {
          result.data && setChapters(result.data);
          result.error && setErrorCode(result.error.code);
        }
      }
    }
  };

  const newAction: IAction = {
    title: strings.new,
    icon: Add,

    onClick: () => navigateTo(`${newForm}?${getCurrentUrlAsSource()}`),
    disabled: !chapterPermissions || !chapterPermissions.listPermissions.includes("create"),
  };

  const newElectionAction: IAction = {
    title: "Neuwahlen",
    icon: Add,
    onClick: () => navigateTo(`${newFormChapterElections}?${getCurrentUrlAsSource()}`),
    disabled:
      !chapterPermissions ||
      !chapterPermissions.listPermissions.includes("edit-all") ||
      !functionaryPermissions ||
      !functionaryPermissions.listPermissions.includes("create"),
  };

  const editAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => {
      const selectedItem = getSelectedItems(chapters)[0];
      selectedItem && navigateTo(`${editForm}/${selectedItem.id}?${getCurrentUrlAsSource()}`);
    },
    disabled: getSelectedItems(chapters).length !== 1 || !chapterPermissions?.listPermissions.includes("edit-all"),
  };
  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: async () => {
      const selectedItems = getSelectedItems(chapters);
      if (selectedItems.length > 0 && confirm(strings.confirmDeleteItems)) {
        const abortController = resetAbortController();
        const result = await deleteChapters(
          selectedItems.map((x) => x.id),
          abortController.signal
        );

        if (result.error) {
          statusBar.addError(getString(result.error));
        } else if (result.data) {
          const s = selectedItems.filter((x) => x.id && result.data?.[x.id]);

          if (s.length != selectedItems.length) {
            statusBar.addWarning(strings.notAllElementsDeleted);
          }
          if (s.length > 0) {
            await loadChapters(pagination);
          }
        }
      }
    },
    disabled: getSelectedItems(chapters).length === 0 || !chapterPermissions?.listPermissions.includes("delete-all"),
  };
  const exportExcel: IAction = {
    title: strings.excelExport,
    icon: Excel,
    onClick: async () => {
      const abortController = resetAbortController();
      const result = await exportChaptersExcel(pagination, abortController.signal);
      result.error && statusBar.addError(getString(result.error));
      !result.error &&
        result.data &&
        downloadFileFromData(result.data.data, "Ortsgruppen.xlsx", result.data.contentType);
    },
    disabled: chapters == null || chapters.total === 0,
  };

  const actions = [newAction, editAction, deleteAction];

  return (
    <>
      {chapterPermissions &&
        (!errorCode ? (
          <Listview
            id={listviewId}
            actions={actions}
            otherActions={[newElectionAction, exportExcel]}
            viewProperties={{
              schema: Functionaries.InternalName,
              list: Functionaries.Lists.Chapters.InternalName,
              name: "all_items",
              setListAndPage: true,
            }}
            fields={fields}
            allowSelectRows
            reload={loadChapters}
            searchResult={chapters}
            getFilters={getChapterFilters}
            pagination={pagination}
            updateItems={(items) =>
              chapters &&
              setChapters({
                ...chapters,
                items: items,
              })
            }
          />
        ) : (
          errorCode && <Errors errorCode={errorCode} />
        ))}
    </>
  );
};

export default AllItems;
