import { format, parseISO } from "date-fns";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import FullCalendar from "../../../components/calendar/Calendar";
import { CalendarItem, CalendarListData } from "../../../components/calendar/types";
import { Pagination } from "../../../components/listview/Pagination";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import { Functionaries } from "../../../listsSettings/functionaries";
import { UserPermissions } from "../../../services/types";
import { dateToString, getCurrentUrlAsSource } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import * as Fields from "./Fields";
import { dispForm, newForm } from "./urls";
import { getChapterEvents } from "./webapi";

const Calendar: FC = () => {
  const [abortController, resetAbortController] = useAbortController();
  const [_, setPermissions] = useState<UserPermissions>();
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const navigateTo = useNavigate();

  useEffect(() => {
    setListName(Functionaries.Lists.ChapterEvents.Title);
    setPage("Kalender");

    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const addItem = (date: Date) => {
    navigateTo(`${newForm}?date=${dateToString(date)}&${getCurrentUrlAsSource()}`);
  };

  const data: CalendarListData[] = [
    {
      dispUrl: dispForm,
      getItems: async (from: Date, to: Date): Promise<CalendarItem[]> => {
        const abortController = resetAbortController();
        const permissions = await getUserPermissions(
          Functionaries.Lists.ChapterEvents.InternalName,
          Functionaries.InternalName,
          abortController.signal
        );
        if (!abortController.signal.aborted) {
          const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
          if (canRead) {
            const pagination: Pagination = {
              page: 1,
              itemsPerPage: 0,
              orderBy: Fields.from.fieldName,
              orderByDescending: false,
              filters: [
                {
                  fieldname: Fields.from.fieldName,
                  values: [
                    {
                      value: format(from, "yyyy-MM-dd"),
                      operator: "GEQ",
                    },
                  ],
                },
                {
                  fieldname: Fields.from.fieldName,
                  values: [
                    {
                      value: format(to, "yyyy-MM-dd"),
                      operator: "LEQ",
                    },
                  ],
                },
              ],
            };
            const result = await getChapterEvents(pagination, abortController.signal);
            if (!abortController.signal.aborted) {
              if (result.data) {
                return result.data.items.map((x) => {
                  var s = {
                    id: x.id,
                    title: x.title,
                    description: x.description,
                    from: parseISO(x.from),
                    to: parseISO(x.to),
                  };
                  return s;
                });
              }
            }
            if (result.error) {
            }
            if (!abortController.signal.aborted) {
              setPermissions(permissions);
            }
          }
        }
        return [];
      },
    },
  ];
  return <FullCalendar showWeeks data={data} onAddItem={addItem} addHref={newForm} />;
};

export default Calendar;
