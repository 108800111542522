import Euro from "../../../components/Euro";
import Number from "../../../components/Number";
import { IListviewField } from "../../../services/types";
import { formatBoolean } from "../../../services/utils";
import { MemberBonus } from "./types";

export const member: IListviewField<MemberBonus> = {
  title: "Mitglied",
  fieldName: "member.name",
  isSortable: true,
  renderListValue: (item: MemberBonus) => item.member.name,
};

export const bonus: IListviewField<MemberBonus> = {
  title: "Bonus",
  fieldName: "bonus",
  isSortable: true,
  renderListValue: (item: MemberBonus) => <Euro value={item.bonus} />,
};

export const invoiced: IListviewField<MemberBonus> = {
  title: "Fakturiert",
  fieldName: "invoiced",
  isSortable: true,
  renderListValue: (item: MemberBonus) => <Euro value={item.invoiced} />,
};

export const remainingBalance: IListviewField<MemberBonus> = {
  title: "Restguthaben",
  fieldName: "restBalance",
  isSortable: true,
  renderListValue: (item: MemberBonus) => <Euro value={item.restBalance} />,
};

export const valid: IListviewField<MemberBonus> = {
  title: "Ist gültig",
  fieldName: "valid",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: MemberBonus) => formatBoolean(item.valid),
};

export const year: IListviewField<MemberBonus> = {
  title: "Jahr",
  fieldName: "year",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: MemberBonus) => <Number value={item.year} />,
};
