import { CSSProperties, FC, PropsWithChildren } from "react";
import styles from "./FormTd.module.css";

interface FormTdProps {
  colSpan?: number;
  rowSpan?: number;
  props?: CSSProperties;
}

const FormTd: FC<PropsWithChildren<FormTdProps>> = ({
  children,
  colSpan,
  rowSpan,
  props,
}: PropsWithChildren<FormTdProps>) => {
  return (
    <td className={styles.td} colSpan={colSpan} rowSpan={rowSpan} style={props}>
      {children}
    </td>
  );
};

export default FormTd;
