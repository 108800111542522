import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Api } from "../../../listsSettings/api";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  api,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  updateItem,
} from "../../../services/webapi";
import { UserMemberPermissionFull, UserMemberPermissionPost as UserMemberPermissionPost } from "./types";

const userMemberPermissionUrl: string = `${api}/${Api.Lists.UserMemberPermissions.InternalName}`;
export const insertUserMemberPermission = async (
  userMemberPermission: UserMemberPermissionPost,
  signal: AbortSignal
): Promise<Result<UserMemberPermissionFull | null>> =>
  insertItem(userMemberPermissionUrl, userMemberPermission, signal);

export const updateUserMemberPermission = async (
  userMemberPermissionId: number,
  userMemberPermission: UserMemberPermissionPost,
  signal: AbortSignal
): Promise<Result<UserMemberPermissionFull | null>> =>
  updateItem(`${userMemberPermissionUrl}/${userMemberPermissionId}`, userMemberPermission, signal);

export const deleteUserMemberPermission = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${userMemberPermissionUrl}/${id}`, signal);

export const deleteUserMemberPermissions = (
  ids: number[],
  signal: AbortSignal
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(userMemberPermissionUrl, ids, signal);

export const getUserMemberPermission = async (
  id: number,
  signal: AbortSignal
): Promise<Result<UserMemberPermissionFull | null>> =>
  getItem<UserMemberPermissionFull>(`${userMemberPermissionUrl}/${id}`, signal);

export const getUserMemberPermissions = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<UserMemberPermissionFull> | null>> =>
  getItems<UserMemberPermissionFull>(userMemberPermissionUrl, pagination, signal);

export const getUserMemberPermissionFilters = (
  fieldName: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(userMemberPermissionUrl, fieldName, signal);
