import { FC } from "react";
import DatePicker from "../../../components/forms/DatePickerWithInput";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import Lookup from "../../../components/forms/Lookup";
import { Functionaries } from "../../../listsSettings/functionaries";
import { Members } from "../../../listsSettings/members";
import { ValidationErrors } from "../../../services/types";
import * as ChaptersFields from "../../members/chapters/Fields";
import { searchChapters } from "../../members/chapters/webapi";
import * as AreaFields from "../areas/Fields";
import { searchAreas } from "../areas/webapi";
import * as Fields from "./Fields";
import { ChapterForm } from "./types";

interface NewEditTableProps {
  state: ChapterForm;
  setState: React.Dispatch<React.SetStateAction<ChapterForm | undefined>>;
  errors: ValidationErrors;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.area.title}</FormLabel>
        <FormData>
          <Lookup
            disabled={!!state.area?.id}
            value={state.area?.name ?? ""}
            onChange={(value) =>
              setState({
                ...state,
                area: value,
              })
            }
            searchItems={searchAreas}
            searchDialogTitle={Functionaries.Lists.Areas.Title}
            searchDialogFields={[AreaFields.name]}
            getTitle={(item) => item.name ?? ""}
          />
          <FormError error={errors[Fields.area.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.district.title}</FormLabel>
        <FormData disp>{state.area?.district?.name}</FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.membersChapter.title}</FormLabel>
        <FormData>
          <Lookup
            disabled={!!state.membersChapter}
            value={state.membersChapter?.name ?? ""}
            onChange={(value) =>
              setState({
                ...state,
                membersChapter: value,
              })
            }
            searchItems={searchChapters}
            searchDialogTitle={Members.Lists.Chapters.Title}
            searchDialogFields={[ChaptersFields.code, ChaptersFields.name]}
            getTitle={(item) => item.name ?? ""}
          />
          <FormError error={errors[Fields.membersChapter.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.from.title}</FormLabel>
        <FormData>
          <DatePicker
            value={state.from}
            onSelect={(value) =>
              setState({
                ...state,
                from: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.to.title}</FormLabel>
        <FormData>
          <DatePicker
            value={state.to}
            onSelect={(value) =>
              setState({
                ...state,
                to: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.taxCode.title}</FormLabel>
        <FormData>
          <Input
            value={state.taxCode}
            onChange={(value) =>
              setState({
                ...state,
                taxCode: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.vatNumber.title}</FormLabel>
        <FormData>
          <Input
            value={state.vatNumber}
            onChange={(value) =>
              setState({
                ...state,
                vatNumber: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.iban.title}</FormLabel>
        <FormData>
          <Input
            value={state.iban}
            onChange={(value) =>
              setState({
                ...state,
                iban: value.toUpperCase(),
              })
            }
          />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;
