import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Conventions } from "../../../listsSettings/conventions";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  conventions,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  updateItem,
} from "../../../services/webapi";
import { ConventionFull } from "../conventions/types";
import { CategoryFull, CategoryPost } from "./types";

const categoriesUrl: string = `${conventions}/${Conventions.Lists.Categories.InternalName}`;
export const insertCategory = async (
  category: CategoryPost,
  signal: AbortSignal
): Promise<Result<CategoryFull | null>> => insertItem(categoriesUrl, category, signal);

export const updateCategory = async (
  categoryId: number,
  category: CategoryPost,
  signal: AbortSignal
): Promise<Result<CategoryFull | null>> => updateItem(`${categoriesUrl}/${categoryId}`, category, signal);

export const deleteCategory = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${categoriesUrl}/${id}`, signal);

export const deleteCategories = (
  ids: number[],
  signal: AbortSignal
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(categoriesUrl, ids, signal);

export const getCategory = async (id: number, signal: AbortSignal): Promise<Result<CategoryFull | null>> =>
  getItem<CategoryFull>(`${categoriesUrl}/${id}`, signal);

export const getCategories = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<CategoryFull> | null>> => getItems<CategoryFull>(categoriesUrl, pagination, signal);

export const getCategoryFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(categoriesUrl, fieldName, signal);

export const getCategoryConventions = async (
  categoryId: number,
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<ConventionFull> | null>> =>
  getItems<ConventionFull>(`${categoriesUrl}/${categoryId}/conventions`, pagination, signal);

export const getCategoryConventionsFilters = (
  categoryId: number,
  fieldname: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(`${categoriesUrl}/${categoryId}/conventions`, fieldname, signal);
