import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Administration } from "../../../listsSettings/administration";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  administration,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  searchItems,
  updateItem,
} from "../../../services/webapi";
import { ListGroupPermissionFull } from "../listGroupPermissions/types";
import { ListUserPermissionFull } from "../listUserPermissions/types";
import { ViewFull } from "../views/types";
import { ListFull, ListPost } from "./types";

const listsUrl: string = `${administration}/${Administration.Lists.Lists.InternalName}`;
export const insertList = async (list: ListPost, signal: AbortSignal): Promise<Result<ListFull | null>> =>
  insertItem(listsUrl, list, signal);

export const updateList = async (
  listId: number,
  list: ListPost,
  signal: AbortSignal
): Promise<Result<ListFull | null>> => updateItem(`${listsUrl}/${listId}`, list, signal);

export const deleteList = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${listsUrl}/${id}`, signal);

export const deleteLists = (ids: number[], signal: AbortSignal): Promise<Result<{ [key: number]: boolean } | null>> =>
  deleteItems(listsUrl, ids, signal);

export const getList = async (id: number, signal: AbortSignal): Promise<Result<ListFull | null>> =>
  getItem<ListFull>(`${listsUrl}/${id}`, signal);

export const getLists = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<ListFull> | null>> => getItems<ListFull>(listsUrl, pagination, signal);

export const getListFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(listsUrl, fieldName, signal);

export const searchLists = async (
  searchValue: string,
  signal: AbortSignal
): Promise<Result<SearchResult<ListFull> | null>> => searchItems<ListFull>(listsUrl, searchValue, signal);

export const getListviews = async (
  listId: number,
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<ViewFull> | null>> =>
  getItems<ViewFull>(`${listsUrl}/${listId}/views`, pagination, signal);

export const getListviewsFilters = (
  listId: number,
  fieldname: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${listsUrl}/${listId}/views`, fieldname, signal);

export const getListGroupsPermissions = async (
  listId: number,
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<ListGroupPermissionFull> | null>> =>
  getItems<ListGroupPermissionFull>(`${listsUrl}/${listId}/group_permissions`, pagination, signal);

export const getListGroupsPermissionsFilters = (
  listId: number,
  fieldname: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(`${listsUrl}/${listId}/group_permissions`, fieldname, signal);

export const getListUsersPermissions = async (
  listId: number,
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<ListUserPermissionFull> | null>> =>
  getItems<ListUserPermissionFull>(`${listsUrl}/${listId}/user_permissions`, pagination, signal);

export const getListUsersPermissionsFilters = (
  listId: number,
  fieldname: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${listsUrl}/${listId}/user_permissions`, fieldname, signal);
