import { Pagination } from "../../../components/listview/Pagination";
import { Administration } from "../../../listsSettings/administration";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import { administration, getFilters, getItem, getItems } from "../../../services/webapi";
import { UserFull } from "../users/types";
import { OfficeFull } from "./types";

const officesUrl: string = `${administration}/${Administration.Lists.Offices.InternalName}`;

export const getOffice = async (id: string, signal: AbortSignal): Promise<Result<OfficeFull | null>> => {
  const url = `${officesUrl}/${id}`;
  return getItem<OfficeFull>(url, signal);
};

export const getOffices = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<OfficeFull> | null>> => getItems<OfficeFull>(officesUrl, pagination, signal);

export const getOfficeFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(officesUrl, fieldName, signal);

export const getOfficeUsers = async (
  officeId: string,
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<UserFull> | null>> =>
  getItems<UserFull>(`${officesUrl}/${officeId}/users`, pagination, signal);

export const getOfficeUsersFilters = (
  officeId: string,
  fieldname: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${officesUrl}/${officeId}/users`, fieldname, signal);
