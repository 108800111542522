import { FC } from "react";
import { camalize } from "../../services/utils";
import styles from "./MultiCheckBox.module.css";

interface MultiCheckBoxProps {
  values: {
    value: string;
    label: string;
    selected?: boolean;
  }[];
  onChange?: (values: string[]) => void;
  isDisabled?: (value: string) => boolean;
  key?: string;
  disp?: boolean;
}

const MultiCheckBox: FC<MultiCheckBoxProps> = (props: MultiCheckBoxProps) => {
  const onChange = (checked: boolean, value: string) => {
    const values = props.values
      .filter((x) => (x.value !== value && x.selected) || (x.value === value && checked))
      .map((x) => x.value);
    props.onChange && props.onChange(values);
  };

  return (
    <div className={styles.container}>
      {props.values.map((x) => {
        const id = camalize(x.value);
        return (
          <div className={styles.checkbox} key={`${props.key}_multi_checkbox_${x.value}`}>
            <input
              type="checkbox"
              checked={x.selected}
              onChange={(event) => onChange(event.target.checked, x.value)}
              id={id}
              disabled={(props.isDisabled && props.isDisabled(x.value)) || props.disp}
            />
            <label className={styles.label} htmlFor={id}>
              {x.label}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default MultiCheckBox;
