import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import LoadingDialog from "../../../components/dialogs/LoadingDialog";
import Errors, { getString } from "../../../components/errors/Errors";
import Listview from "../../../components/listview/Listview";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import useAbortController from "../../../hooks/useAbortController";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Functionaries } from "../../../listsSettings/functionaries";
import { ErrorCode, IAction, IListviewField, SearchResult, UserPermissions } from "../../../services/types";
import { downloadFileFromData, getCurrentUrlAsSource, getSelectedItems } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import Excel from "../../../svg/Excel.svg?react";
import * as Fields from "./Fields";
import { FunctionaryFull } from "./types";
import { editForm, insertBatch, newForm } from "./urls";
import { deleteFunctionaries, exportFunctionariesExcel, getFunctionaries, getFunctionaryFilters } from "./webapi";

const AllItems: FC = () => {
  const [abortController, resetAbortController] = useAbortController();
  const listviewId = "functionaries-all-items";
  const fields: IListviewField<FunctionaryFull>[] = [
    Fields.lastName,
    Fields.firstName,
    Fields.company,
    Fields.chapter,
    Fields.area,
    Fields.district,
    Fields.role,
    Fields.legislature,
    Fields.electionDate,
    Fields.endDate,
  ];

  const statusBar = useStatusBarState();
  const [functionaries, setFunctionaries] = useState<SearchResult<FunctionaryFull> | null>(null);
  const [errorCode, setErrorCode] = useState<ErrorCode>();

  const [permissions, setPermissions] = useState<UserPermissions>();
  const navigateTo = useNavigate();

  const pagination = getPagination(listviewId) || {
    ...DefaultPagination,
  };

  useEffect(() => {
    loadFunctionaries(pagination);

    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const loadFunctionaries = async (pagination: Pagination) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Functionaries.Lists.Functionaries.InternalName,
      Functionaries.InternalName,
      abortController.signal
    );

    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getFunctionaries(pagination, abortController.signal);
        if (!abortController.signal.aborted) {
          result.data && setFunctionaries(result.data);
          result.error && setErrorCode(result.error.code);
        }
      }
    }
  };

  const newAction: IAction = {
    title: strings.new,
    icon: Add,

    onClick: () => navigateTo(`${newForm}?${getCurrentUrlAsSource()}`),
    disabled: !permissions || !permissions.listPermissions.includes("create"),
  };

  const newElectionAction: IAction = {
    title: "Neuwahlen",
    icon: Add,
    onClick: () => navigateTo(insertBatch),
    disabled: newAction.disabled,
  };
  const editAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => {
      const selectedItem = getSelectedItems(functionaries)[0];
      selectedItem && navigateTo(`${editForm}/${selectedItem.id}?${getCurrentUrlAsSource()}`);
    },
    disabled: getSelectedItems(functionaries).length !== 1 || !permissions?.listPermissions.includes("edit-all"),
  };
  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: async () => {
      const selectedItems = getSelectedItems(functionaries);
      if (selectedItems.length > 0 && confirm(strings.confirmDeleteItems)) {
        const abortController = resetAbortController();
        const result = await deleteFunctionaries(
          selectedItems.map((x) => x.id),
          abortController.signal
        );

        if (result.error) {
          statusBar.addError(getString(result.error));
        } else if (result.data) {
          const s = selectedItems.filter((x) => x.id && result.data?.[x.id]);

          if (s.length != selectedItems.length) {
            statusBar.addWarning(strings.notAllElementsDeleted);
          }
          if (s.length > 0) {
            await loadFunctionaries(pagination);
          }
        }
      }
    },
    disabled:
      getSelectedItems(functionaries).length === 0 ||
      !permissions ||
      !permissions.listPermissions.includes("delete-all"),
  };

  const [showLoadingDialog, setLoadingDialog] = useState(false);
  const exportExcel: IAction = {
    title: strings.excelExport,
    icon: Excel,
    onClick: async () => {
      setLoadingDialog(true);
      const abortController = resetAbortController();
      const result = await exportFunctionariesExcel(pagination, abortController.signal);

      if (!abortController.signal.aborted) {
        result.error && statusBar.addError(getString(result.error));
        !result.error &&
          result.data &&
          downloadFileFromData(result.data.data, "Funktionäre.xlsx", result.data.contentType);
        setLoadingDialog(false);
      }
    },
    disabled: functionaries == null || functionaries.total === 0,
  };

  const actions = [newAction, editAction, deleteAction];

  return (
    <>
      <LoadingDialog isOpen={showLoadingDialog} onClose={() => setLoadingDialog(false)} />
      {permissions &&
        (!errorCode ? (
          <Listview
            id={listviewId}
            actions={actions}
            otherActions={[newElectionAction, exportExcel]}
            viewProperties={{
              schema: Functionaries.InternalName,
              list: Functionaries.Lists.Functionaries.InternalName,
              name: "all_items",
              setListAndPage: true,
            }}
            fields={fields}
            allowSelectRows
            reload={loadFunctionaries}
            searchResult={functionaries}
            getFilters={getFunctionaryFilters}
            pagination={pagination}
            updateItems={(items) =>
              functionaries &&
              setFunctionaries({
                ...functionaries,
                items: items,
              })
            }
          />
        ) : (
          errorCode && <Errors errorCode={errorCode} />
        ))}
    </>
  );
};

export default AllItems;
