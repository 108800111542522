import { Item, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import { GroupFull } from "../groups/types";
import { PermissionFull } from "../permissions/types";
import { SchemaFull } from "../schemas/types";
import * as Fields from "./Fields";

export interface SchemaGroupPermissionFull extends Item {
  id: number;
  schema: SchemaFull;
  group: GroupFull;
  permission: PermissionFull;
}

export interface SchemaGroupPermissionForm {
  schema: SchemaFull | null;
  group: GroupFull | null;
  permission: PermissionFull | null;
}

export interface SchemaGroupPermissionPost {
  schemaId: number;
  groupId: string;
  permission: string;
}

export const mapToPOST = (item: SchemaGroupPermissionForm): SchemaGroupPermissionPost => {
  return {
    schemaId: item.schema?.id || 0,
    groupId: item.group?.id || "",
    permission: item.permission?.name || "",
  };
};

export const validate = (
  state: SchemaGroupPermissionForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.schema) {
    errors[Fields.schema.fieldName] = strings.required;
  }
  if (!state.group) {
    errors[Fields.group.fieldName] = strings.required;
  }
  if (!state.permission) {
    errors[Fields.permission.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};
