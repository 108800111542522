import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Actions from "../../../components/controls/Actions";
import Errors, { getString } from "../../../components/errors/Errors";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Functionaries } from "../../../listsSettings/functionaries";
import { ErrorCode, IAction, ValidationErrors } from "../../../services/types";
import {
  getCancelAction,
  getEncodedSourceFromUrl,
  getNumberFromUrl,
  getParamFromUrl,
  getSaveAction,
} from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import NewEditTable from "./NewEditTable";
import { ChapterEventForm, mapToPOST, validate } from "./types";
import { allItems, dispForm } from "./urls";
import { insertChapterEvent } from "./webapi";

import { UserPermissions } from "../../../services/types";

const NewForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const statusBar = useStatusBarState();
  const from = getNumberFromUrl("from");
  const [state, setState] = useState<ChapterEventForm | undefined>({
    title: null,
    description: null,
    location: null,
    date: getParamFromUrl("date"),
    from: from || from === 0 ? from * 60 : null,
    to: null,
    visibleOnChaptersIt: false,
    visibleOnHgvIt: false,
    signUpLink: null,
    chapter: null,
    district: null,
    category: null,
  });
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [errors, setErrors] = useState<ValidationErrors>({});
  const navigateTo = useNavigate();

  useEffect(() => {
    (async () => {
      const abortController = resetAbortController();
      const permissions = await getUserPermissions(
        Functionaries.Lists.ChapterEvents.InternalName,
        Functionaries.InternalName,
        abortController.signal
      );
      if (!abortController.signal.aborted) {
        setPermissions(permissions);
        const canCreate = permissions.schemaPermission && permissions.listPermissions.includes("create");
        !canCreate && setErrorCode(403);
      }
    })();

    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const save = async () => {
    if (state && validate(state, setErrors)) {
      const abortController = resetAbortController();
      const result = await insertChapterEvent(mapToPOST(state), abortController.signal);

      if (result.error) {
        if (result.error.code == 409 || result.error.code == 422) {
          const newErrors = result.error.details?.reduce((rv: ValidationErrors, d) => {
            rv[d.field] = strings.getMessageFromTypeError(d.type);
            return rv;
          }, {});

          newErrors && setErrors(newErrors);
        } else {
          statusBar.addError(getString(result.error));
        }
      }
      result.data && navigateTo(`${dispForm}/${result.data.id}?${getEncodedSourceFromUrl()}`);
    }
  };

  const actions: IAction[] = [getSaveAction(save), getCancelAction(navigateTo, allItems)];

  useEffect(() => {
    if (permissions) {
      setListName(Functionaries.Lists.ChapterEvents.Title);
      setPage(strings.newElement);
    }
  }, [permissions]);

  return (
    <>
      {permissions &&
        (state && !errorCode ? (
          <>
            <Title text={"Veranstaltung"} />
            <Actions actions={actions} />
            <NewEditTable state={state} setState={setState} errors={errors} />
          </>
        ) : (
          errorCode && <Errors errorCode={errorCode} />
        ))}
    </>
  );
};

export default NewForm;
