import classNames from "classnames";
import { format } from "date-fns";
import { CSSProperties, FC, PropsWithChildren } from "react";
import styles from "./Cell.module.css";
import { CalendarItem } from "./types";

interface CellProps {
  item: CalendarItem;
  classNames: string;
  cssProps?: CSSProperties;
  width?: number;
}

const Cell: FC<PropsWithChildren<CellProps>> = ({
  children,
  item,
  cssProps,
  classNames: clazz,
  width = 100,
}: PropsWithChildren<CellProps>) => {
  const description = item.description ? `(${item.description})` : "";
  const title = `${format(item.from, "HH:mm")} - ${format(item.to, "HH:mm")} ${item.title} ${description}`;

  return (
    <td className={classNames(styles.item, clazz)} style={cssProps} title={title} width={`${width}%`}>
      {children}
    </td>
  );
};

export default Cell;
