import { FC } from "react";
import { isEmailValid } from "../services/utils";

interface EmailProps {
  value?: string | null;
}

const Email: FC<EmailProps> = ({ value }: EmailProps) => {
  if (!value || !isEmailValid(value)) {
    return <>{value}</>;
  }
  return (
    <>
      {value && (
        <a href={`mailto:${value}`} onClick={(event) => event.stopPropagation()}>
          {value}
        </a>
      )}
    </>
  );
};

export default Email;
