import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import * as Fields from "./Fields";

export interface PermissionFull extends CreationInformationItem {
  name: string;
  description: string;
}

export interface PermissionForm {
  name: string | null;
  description: string | null;
}

export interface PermissionPost {
  name: string;
  description: string;
}

export const mapToPOST = (item: PermissionForm): PermissionPost => {
  return {
    name: item.name || "",
    description: item.description || "",
  };
};

export const validate = (
  state: PermissionForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.name) {
    errors[Fields.name.fieldName] = strings.required;
  }
  if (!state.description) {
    errors[Fields.description.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};
