import add from "date-fns/add";
import { FC, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import styles from "./DatePicker.module.css";
import DayView from "./DayPicker";
import MonthView from "./MonthPicker";
import YearPicker from "./YearPicker";

interface DatePickerProps {
  value?: Date | null;
  onSelect: (date: Date) => void;
  onClose: () => void;
}

const DatePicker: FC<DatePickerProps> = ({ value, onSelect, onClose }: DatePickerProps) => {
  const [state, setState] = useState<Date>(value ?? new Date());
  const [type, setType] = useState<"cal" | "months" | "years">("cal");
  const ref = useOutsideClick<HTMLDivElement>(onClose);
  const [returnTo, setReturnTo] = useState<"cal" | "months">("cal");

  const move = (key: keyof Duration, value: number) => {
    setState((prevstate) => add(prevstate, { [key]: value }));
  };

  return (
    <div ref={ref}>
      {type && (
        <div className={styles.table}>
          {type === "cal" && (
            <DayView
              date={state}
              onSelect={onSelect}
              onCancel={onClose}
              onMove={move}
              setType={setType}
              setReturnTo={setReturnTo}
            />
          )}
          {type === "months" && (
            <MonthView
              month={state.getMonth()}
              year={state.getFullYear()}
              onSelect={(month) => {
                setState((prevstate) => new Date(new Date(prevstate).setMonth(month)));
                setType("cal");
              }}
              onCancel={onClose}
              onMove={move}
              setType={setType}
            />
          )}
          {type === "years" && (
            <YearPicker
              year={state.getFullYear()}
              onSelect={(year) => {
                setState((prevstate) => new Date(new Date(prevstate).setFullYear(year)));
                setType(returnTo);
              }}
              onCancel={onClose}
              onMove={move}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DatePicker;
