import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import { UserFull } from "../users/types";
import * as Fields from "./Fields";

export interface UserMemberPermissionFull extends CreationInformationItem {
  user: UserFull;
  memberTypes: string[];
  canceledMembers: boolean;
  companies: boolean;
  canceledCompanies: boolean;
  legalRepresentative: boolean;
  departmentData: boolean;
  endpoint: string | null;
}

export interface UserMemberPermissionForm {
  user: UserFull | null;
  memberTypes: string[];
  canceledMembers: boolean;
  companies: boolean;
  canceledCompanies: boolean;
  legalRepresentative: boolean;
  departmentData: boolean;
  customEndpoint: boolean;
  endpoint: string | null;
}

export interface UserMemberPermissionPost {
  userId: number;
  memberTypes: string[];
  canceledMembers: boolean;
  companies: boolean;
  canceledCompanies: boolean;
  legalRepresentative: boolean;
  departmentData: boolean;
  endpoint: string | null;
}

const all = "all";
export const ordinary = "ordinary";
const extraordinary = "extraordinary";
const client = "client";
const freeNewspaper = "free-newspaper";
const newspaperSubscription = "newspaper-subscription";
const domesticWorkers = "domestic-worker";
export const memberTypes = [
  all,
  ordinary,
  extraordinary,
  client,
  freeNewspaper,
  newspaperSubscription,
  domesticWorkers,
];
export const renderMemberType = (type?: string | null): string => {
  switch (type) {
    case all: {
      return "Alle";
    }
    case ordinary: {
      return "Ordentlich";
    }
    case extraordinary: {
      return "Außerordentlich";
    }
    case client: {
      return "Kunde";
    }
    case freeNewspaper: {
      return "Gratis-Zeitung";
    }
    case newspaperSubscription: {
      return "Zeitung-Abo";
    }
    case domesticWorkers: {
      return "Hausangestellte";
    }
    default: {
      return type ?? "";
    }
  }
};

export const mapToPOST = (item: UserMemberPermissionForm): UserMemberPermissionPost => {
  return {
    userId: item.user?.id ?? 0,
    memberTypes: item.memberTypes.filter((_) => !item.customEndpoint),
    canceledMembers: item.canceledMembers && !item.customEndpoint,
    companies: item.companies && !item.customEndpoint,
    canceledCompanies: item.canceledCompanies && !item.customEndpoint,
    legalRepresentative: item.legalRepresentative && !item.customEndpoint,
    departmentData: item.departmentData && !item.customEndpoint,
    endpoint: item.customEndpoint ? item.endpoint : null,
  };
};

export const validate = (
  state: UserMemberPermissionForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.user?.id) {
    errors[Fields.userName.fieldName] = strings.required;
  }
  if (!state.memberTypes[0] && !state.customEndpoint) {
    errors[Fields.memberTypes.fieldName] = strings.required;
  }
  if (state.customEndpoint && !state.endpoint) {
    errors[Fields.endpoint.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};
