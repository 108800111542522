import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import * as Fields from "./Fields";

export interface GlobalNavigationItemFull extends CreationInformationItem {
  id: number;
  name: string;
  url: string | null;
  order: number | null;
  openNewTab: boolean;
  parent: GlobalNavigationItemFull | null;
}

export interface GlobalNavigationItemPost {
  name: string;
  url: string | null;
  order: number | null;
  openNewTab: boolean;
  parentId: number | null;
}

export interface GlobalNavigationMenuItemPost extends GlobalNavigationItemPost {
  id?: number;
}

export interface GlobalNavigationItemForm {
  name: string | null;
  url: string | null;
  order: number | null;
  openNewTab: boolean;
  parent: GlobalNavigationItemFull | null;
}

export const mapToPOST = (item: GlobalNavigationItemForm): GlobalNavigationItemPost => {
  return {
    name: item.name || "",
    url: item.url,
    order: item.order,
    openNewTab: !!item.openNewTab,
    parentId: item.parent?.id || null,
  };
};

export const validate = (
  state: GlobalNavigationItemForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.name) {
    errors[Fields.name.fieldName] = strings.required;
  }
  if (state.order && (state.order > 2147483647 || state.order < 0)) {
    errors[Fields.order.fieldName] = strings.invalidText;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};
