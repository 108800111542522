import classNames from "classnames";
import { FC, Fragment } from "react";
import { getCurrentUrlAsSource } from "../../services/utils";
import Link from "../Link";
import Cell from "./Cell";
import styles from "./DailyCell.module.css";
import EmptyCell from "./EmptyCell";
import { CalendarCell } from "./types";

interface DailyCellProps {
  data: CalendarCell[];
}

const DailyCell: FC<DailyCellProps> = ({ data }: DailyCellProps) => {
  const sorted = data.sort((itemA, itemB) => {
    if (!itemA.value && !itemB.value) {
      return 0;
    }
    if (!itemA.value) {
      return -1;
    }
    if (!itemB.value) {
      return 1;
    }
    return itemA.value?.item.from.valueOf() - itemB.value?.item.from.valueOf();
  });

  return (
    <table cellPadding="0" cellSpacing="0" width={"100%"}>
      <tbody>
        <tr>
          {sorted.map((d, index) => {
            if (d.value.data) {
              const min =
                d.value.item.to.getHours() * 60 +
                d.value.item.to.getMinutes() -
                (d.value.item.from.getHours() * 60 + d.value.item.from.getMinutes());
              const rows = Math.ceil(min / 30);

              const px = min + (rows > 2 ? rows - 2 : 1);
              return (
                <Fragment key={`daily-entry-${d.value.item.from.toISOString()}-${index}`}>
                  <Cell
                    classNames={classNames({ [styles.single]: !!d.value.item.title })}
                    cssProps={{ minHeight: px }}
                    item={d.value.item}
                    width={d.width}
                  >
                    <div>
                      <div>
                        <Link href={`${d.value.data.dispUrl}/${d.value.item.id}?${getCurrentUrlAsSource()}`}>
                          {d.value.item.title}
                        </Link>
                      </div>
                      {d.value.item.description && <div>{d.value.item.description}</div>}
                    </div>
                  </Cell>
                </Fragment>
              );
            } else {
              return <EmptyCell key={`daily-entry-busy-${index}`} width={d.width}></EmptyCell>;
            }
          })}
        </tr>
      </tbody>
    </table>
  );
};

export default DailyCell;
