import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import styles from "./FormLabel.module.css";
import FormTd from "./FormTd";

interface FormLabelProps {
  required?: boolean;
  noWrap?: boolean;
  rowSpan?: number;
  minWidth?: boolean;
  colSpan?: number;
}

const FormLabel: FC<PropsWithChildren<FormLabelProps>> = ({
  children,
  required,
  noWrap,
  rowSpan,
  minWidth,
  colSpan,
}: PropsWithChildren<FormLabelProps>) => {
  return (
    <FormTd
      rowSpan={rowSpan}
      colSpan={colSpan}
      props={{
        width: minWidth ? 1 : "auto",
      }}
    >
      <div className={classNames(styles.label, { [styles["no-wrap"]]: noWrap })}>
        <span>{children}</span>
        {required && <span className={styles.required}>&nbsp;*</span>}
      </div>
    </FormTd>
  );
};

export default FormLabel;
