import { setDefaultOptions } from "date-fns";
import { de } from "date-fns/locale";
import { createRoot } from "react-dom/client";
import "./colors.css";
import App from "./components/App";
import "./global.css";

setDefaultOptions({
  locale: de,
  weekStartsOn: 1,
});
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
