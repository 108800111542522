import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import { SchemaFull } from "../schemas/types";
import * as Fields from "./Fields";

export interface ListFull extends CreationInformationItem, ListForm {
  id: number;
}
export interface ListForm {
  name: string | null;
  internalName: string | null;
  inheritPermissions: boolean;
  schema: SchemaFull | null;
}

export interface ListPost {
  name: string;
  internalName: string;
  inheritPermissions: boolean;
  schemaId: number | null;
}

export const dispUrl = (item?: ListFull | ListForm | null) => {
  return item?.schema
    ? `/${item.schema.internalName}/_lists/${item.internalName}`
    : item
    ? `/_lists/${item.internalName}`
    : "";
};

export const mapToPOST = (item: ListForm): ListPost => {
  return {
    name: item.name ?? "",
    internalName: item.internalName ?? "",
    inheritPermissions: item.inheritPermissions,
    schemaId: item.schema?.id || null,
  };
};

export const validate = (
  state: ListForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.name) {
    errors[Fields.name.fieldName] = strings.required;
  }
  if (!state.internalName) {
    errors[Fields.internalName.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};
