import { FC, useEffect } from "react";
import useGlobalState from "../../hooks/useGlobalState";
import strings from "../../strings";
import { getTitleString } from "./Errors";

const NotFound: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);

  useEffect(() => {
    setPage(getTitleString(404));
  }, []);

  return (
    <>
      <div>{strings.notFoundText}</div>
      <div>&nbsp;</div>
      <div>
        Bitte überprüfen Sie die eingegebene URL oder benutzen Sie die Menüs, um zur gewünschten Seite zu navigieren.
      </div>
    </>
  );
};

export default NotFound;
