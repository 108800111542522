import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import { ValidationErrors } from "../../../services/types";
import * as Fields from "./Fields";
import { CategoryForm } from "./types";

const NewEditTable = ({
  state,
  setState,
  errors,
}: {
  state: CategoryForm;
  setState: React.Dispatch<React.SetStateAction<CategoryForm | undefined>>;
  errors: ValidationErrors;
}) => {
  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.name.title}</FormLabel>
        <FormData>
          <Input
            value={state.name}
            onChange={(value) =>
              setState({
                ...state,
                name: value,
              })
            }
            autoFocus
          />
          <FormError error={errors[Fields.name.fieldName]} />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;
