import { isValid, parseISO } from "date-fns";
import { StatusBarState } from "../../../hooks/useStatusBar";
import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import { Company } from "../../members/companies/types";
import { PersonFull } from "../../public/persons/types";
import { ChapterFull } from "../chapters/types";
import * as FunctionariesFields from "../functionaries/Fields";
import { FunctionaryFull } from "../functionaries/types";
import { LegislatureFull } from "../legislatures/types";
import { RoleFull } from "../roles/types";
import * as Fields from "./Fields";

export interface ChapterElectionFull extends CreationInformationItem {
  id: number;
  legislature: LegislatureFull;
  chapter: ChapterFull;
  electionDate: string | null;
  functionaries: FunctionaryFull[];
}

export interface ChapterElectionFunctionary {
  person: PersonFull | null;
  roles: RoleFull[];
  company: Company | null;
}

export interface ChapterElectionForm {
  legislature: LegislatureFull | null;
  chapter: ChapterFull | null;
  electionDate: string | null;
  functionaries: ChapterElectionFunctionary[];
}

export interface ChapterElectionPost {
  legislatureId: number;
  chapterId: number;
  electionDate: string;
  functionaries: {
    personId: number;
    companyId: number;
    roleIds: number[];
  }[];
}

export const mapToPOST = (item: ChapterElectionForm): ChapterElectionPost => {
  return {
    legislatureId: item.legislature?.id || 0,
    chapterId: item.chapter?.id ?? 0,
    electionDate: item.electionDate ?? "",
    functionaries: item.functionaries.map((x) => ({
      companyId: x.company?.id ?? 0,
      personId: x.person?.id ?? 0,
      roleIds: x.roles.filter((y) => y.selected).map((y) => y.id),
    })),
  };
};

export const validate = (
  state: ChapterElectionForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>,
  statusBar: StatusBarState
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.legislature) {
    errors[Fields.legislature.fieldName] = strings.required;
  }
  if (!state.electionDate) {
    errors[Fields.electionDate.fieldName] = strings.required;
  } else if (state.electionDate) {
    const date = parseISO(state.electionDate);
    !isValid(date) && (errors[Fields.electionDate.fieldName] = strings.invalidText);
  }
  if (!state.chapter) {
    errors[Fields.chapter.fieldName] = strings.required;
  }

  state.functionaries.length === 0 ? statusBar.addError("Es wurden keine Funktionäre angegeben.") : statusBar.clear();

  const missingPersons = !state.functionaries.reduce((rv: boolean, f, index) => {
    const error = !f.person;
    errors[`${FunctionariesFields.person.fieldName}_${index}`] = error ? "error" : undefined;
    return rv && !error;
  }, true);

  const missingCompanies = !state.functionaries.reduce((rv: boolean, f, index) => {
    const error = !f.company;
    errors[`${FunctionariesFields.company.fieldName}_${index}`] = error ? "error" : undefined;
    return rv && !error;
  }, true);

  const missingRoles = !state.functionaries.reduce((rv: boolean, f, index) => {
    const error = f.roles.filter((x) => x.selected).length == 0;
    errors[`${FunctionariesFields.role.fieldName}_${index}`] = error ? "error" : undefined;
    return rv && !error;
  }, true);

  setErrors(errors);

  return (
    Object.keys(errors).filter((x) => errors[x]).length === 0 &&
    state.functionaries.length > 0 &&
    !missingPersons &&
    !missingCompanies &&
    !missingRoles
  );
};
