import { Pagination } from "../../../components/listview/Pagination";
import { Members } from "../../../listsSettings/members";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import { getFilters, getItem, getItems, members } from "../../../services/webapi";
import { Member } from "./types";

const membersUrl: string = `${members}/${Members.Lists.Members.InternalName}`;

export const getMember = async (id: number, signal: AbortSignal): Promise<Result<Member | null>> => {
  const url = `${membersUrl}/${id}`;
  return getItem<Member>(url, signal);
};

export const getMembers = async (pagination: Pagination, signal: AbortSignal): Promise<Result<SearchResult<Member>>> =>
  getItems<Member>(membersUrl, pagination, signal);

export const getMembersHgvMembers = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<Member> | null>> => getItems<Member>(`${membersUrl}/hgv_members`, pagination, signal);

export const getMemberFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(membersUrl, fieldName, signal);

export const getMemberHgvMembersFilters = (
  fieldName: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${membersUrl}/hgv_members`, fieldName, signal);
