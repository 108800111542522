import { Pagination } from "../../../components/listview/Pagination";
import { Members } from "../../../listsSettings/members";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import { getFilters, getItem, getItems, members, searchItems } from "../../../services/webapi";
import { Company } from "./types";

const companiesUrl: string = `${members}/${Members.Lists.Companies.InternalName}`;

export const getCompany = async (id: number, signal: AbortSignal): Promise<Result<Company | null>> => {
  const url = `${companiesUrl}/${id}`;
  return getItem<Company>(url, signal);
};

export const getCompanies = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<Company> | null>> => getItems<Company>(companiesUrl, pagination, signal);

export const getActiveCompanies = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<Company> | null>> =>
  getItems<Company>(`${companiesUrl}/active_companies`, pagination, signal);

export const searchCompanies = async (
  searchValue: string,
  signal: AbortSignal
): Promise<Result<SearchResult<Company> | null>> => searchItems<Company>(companiesUrl, searchValue, signal);

export const getCompanyFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(companiesUrl, fieldName, signal);

export const getActiveCompanyFilters = (
  fieldName: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(`${companiesUrl}/active_companies`, fieldName, signal);
