import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import { DialogOptions, SearchDialog } from "../../../components/dialogs/SearchDialog";
import Email from "../../../components/Email";
import Errors, { getString } from "../../../components/errors/Errors";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import ReferencedItemsView from "../../../components/listview/ReferencedItemsView";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Administration } from "../../../listsSettings/administration";
import { ErrorCode, IAction, SearchResult, UserPermissions } from "../../../services/types";
import { formatBoolean, getCloseAction, getSelectedItems, getSourceFromUrl } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import * as GroupFields from "../groups/Fields";
import { GroupFull } from "../groups/types";
import { editForm as editFormGroups, newForm as newFormGroups } from "../groups/urls";
import { searchGroups } from "../groups/webapi";
import * as Fields from "./Fields";
import { UserFull } from "./types";
import { allItems } from "./urls";
import { addGroup, getUser, getUserGroups, getUserGroupsFilters, removeGroups } from "./webapi";

const DispForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<UserFull>();
  const [groups, setGroups] = useState<SearchResult<GroupFull> | null>(null);
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [groupPermissions, setGroupPermissions] = useState<UserPermissions>();
  const statusBar = useStatusBarState();
  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(id);

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: string) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Administration.Lists.Users.InternalName,
      Administration.InternalName,
      abortController.signal
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const [groupPermissions, result] = await Promise.all([
          getUserPermissions(
            Administration.Lists.Groups.InternalName,
            Administration.InternalName,
            abortController.signal
          ),
          getUser(id, abortController.signal),
        ]);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);

          if (result.data) {
            setGroupPermissions(groupPermissions);
            setState(result.data);
            groupPermissions?.listPermissions.includes("read-all") &&
              (await loadGroups(result.data.id, pagination, abortController));
          }
        }
      }
    }
  };

  const groupFields = [
    GroupFields.name,
    GroupFields.description,
    GroupFields.email,
    GroupFields.isActiveDirectoryGroup,
  ];

  const groupDialogFields = [GroupFields.name, GroupFields.description];

  const [showGroupsDialog, setShowGroupsDialog] = useState(false);
  const groupsDialogOptions: DialogOptions<GroupFull> = {
    title: Administration.Lists.Groups.Title,
    fields: groupDialogFields,
    searchItems: (value: string, abortController: AbortController) => searchGroups(value, abortController.signal),
    onItemSelected: async (group: GroupFull) => {
      if (state) {
        const abortController = resetAbortController();
        await addGroup(state, group, abortController.signal);
      }
    },
    cancelClicked: () => setShowGroupsDialog(false),
    isOpen: showGroupsDialog,
  };

  const listviewId = "user-groups";

  const pagination = getPagination(listviewId) || {
    ...DefaultPagination,
  };

  const loadGroups = async (groupId: string, pagination: Pagination, abortController: AbortController) => {
    const result = await getUserGroups(groupId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setGroups(result.data);
    }
  };

  const closeAction: IAction = getCloseAction(navigateTo, allItems);

  const addGroupAction: IAction = {
    title: strings.new,
    icon: Add,
    onClick: () => navigateTo(newFormGroups),
    disabled:
      !groupPermissions?.listPermissions.includes("read-all") || !permissions?.listPermissions.includes("edit-all"),
  };

  const editGroupAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => {
      const selectedItem = getSelectedItems(groups)[0];
      selectedItem && navigateTo(getSourceFromUrl() ?? `${editFormGroups}/${selectedItem.id}`);
    },
    disabled: getSelectedItems(groups).length !== 1 || !groupPermissions?.listPermissions.includes("edit-all"),
  };

  const removeGroupsAction: IAction = {
    title: strings.remove,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(groups);
        if (selectedItems.length > 0 && confirm(strings.confirmRemoveItems)) {
          const abortController = resetAbortController();
          await removeGroups(state, selectedItems, abortController.signal);
        }
      }
    },
    disabled: getSelectedItems(groups).length === 0 || !groupPermissions?.listPermissions.includes("delete-all"),
  };

  const actions = [addGroupAction, editGroupAction, removeGroupsAction];

  useEffect(() => {
    if (state) {
      setListName(Administration.Lists.Users.Title);
      setPage(state.name);
    }
  }, [state]);

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Benutzer"} />
          <Actions actions={[closeAction]} />
          <FormTable>
            <FormTr>
              <FormLabel>{Fields.name.title}</FormLabel>
              <FormData disp>{state?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.department.title}</FormLabel>
              <FormData disp>{state?.department}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.email.title}</FormLabel>
              <FormData disp>
                <Email value={state?.email} />
              </FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.offices.title}</FormLabel>
              <FormData disp>
                {state?.offices
                  ? state?.offices.length == 1
                    ? state.offices[0]?.name
                    : state?.offices.map((x) => `${x.name};`).join()
                  : null}
              </FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.accountEnabled.title}</FormLabel>
              <FormData disp>
                <Email value={formatBoolean(state?.accountEnabled)} />
              </FormData>
            </FormTr>
          </FormTable>
          <div>&nbsp;</div>

          {showGroupsDialog && (
            <SearchDialog
              title="Suche Gruppe"
              fields={groupsDialogOptions.fields}
              searchItems={groupsDialogOptions.searchItems}
              onItemSelected={groupsDialogOptions.onItemSelected}
              cancelClicked={() => setShowGroupsDialog(false)}
              isOpen={showGroupsDialog}
            />
          )}
          <ReferencedItemsView
            itemId={state.id}
            id={listviewId}
            actions={actions}
            title={Administration.Lists.Groups.Title}
            fields={groupFields}
            allowSelectRows
            isItemSelectable={(item: GroupFull) => !item.isActiveDirectoryGroup}
            reload={(id, pagination) => {
              const abortController = resetAbortController();
              return loadGroups(id, pagination, abortController);
            }}
            searchResult={groups}
            getFilters={getUserGroupsFilters}
            pagination={pagination}
            updateItems={(items) =>
              groups &&
              setGroups({
                ...groups,
                items: items,
              })
            }
          />
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;
