import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import Errors, { getString } from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Functionaries } from "../../../listsSettings/functionaries";
import { ErrorCode, IAction, UserPermissions } from "../../../services/types";
import {
  formatDateFromISOString,
  getCloseAction,
  getCurrentUrlAsSource,
  getSourceFromUrl,
} from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import { getItemTitle as getPersonTitle } from "../../public/persons/types";
import { getItemTitle as getLegislatureTitle } from "../legislatures/types";
import * as Fields from "./Fields";
import { FunctionaryFull } from "./types";
import { allItems, editForm } from "./urls";
import { deleteFunctionary, getFunctionary } from "./webapi";

const DispForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<FunctionaryFull>();
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const statusBar = useStatusBarState();
  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(Number(id));

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Functionaries.Lists.Functionaries.InternalName,
      Functionaries.InternalName,
      abortController.signal
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getFunctionary(id, abortController.signal);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);
          result.data && setState(result.data);
        }
      }
    }
  };

  const onDelete = async () => {
    if (state && confirm(strings.confirmDeleteItem)) {
      const abortController = resetAbortController();
      const error = await deleteFunctionary(state.id, abortController.signal);
      if (!abortController.signal.aborted) {
        error && statusBar.addError(getString(error));
        !error && navigateTo(getSourceFromUrl() ?? allItems);
      }
    }
  };
  const editAction: IAction = {
    title: strings.edit,
    icon: Edit,

    onClick: () => state && navigateTo(`${editForm}/${state.id}?${getCurrentUrlAsSource()}`),
    disabled: !permissions || !permissions.listPermissions.includes("edit-all"),
  };
  const closeAction: IAction = getCloseAction(navigateTo, allItems);
  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: onDelete,
    disabled: !permissions || !permissions.listPermissions.includes("delete-all"),
  };

  useEffect(() => {
    if (state) {
      setListName(Functionaries.Lists.Functionaries.Title);
      setPage(getPersonTitle(state.person) ?? null);
    }
  }, [state]);

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Funktionär"} />
          <Actions actions={[editAction, closeAction, deleteAction]} />
          <FormTable>
            <FormTr>
              <FormLabel>{Fields.legislature.title}</FormLabel>
              <FormData disp>{getLegislatureTitle(state?.legislature)}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.person.title}</FormLabel>
              <FormData disp>{getPersonTitle(state?.person)}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>Betrieb</FormLabel>
              <FormData disp>{state?.company?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.district.title}</FormLabel>
              <FormData disp>{state?.district?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.area.title}</FormLabel>
              <FormData disp>{state?.area?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.chapter.title}</FormLabel>
              <FormData disp>{state?.chapter?.membersChapter?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.role.title}</FormLabel>
              <FormData disp>{state?.role?.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.electionDate.title}</FormLabel>
              <FormData disp>{formatDateFromISOString(state?.electionDate)}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.endDate.title}</FormLabel>
              <FormData disp>{formatDateFromISOString(state?.endDate)}</FormData>
            </FormTr>
          </FormTable>
          <CreatedModifiedInfo info={state} />
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;
