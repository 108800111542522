import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Functionaries } from "../../../listsSettings/functionaries";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  deleteItem,
  deleteItems,
  functionaries,
  getFilters,
  getItem,
  getItems,
  insertItem,
  updateItem,
} from "../../../services/webapi";
import { ChapterEventCategoryFull, ChapterEventCategoryPost } from "./types";

const chapterEventsCategoriesUrl: string = `${functionaries}/${Functionaries.Lists.ChapterEventsCategories.InternalName}`;
export const insertchapterEventCategory = async (
  categoryChapterEvent: ChapterEventCategoryPost,
  signal: AbortSignal
): Promise<Result<ChapterEventCategoryFull | null>> =>
  insertItem(chapterEventsCategoriesUrl, categoryChapterEvent, signal);

export const updatechapterEventCategory = async (
  categoryChapterEventId: number,
  categoryChapterEvent: ChapterEventCategoryPost,
  signal: AbortSignal
): Promise<Result<ChapterEventCategoryFull | null>> =>
  updateItem(`${chapterEventsCategoriesUrl}/${categoryChapterEventId}`, categoryChapterEvent, signal);

export const deletechapterEventCategory = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${chapterEventsCategoriesUrl}/${id}`, signal);

export const deletechapterEventsCategories = (
  ids: number[],
  signal: AbortSignal
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(chapterEventsCategoriesUrl, ids, signal);

export const getChapterEventCategory = async (
  id: number,
  signal: AbortSignal
): Promise<Result<ChapterEventCategoryFull | null>> =>
  getItem<ChapterEventCategoryFull>(`${chapterEventsCategoriesUrl}/${id}`, signal);

export const getChapterEventsCategories = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<ChapterEventCategoryFull> | null>> =>
  getItems<ChapterEventCategoryFull>(chapterEventsCategoriesUrl, pagination, signal);

export const getChapterEventCategoryFilters = (
  fieldName: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(chapterEventsCategoriesUrl, fieldName, signal);
