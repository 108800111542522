import { FC, useEffect, useState } from "react";
import Button from "../../../components/Button";
import { DialogBorder } from "../../../components/dialogs/DialogBorder";
import { DialogCloseIcon } from "../../../components/dialogs/DialogCloseIcon";
import { DialogContainer } from "../../../components/dialogs/DialogContainer";
import { Draggable } from "../../../components/dialogs/Draggable";
import Modal from "../../../components/dialogs/Modal";
import { getString } from "../../../components/errors/Errors";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useStatusBarState from "../../../hooks/useStatusBar";
import { ValidationErrors } from "../../../services/types";
import { ListFull } from "../lists/types";
import NewEditTable from "./NewEditTable";
import { ListUserPermissionForm, mapToPOST, validate } from "./types";
import { insertListUserPermission } from "./webapi";

interface NewDialogFormProps {
  list: ListFull;
  isOpen: boolean;
  onCancel: () => void;
  onSave: () => void;
}

const NewDialogForm: FC<NewDialogFormProps> = ({ list, isOpen, onCancel, onSave }: NewDialogFormProps) => {
  const statusBar = useStatusBarState();
  const [abortController, resetAbortController] = useAbortController();
  const [form, setForm] = useState<ListUserPermissionForm | undefined>({
    list: list,
    user: null,
    permission: null,
  });
  const [errors, setErrors] = useState<ValidationErrors>({});

  useEffect(() => {
    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const save = async () => {
    if (form && validate(form, setErrors)) {
      const abortController = resetAbortController();
      const result = await insertListUserPermission(mapToPOST(form), abortController.signal);
      if (result.error) {
        statusBar.addError(getString(result.error));
        onCancel();
      }
      result.data && onSave();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onCancel()}>
      <Draggable>
        <DialogContainer>
          <DialogCloseIcon onClose={() => onCancel()} />
          <DialogBorder>
            <Title text="Neue Berechtigung vergeben" />
            {form && <NewEditTable state={form} setState={setForm} errors={errors} />}
            <Button onclick={save} disabled={!form}>
              Speichern
            </Button>
          </DialogBorder>
        </DialogContainer>
      </Draggable>
    </Modal>
  );
};

export default NewDialogForm;
