import { Administration } from "../../../listsSettings/administration";
import { Result } from "../../../services/types";
import { administration, deleteItems, insertItem, updateItem } from "../../../services/webapi";
import { SchemaUserPermissionFull, SchemaUserPermissionPost } from "./types";

const schemaUserPermissionsUrl: string = `${administration}/${Administration.Lists.SchemaUserPermissions.InternalName}`;

export const insertSchemaUserPermission = async (
  schemaUserPermission: SchemaUserPermissionPost,
  signal: AbortSignal
): Promise<Result<SchemaUserPermissionFull | null>> =>
  insertItem(schemaUserPermissionsUrl, schemaUserPermission, signal);

export const updateSchemaUserPermission = async (
  schemaUserPermissionId: number,
  schemaUserPermission: SchemaUserPermissionPost,
  signal: AbortSignal
): Promise<Result<SchemaUserPermissionFull | null>> =>
  updateItem(`${schemaUserPermissionsUrl}/${schemaUserPermissionId}`, schemaUserPermission, signal);

export const deleteSchemaUserPermissions = (
  ids: number[],
  signal: AbortSignal
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(schemaUserPermissionsUrl, ids, signal);
