import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import Errors, { getString } from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import ReferencedItemsView from "../../../components/listview/ReferencedItemsView";
import CustomLexicalEditor from "../../../components/richTextEditor/CustomLexicalEditor";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Conventions } from "../../../listsSettings/conventions";
import { ErrorCode, IAction, SearchResult, UserPermissions } from "../../../services/types";
import {
  formatBoolean,
  getCloseAction,
  getCurrentUrlAsSource,
  getSelectedItems,
  getSourceFromUrl,
} from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import * as AttachmentFields from "../attachments/Fields";
import { AttachmentFull } from "../attachments/types";
import { editForm as editFormAttachments, newForm as newFormAttachments } from "../attachments/urls";
import { deleteAttachments } from "../attachments/webapi";
import * as ContactFields from "../contacts/Fields";
import { ContactFull } from "../contacts/types";
import { editForm as editFormContacts, newForm as newFormContacts } from "../contacts/urls";
import { deleteContacts } from "../contacts/webapi";
import * as Fields from "./Fields";
import { ConventionFull } from "./types";
import { allItems, editForm } from "./urls";
import {
  deleteConvention,
  getConvention,
  getConventionAttachments,
  getConventionAttachmentsFilters,
  getConventionContacts,
  getConventionContactsFilters,
} from "./webapi";

const DispForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<ConventionFull>();
  const [contacts, setContacts] = useState<SearchResult<ContactFull> | null>(null);
  const [attachments, setAttachments] = useState<SearchResult<AttachmentFull> | null>(null);
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [contactPermissions, setContactPermissions] = useState<UserPermissions>();
  const statusBar = useStatusBarState();
  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(Number(id));

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Conventions.Lists.Conventions.InternalName,
      Conventions.InternalName,
      abortController.signal
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const [contactPermissions, result] = await Promise.all([
          getUserPermissions(Conventions.Lists.Contacts.InternalName, Conventions.InternalName, abortController.signal),
          getConvention(id, abortController.signal),
        ]);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);

          if (result.data) {
            setContactPermissions(contactPermissions);
            setState(result.data);
            loadContacts(result.data.id, paginationContacts, abortController);
            loadAttachments(result.data.id, paginationAttachments, abortController);
          }
        }
      }
    }
  };

  const onDelete = async () => {
    if (state && confirm(strings.confirmDeleteItem)) {
      const abortController = resetAbortController();
      const error = await deleteConvention(state.id, abortController.signal);
      if (!abortController.signal.aborted) {
        error && statusBar.addError(getString(error));
        !error && navigateTo(getSourceFromUrl() ?? allItems);
      }
    }
  };

  const editAction: IAction = {
    title: strings.edit,
    icon: Edit,

    onClick: () => state && navigateTo(`${editForm}/${state.id}?${getCurrentUrlAsSource()}`),
    disabled: !permissions || !permissions.listPermissions.includes("edit-all"),
  };
  const closeAction: IAction = getCloseAction(navigateTo, allItems);
  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: onDelete,
    disabled: !permissions || !permissions.listPermissions.includes("delete-all"),
  };

  useEffect(() => {
    if (state) {
      setListName(Conventions.Lists.Conventions.Title);
      setPage(state.title);
    }
  }, [state]);

  const listviewIdContacts = "convention-contacts";

  const paginationContacts = getPagination(listviewIdContacts) || {
    ...DefaultPagination,
  };

  const loadContacts = async (conventionId: number, pagination: Pagination, abortController: AbortController) => {
    const result = await getConventionContacts(conventionId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setContacts(result.data);
    }
  };

  const addContactAction: IAction = {
    title: strings.new,
    icon: Add,
    onClick: () => state?.id && navigateTo(`${newFormContacts}?conventionId=${state.id}&${getCurrentUrlAsSource()}`),
    disabled:
      !contactPermissions?.listPermissions.includes("create") || !permissions?.listPermissions.includes("read-all"),
  };

  const editContactAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => {
      const selectedItem = getSelectedItems(contacts)[0];
      selectedItem && navigateTo(`${editFormContacts}/${selectedItem.id}?${getCurrentUrlAsSource()}`);
    },
    disabled: getSelectedItems(contacts).length !== 1 || !permissions?.listPermissions.includes("edit-all"),
  };

  const deleteContactAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(contacts);
        if (selectedItems.length > 0 && confirm(strings.confirmDeleteItems)) {
          const abortController = resetAbortController();
          const result = await deleteContacts(
            selectedItems.map((x) => x.id),
            abortController.signal
          );
          if (!abortController.signal.aborted) {
            if (result.error) {
              statusBar.addError(getString(result.error));
            } else if (result.data) {
              const s = selectedItems.filter((x) => x.id && result.data?.[x.id]);

              if (s.length != selectedItems.length) {
                statusBar.addWarning(strings.notAllElementsDeleted);
              }
              if (s.length > 0) {
                await loadContacts(state.id, paginationContacts, abortController);
              }
            }
          }
        }
      }
    },
    disabled: getSelectedItems(contacts).length === 0 || !permissions?.listPermissions.includes("delete-all"),
  };

  const contactActions = [addContactAction, editContactAction, deleteContactAction];

  const listviewIdAttachments = "convention-attachments";

  const paginationAttachments = getPagination(listviewIdAttachments) || {
    ...DefaultPagination,
  };

  const loadAttachments = async (conventionId: number, pagination: Pagination, abortController: AbortController) => {
    const result = await getConventionAttachments(conventionId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setAttachments(result.data);
    }
  };

  const addAttachmentAction: IAction = {
    title: strings.new,
    icon: Add,
    onClick: () => navigateTo(`${newFormAttachments}?conventionId=${state?.id}&${getCurrentUrlAsSource()}`),
    disabled:
      !contactPermissions?.listPermissions.includes("create") || !permissions?.listPermissions.includes("read-all"),
  };

  const editAttachmentAction: IAction = {
    title: strings.editMetadata,
    icon: Edit,
    onClick: () => {
      const selectedItem = getSelectedItems(attachments)[0];
      selectedItem && navigateTo(`${editFormAttachments}/${selectedItem.id}?${getCurrentUrlAsSource()}`);
    },
    disabled: getSelectedItems(attachments).length !== 1 || !permissions?.listPermissions.includes("edit-all"),
  };

  const deleteAttachmentAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(attachments);
        if (selectedItems.length > 0 && confirm(strings.confirmDeleteItems)) {
          const abortController = resetAbortController();
          const result = await deleteAttachments(
            selectedItems.map((x) => x.id),
            abortController.signal
          );
          if (!abortController.signal.aborted) {
            if (result.error) {
              statusBar.addError(getString(result.error));
            } else if (result.data) {
              const s = selectedItems.filter((x) => x.id && result.data?.[x.id]);

              if (s.length != selectedItems.length) {
                statusBar.addWarning(strings.notAllElementsDeleted);
              }
              if (s.length > 0) {
                await loadAttachments(state.id, paginationAttachments, abortController);
              }
            }
          }
        }
      }
    },
    disabled: getSelectedItems(attachments).length === 0 || !permissions?.listPermissions.includes("delete-all"),
  };

  const attachmentActions = [addAttachmentAction, editAttachmentAction, deleteAttachmentAction];

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Konvention"} />
          <Actions actions={[editAction, closeAction, deleteAction]} />
          <FormTable>
            <FormTr>
              <FormLabel>{Fields.title.title}</FormLabel>
              <FormData disp fitContent>
                {state.title}
              </FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.description.title}</FormLabel>
              <FormData fitContent>
                <CustomLexicalEditor initialValue={state.description} size={{ width: 1000 }} disp />
              </FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.category.title}</FormLabel>
              <FormData disp fitContent>
                {state.category.name}
              </FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.partner.title}</FormLabel>
              <FormData disp fitContent>
                {state.partner?.name}
              </FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.online.title}</FormLabel>
              <FormData disp>{formatBoolean(state.online)}</FormData>
            </FormTr>
          </FormTable>
          <CreatedModifiedInfo info={state} />

          <ReferencedItemsView
            itemId={state.id}
            id={listviewIdContacts}
            actions={contactActions}
            title={Conventions.Lists.Contacts.Title}
            fields={[
              ContactFields.name,
              ContactFields.phone,
              ContactFields.mobile,
              ContactFields.email,
              ContactFields.website,
            ]}
            allowSelectRows
            reload={(conventionId, pagination) => {
              const abortController = resetAbortController();
              return loadContacts(conventionId, pagination, abortController);
            }}
            searchResult={contacts}
            getFilters={getConventionContactsFilters}
            pagination={paginationContacts}
            updateItems={(items) =>
              contacts &&
              setContacts({
                ...contacts,
                items: items,
              })
            }
          />

          <ReferencedItemsView
            itemId={state.id}
            id={listviewIdAttachments}
            actions={attachmentActions}
            title={Conventions.Lists.Attachments.Title}
            fields={[AttachmentFields.file, AttachmentFields.displayname]}
            documentLibraryOptions={{
              field: AttachmentFields.extension,
            }}
            allowSelectRows
            reload={(conventionId, pagination) => {
              const abortController = resetAbortController();
              return loadAttachments(conventionId, pagination, abortController);
            }}
            searchResult={attachments}
            getFilters={getConventionAttachmentsFilters}
            pagination={paginationAttachments}
            updateItems={(items) =>
              attachments &&
              setAttachments({
                ...attachments,
                items: items,
              })
            }
          />
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;
