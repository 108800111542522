import Link from "../../../components/Link";
import { IDialogField, IListviewField } from "../../../services/types";
import { getCurrentUrlAsSource } from "../../../services/utils";
import { PermissionFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<PermissionFull> & IDialogField<PermissionFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: PermissionFull) => (
    <Link href={`${dispForm}/${item.name}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
  renderDialogValue: (item: PermissionFull) => item.name,
};

export const description: IListviewField<PermissionFull> & IDialogField<PermissionFull> = {
  title: "Beschreibung",
  fieldName: "description",
  renderListValue: (item: PermissionFull) => item.description,
  renderDialogValue: (item: PermissionFull) => item.description,
};
