import { FC, useEffect, useState } from "react";
import Errors from "../../../components/errors/Errors";
import Listview from "../../../components/listview/Listview";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import useAbortController from "../../../hooks/useAbortController";
import { Members } from "../../../listsSettings/members";
import { ErrorCode, IListviewField, SearchResult, UserPermissions } from "../../../services/types";
import { getUserPermissions } from "../../../services/webapi";
import * as Fields from "./Fields";
import { MemberBonus } from "./types";
import { getBonusesBonusesPreviousYear, getMemberBonusBonusesPreviousYearFilters } from "./webapi";

const BonusesPreviousYear: FC = () => {
  const listviewId = "member-bonuses-prev-year";
  const fields: IListviewField<MemberBonus>[] = [
    Fields.member,
    Fields.year,
    Fields.bonus,
    Fields.invoiced,
    Fields.remainingBalance,
    Fields.valid,
  ];

  const [chapters, setBonuses] = useState<SearchResult<MemberBonus> | null>(null);

  const [abortController, resetAbortController] = useAbortController();
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();

  const pagination = getPagination(listviewId) || {
    ...DefaultPagination,
  };

  useEffect(() => {
    loadBonuses(pagination);

    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const loadBonuses = async (pagination: Pagination) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Members.Lists.Bonuses.InternalName,
      Members.InternalName,
      abortController.signal
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getBonusesBonusesPreviousYear(pagination, abortController.signal);
        result.data && setBonuses(result.data);
        result.error && setErrorCode(result.error.code);
      }
    }
  };

  return (
    <>
      {permissions &&
        (!errorCode ? (
          <Listview
            id={listviewId}
            viewProperties={{
              schema: Members.InternalName,
              list: Members.Lists.Bonuses.InternalName,
              name: "previous_year",
              setListAndPage: true,
            }}
            fields={fields}
            allowSelectRows
            reload={loadBonuses}
            searchResult={chapters}
            getFilters={getMemberBonusBonusesPreviousYearFilters}
            pagination={pagination}
            updateItems={(items) =>
              chapters &&
              setBonuses({
                ...chapters,
                items: items,
              })
            }
          />
        ) : (
          errorCode && <Errors errorCode={errorCode} />
        ))}
    </>
  );
};

export default BonusesPreviousYear;
