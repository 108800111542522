import { FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ListviewOptions } from "../../services/types";
import Menu from "../../svg/Menu.svg?react";
import Action from "../controls/Action";
import styles from "./ListviewViews.module.css";

interface ListviewViewsProps {
  options: ListviewOptions;
}

const buildUrl = (list: string, view: string, schema?: string | null): string =>
  schema ? `/${schema}/_lists/${list}/_pages/${view}` : `/_lists/${list}/_pages/${view}`;

const ListviewViews: FC<ListviewViewsProps> = (props: ListviewViewsProps) => {
  const navigateTo = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const openMenu = () => setShowMenu(true);
  const closeMenu = () => setShowMenu(false);
  const ref = useOutsideClick<HTMLDivElement>(closeMenu);

  return (
    <>
      {props.options.view.views && props.options.view.views.length > 0 && (
        <div ref={ref} className={styles["other-actions"]}>
          <Action title={props.options.view.name} icon={Menu} onClick={openMenu} />
          {showMenu && (
            <div className={styles.menu}>
              {props.options.view.views?.map(
                (x, i) =>
                  x && (
                    <Fragment key={`action_${i}`}>
                      <Action
                        title={x.name}
                        onClick={() => {
                          navigateTo(
                            buildUrl(props.options.list.internalName, x.internalName, props.options.schema.internalName)
                          );
                          closeMenu();
                        }}
                      />
                    </Fragment>
                  )
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ListviewViews;
