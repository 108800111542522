import { FC } from "react";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import Link from "../../../components/Link";
import { ValidationErrors } from "../../../services/types";
import * as Fields from "./Fields";
import { dispUrl, SchemaForm } from "./types";

interface NewEditTableProps {
  state: SchemaForm;
  setState: React.Dispatch<React.SetStateAction<SchemaForm | undefined>>;
  errors: ValidationErrors;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.name.title}</FormLabel>
        <FormData>
          <Input
            value={state.name}
            onChange={(value) =>
              setState({
                ...state,
                name: value,
              })
            }
          />
          <FormError error={errors[Fields.name.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.internalName.title}</FormLabel>
        <FormData>
          <Input
            value={state.internalName}
            onChange={(value) =>
              setState({
                ...state,
                internalName: value,
              })
            }
          />
          <FormError error={errors[Fields.internalName.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.url.title}</FormLabel>
        <FormData disp>
          <Link href={dispUrl(state)}></Link>
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;
