import { FC } from "react";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import { ValidationErrors } from "../../../services/types";
import * as Fields from "./Fields";
import { PermissionForm } from "./types";

interface NewEditTableProps {
  state: PermissionForm;
  setState: React.Dispatch<React.SetStateAction<PermissionForm | undefined>>;
  errors: ValidationErrors;
  isEdit?: boolean;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors, isEdit }: NewEditTableProps) => {
  return (
    <FormTable>
      <FormTr>
        <FormLabel required={!isEdit}>{Fields.name.title}</FormLabel>
        <FormData disp={isEdit}>
          {isEdit ? (
            <>{state?.name}</>
          ) : (
            <Input
              value={state.name}
              onChange={(value) =>
                setState({
                  ...state,
                  name: value,
                })
              }
            />
          )}
          <FormError error={errors[Fields.name.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.description.title}</FormLabel>
        <FormData>
          <Input
            value={state.description}
            onChange={(value) =>
              setState({
                ...state,
                description: value,
              })
            }
          />
          <FormError error={errors[Fields.description.fieldName]} />
        </FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;
