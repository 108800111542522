import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Administration } from "../../../listsSettings/administration";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  administration,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  updateItem,
} from "../../../services/webapi";
import { CommuneFull, CommunePost } from "./types";

const communesUrl: string = `${administration}/${Administration.Lists.Communes.InternalName}`;
export const insertCommune = async (commune: CommunePost, signal: AbortSignal): Promise<Result<CommuneFull | null>> =>
  insertItem(communesUrl, commune, signal);

export const updateCommune = async (
  communeId: number,
  commune: CommunePost,
  signal: AbortSignal
): Promise<Result<CommuneFull | null>> => updateItem(`${communesUrl}/${communeId}`, commune, signal);

export const deleteCommune = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${communesUrl}/${id}`, signal);

export const deleteCommunes = (
  ids: number[],
  signal: AbortSignal
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(communesUrl, ids, signal);

export const getCommune = async (id: number, signal: AbortSignal): Promise<Result<CommuneFull | null>> =>
  getItem<CommuneFull>(`${communesUrl}/${id}`, signal);

export const getCommunes = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<CommuneFull> | null>> => getItems<CommuneFull>(communesUrl, pagination, signal);

export const getCommuneFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(communesUrl, fieldName, signal);
