import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Functionaries } from "../../../listsSettings/functionaries";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  deleteItem,
  deleteItems,
  functionaries,
  getFilters,
  getItem,
  getItems,
  insertItem,
  updateItem,
} from "../../../services/webapi";
import { ChapterEventFull, ChapterEventPost } from "./types";

const chapterEventsUrl: string = `${functionaries}/${Functionaries.Lists.ChapterEvents.InternalName}`;
export const insertChapterEvent = async (
  chapterEvent: ChapterEventPost,
  signal: AbortSignal
): Promise<Result<ChapterEventFull | null>> => insertItem(chapterEventsUrl, chapterEvent, signal);

export const updateChapterEvent = async (
  chapterEventId: number,
  chapterEvent: ChapterEventPost,
  signal: AbortSignal
): Promise<Result<ChapterEventFull | null>> =>
  updateItem(`${chapterEventsUrl}/${chapterEventId}`, chapterEvent, signal);

export const deleteChapterEvent = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${chapterEventsUrl}/${id}`, signal);

export const deleteChapterEvents = (
  ids: number[],
  signal: AbortSignal
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(chapterEventsUrl, ids, signal);

export const getChapterEvent = async (id: number, signal: AbortSignal): Promise<Result<ChapterEventFull | null>> =>
  getItem<ChapterEventFull>(`${chapterEventsUrl}/${id}`, signal);

export const getChapterEvents = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<ChapterEventFull> | null>> =>
  getItems<ChapterEventFull>(chapterEventsUrl, pagination, signal);

export const getChapterEventFilters = (
  fieldName: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(chapterEventsUrl, fieldName, signal);
