const Users = {
  InternalName: "users",
  Title: "Benutzer",
};

const UserMemberPermissions = {
  InternalName: "user_member_permissions",
  Title: "Berechtigungen Mitglieder",
};

const UserConventionPermissions = {
  InternalName: "user_convention_permissions",
  Title: "Berechtigungen Konventionen",
};

const UserFunctionaryPermissions = {
  InternalName: "user_functionary_permissions",
  Title: "Berechtigungen Funktionäre",
};

export const Api = {
  InternalName: "api",
  Title: "Schnittstelle",
  Lists: {
    UserMemberPermissions: UserMemberPermissions,
    UserConventionPermissions: UserConventionPermissions,
    UserFunctionaryPermissions: UserFunctionaryPermissions,
    Users: Users,
  },
};
