import Link from "../../../components/Link";
import { IDialogField, IListviewField } from "../../../services/types";
import { getCurrentUrlAsSource } from "../../../services/utils";
import { ChapterEventCategoryFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<ChapterEventCategoryFull> & IDialogField<ChapterEventCategoryFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: ChapterEventCategoryFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
  renderDialogValue: (item: ChapterEventCategoryFull) => item.name,
};
