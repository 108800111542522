import Link from "../../../components/Link";
import { IListviewField } from "../../../services/types";
import { formatBoolean, getCurrentUrlAsSource } from "../../../services/utils";
import { ConventionFull } from "./types";
import { dispForm } from "./urls";

export const title: IListviewField<ConventionFull> = {
  title: "Titel",
  fieldName: "title",
  isSortable: true,
  renderListValue: (item: ConventionFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.title}</Link>
  ),
};

export const description: IListviewField<ConventionFull> = {
  title: "Beschreibung",
  fieldName: "description",
  isSortable: true,
  renderListValue: (item: ConventionFull) => item.description,
};

export const partner: IListviewField<ConventionFull> = {
  title: "Partner",
  fieldName: "partner.name",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: ConventionFull) => item.partner?.name,
};

export const category: IListviewField<ConventionFull> = {
  title: "Kategorie",
  fieldName: "category.name",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: ConventionFull) => item.category.name,
};

export const online: IListviewField<ConventionFull> = {
  title: "Online",
  fieldName: "online",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: ConventionFull) => formatBoolean(item.online),
};
