import { FC, useEffect, useState } from "react";
import Button from "../../../components/Button";
import { DialogBorder } from "../../../components/dialogs/DialogBorder";
import { DialogCloseIcon } from "../../../components/dialogs/DialogCloseIcon";
import { DialogContainer } from "../../../components/dialogs/DialogContainer";
import { Draggable } from "../../../components/dialogs/Draggable";
import Modal from "../../../components/dialogs/Modal";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import { ValidationErrors } from "../../../services/types";
import NewEditTable from "./NewEditTable";
import { mapToPOST, SchemaUserPermissionForm, SchemaUserPermissionFull, validate } from "./types";
import { updateSchemaUserPermission } from "./webapi";

interface EditDialogForm {
  item: SchemaUserPermissionFull;
  isOpen: boolean;
  onCancel: () => void;
  onSave: () => void;
}

const EditDialogForm: FC<EditDialogForm> = ({ item, isOpen, onCancel, onSave }: EditDialogForm) => {
  const [abortController, resetAbortController] = useAbortController();
  const [form, setForm] = useState<SchemaUserPermissionForm | undefined>(item);
  const [errors, setErrors] = useState<ValidationErrors>({});

  useEffect(() => {
    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const save = async () => {
    if (item && form && validate(form, setErrors)) {
      const abortController = resetAbortController();
      await updateSchemaUserPermission(item.id, mapToPOST(form), abortController.signal);
      onSave();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onCancel()}>
      <Draggable>
        <DialogContainer>
          <DialogCloseIcon onClose={() => onCancel()} />
          <DialogBorder>
            <Title text="Berechtigung bearbeiten" />
            {form && <NewEditTable state={form} setState={setForm} errors={errors} />}
            <Button onclick={save} disabled={!form}>
              Speichern
            </Button>
          </DialogBorder>
        </DialogContainer>
      </Draggable>
    </Modal>
  );
};

export default EditDialogForm;
