import Link from "../../../components/Link";
import { IDialogField, IListviewField } from "../../../services/types";
import { formatBoolean, getCurrentUrlAsSource } from "../../../services/utils";
import { dispUrl, ListFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<ListFull> & IDialogField<ListFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: ListFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
  renderDialogValue: (item: ListFull) => item.name,
};

export const internalName: IListviewField<ListFull> & IDialogField<ListFull> = {
  title: "Interner-Name",
  fieldName: "internalName",
  isSortable: true,
  renderListValue: (item: ListFull) => item.internalName,
  renderDialogValue: (item: ListFull) => item.internalName,
};

export const inheritPermissions: IListviewField<ListFull> & IDialogField<ListFull> = {
  title: "Berechtigungen erben",
  fieldName: "inheritPermissions",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: ListFull) => formatBoolean(item.inheritPermissions),
  renderDialogValue: (item: ListFull) => formatBoolean(item.inheritPermissions),
};

export const url: IListviewField<ListFull> = {
  title: "Url",
  fieldName: "url",
  renderListValue: (item: ListFull) => dispUrl(item),
};

export const schema: IListviewField<ListFull> & IDialogField<ListFull> = {
  title: "Schema",
  fieldName: "schema.name",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: ListFull) => item.schema?.name,
  renderDialogValue: (item: ListFull) => item.schema?.name,
};
