import Email from "../../../components/Email";
import Link from "../../../components/Link";
import Number from "../../../components/Number";
import DispPhoneNumber from "../../../components/phoneNumber/DispPhoneNumber";
import { IDialogField, IListviewField, MenuOptionValue } from "../../../services/types";
import { formatBoolean, getCurrentUrlAsSource } from "../../../services/utils";
import { isMember as isMember_, Member, renderCompanyType, renderStatus, renderType } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<Member> & IDialogField<Member> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: Member) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
  renderDialogValue: (item: Member) => item.name,
};

export const memberNumber: IListviewField<Member> & IDialogField<Member> = {
  title: "MG-Nummer",
  fieldName: "id",
  isSortable: true,
  renderListValue: (item: Member) => <Number value={item.id} />,
  renderDialogValue: (item: Member) => item.id,
};

export const type: IListviewField<Member> = {
  title: "Art",
  fieldName: "type",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: Member) => renderType(item.type),
  renderMenuOptionValue: (value: MenuOptionValue) => renderType(value as string) || "(Leer)",
};

export const companyType: IListviewField<Member> = {
  title: "Firmentyp",
  fieldName: "companyType",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: Member) => renderCompanyType(item.companyType),
  renderMenuOptionValue: (value: MenuOptionValue) => renderCompanyType(value as string) || "(Leer)",
};

export const status: IListviewField<Member> = {
  title: "Status",
  fieldName: "status",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: Member) => renderStatus(item.status),
  renderMenuOptionValue: (value: MenuOptionValue) => renderStatus(value as string) || "(Leer)",
};

export const isMember: IListviewField<Member> = {
  title: "Ist HGV Mitglied",
  fieldName: "isMember",
  isSortable: true,
  isFilterable: true,
  renderListValue: (item: Member) => formatBoolean(isMember_(item)),
};

export const city: IListviewField<Member> = {
  title: "Ortschaft",
  fieldName: "address.city",
  isSortable: true,
  renderListValue: (item: Member) => item.address.city,
};

export const street: IListviewField<Member> = {
  title: "Straße",
  fieldName: "address.street",
  isSortable: true,
  renderListValue: (item: Member) => item.address.street,
};

export const email: IListviewField<Member> = {
  title: "E-Mail",
  fieldName: "email",
  isSortable: true,
  renderListValue: (item: Member) => <Email value={item.email} />,
};

export const phone: IListviewField<Member> = {
  title: "Telefon",
  fieldName: "phone",
  isSortable: true,
  renderListValue: (item: Member) => <DispPhoneNumber value={item.phone} />,
};
