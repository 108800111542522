const Communes = {
  InternalName: "communes",
  Title: "Gemeinden",
};

const Countries = {
  InternalName: "countries",
  Title: "Länder",
};

const CurrentNavigationItems = {
  InternalName: "current_navigation_items",
  Title: "Einträge aktuelles Menü",
};

const Departments = {
  InternalName: "departments",
  Title: "Abteilungen",
};
const GlobalNavigationItems = {
  InternalName: "global_navigation_items",
  Title: "Einträge globales Menü",
};
const Groups = {
  InternalName: "groups",
  Title: "Gruppen",
};

const ListUserPermissions = {
  InternalName: "list_user_permissions",
  Title: "Listen Benutzer-Berechtigungen",
};

const ListGroupPermissions = {
  InternalName: "list_group_permissions",
  Title: "Gruppen Benutzer-Berechtigungen",
};

const Lists = {
  InternalName: "lists",
  Title: "Listen",
};
const Views = {
  InternalName: "views",
  Title: "Ansichten",
};

const ViewUserPermissions = {
  InternalName: "view_user_permissions",
  Title: "Ansichten Benutzer-Berechtigungen",
};

const ViewGroupPermissions = {
  InternalName: "view_group_permissions",
  Title: "Ansichten Gruppen-Berechtigungen",
};
const Offices = {
  InternalName: "offices",
  Title: "Büros",
};
const Permissions = {
  InternalName: "permissions",
  Title: "Berechtigungen",
};

const Schemas = {
  InternalName: "schemas",
  Title: "Schemas",
};

const SchemaUserPermissions = {
  InternalName: "schema_user_permissions",
  Title: "Schemas Benutzer-Berechtigungen",
};

const SchemaGroupPermissions = {
  InternalName: "schema_group_permissions",
  Title: "Schemas Gruppen-Berechtigungen",
};

const Users = {
  InternalName: "users",
  Title: "Benutzer",
};

export const Administration = {
  InternalName: "administration",
  Title: "Administration",
  Lists: {
    Communes: Communes,
    Countries: Countries,
    CurrentNavigationItems: CurrentNavigationItems,
    Departments: Departments,
    GlobalNavigationItems: GlobalNavigationItems,
    Groups: Groups,
    ListGroupPermissions: ListGroupPermissions,
    Lists: Lists,
    ListUserPermissions: ListUserPermissions,
    Offices: Offices,
    Permissions: Permissions,
    SchemaGroupPermissions: SchemaGroupPermissions,
    Schemas: Schemas,
    SchemaUserPermissions: SchemaUserPermissions,
    Users: Users,
    ViewGroupPermissions: ViewGroupPermissions,
    Views: Views,
    ViewUserPermissions: ViewUserPermissions,
  },
};
