import { FC } from "react";
import styles from "./DataDiv.module.css";

interface DataDivProps {
  children: React.ReactNode;
}

const DataDiv: FC<DataDivProps> = ({ children }: DataDivProps) => <div className={styles.padding}>{children}</div>;

export default DataDiv;
