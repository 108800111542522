import classNames, { Argument } from "classnames";
import { FC, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { MenuItem } from "../../../services/types";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import ArrowDown from "../../../svg/ArrowDown.svg?react";
import ArrowUp from "../../../svg/ArrowUp.svg?react";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import IndentDecrease from "../../../svg/IndentDecrease.svg?react";
import IndentIncrease from "../../../svg/IndentIncrease.svg?react";
import Restore from "../../../svg/Restore.svg?react";
import Modal from "./../../dialogs/Modal";
import styles from "./EditableMenu.module.css";
import CurrentNavigationItem from "./NavigationItem";
import NewEditMenuItem from "./NewEditMenuItem";

interface EditableMenuProps {
  menuItems: MenuItem[];
  updateMenu: (menuItems: MenuItem[]) => void;
  isEditMode: boolean;
}

const getTarget = (openNewTab: boolean): string => (openNewTab ? "_blank" : "_self");

const EditableMenu: FC<EditableMenuProps> = ({ menuItems, updateMenu, isEditMode }: EditableMenuProps) => {
  const [selectedItem, setSelectedItem] = useState<MenuItem | undefined>();
  const [addEditItemOptions, setAddEditItemOptions] = useState<{ show: boolean; addEdit?: "new" | "edit" }>({
    show: false,
  });

  const cloneItems = (selectedItem: MenuItem, menuItems: MenuItem[]): [MenuItem[], MenuItem | undefined] => {
    const cloned: { newItems: MenuItem[]; newSelectedItem?: MenuItem } = menuItems.reduce(
      (rv: { newItems: MenuItem[]; newSelectedItem?: MenuItem }, x) => {
        const clone: MenuItem = { ...x, children: undefined };
        if (x.children) {
          const [children, newSel] = cloneItems(selectedItem, x.children);
          clone.children = children;
          if (newSel) {
            rv.newSelectedItem = newSel;
          }
        }
        rv.newItems.push(clone);
        if (x === selectedItem) {
          rv.newSelectedItem = clone;
        }
        return rv;
      },
      { newItems: [] }
    );

    return [cloned.newItems, cloned.newSelectedItem];
  };

  const getParent = (items: MenuItem[], currentItem: MenuItem): MenuItem | null =>
    items.reduce((rv1: MenuItem | null, item: MenuItem) => {
      const result = item.children?.reduce((rv2: MenuItem | null, child: MenuItem) => {
        return currentItem === child ? item : rv2;
      }, null);

      if (item.children && item.children.length > 0) {
        const parent = getParent(item.children, currentItem);
        if (parent) {
          return parent;
        }
      }

      return result ?? rv1;
    }, null);

  const getPreviousSibling = (items: MenuItem[], item: MenuItem): MenuItem | null => {
    const parent = getParent(items, item);
    const children = parent?.children ?? items;
    const index = children.indexOf(item);
    return children[index - 1] ?? null;
  };

  const getNextSibling = (items: MenuItem[], item: MenuItem): MenuItem | null => {
    const parent = getParent(items, item);
    const children = parent?.children ?? items;
    const index = children.indexOf(item);
    return children[index + 1] ?? null;
  };

  const getLastItem = (items: MenuItem[]): MenuItem | null => {
    const [lastItem] = items.slice(-1);
    const lastChildren = lastItem?.children && getLastItem(lastItem?.children);
    return lastChildren ?? lastItem ?? null;
  };

  const moveItemUp = (items: MenuItem[], item: MenuItem) => {
    const [newItems, newSelectedItem] = cloneItems(item, items);
    if (newSelectedItem) {
      const parent = getParent(newItems, newSelectedItem);
      const children = parent?.children ?? newItems;
      const previousSibling = getPreviousSibling(newItems, newSelectedItem);
      if (previousSibling) {
        const index = children.indexOf(newSelectedItem);
        children[index] = previousSibling;
        children[index - 1] = newSelectedItem;
      } else if (children[0] === newSelectedItem) {
        children.splice(0, 1);
        const parentsParent = parent && getParent(newItems, parent);
        const parentsChildren = parentsParent?.children ?? newItems;
        if (parent) {
          const index = parentsChildren.indexOf(parent);
          parentsChildren.splice(index, 0, newSelectedItem);
        }
      }
      setSelectedItem(newSelectedItem);
      updateMenu(newItems);
    }
  };

  const moveItemDown = (items: MenuItem[], item: MenuItem) => {
    const [newItems, newSelectedItem] = cloneItems(item, items);
    if (newSelectedItem) {
      const parent = getParent(newItems, newSelectedItem);
      const children = parent?.children ?? newItems;
      const nextSibling = getNextSibling(newItems, newSelectedItem);
      if (nextSibling) {
        const childrenNextSibling = nextSibling.children;
        const index = children.indexOf(newSelectedItem);
        if (childrenNextSibling && childrenNextSibling.length > 0) {
          children.splice(index, 1);
          nextSibling.children = [newSelectedItem, ...childrenNextSibling];
        } else {
          children[index] = nextSibling;
          children[index + 1] = newSelectedItem;
        }
        setSelectedItem(newSelectedItem);
        updateMenu(newItems);
      }
    }
  };

  const increaseIndent = (items: MenuItem[], item: MenuItem) => {
    const [newItems, newSelectedItem] = cloneItems(item, items);
    if (newSelectedItem) {
      const parent = getParent(newItems, newSelectedItem);
      const parentChildren = parent?.children ?? newItems;
      const previousSibling = getPreviousSibling(newItems, newSelectedItem);
      if (previousSibling) {
        const index = parentChildren.indexOf(newSelectedItem);
        parentChildren.splice(index, 1);
        (previousSibling.children = previousSibling.children || []).push(newSelectedItem);
      }
      setSelectedItem(newSelectedItem);
      updateMenu(newItems);
    }
  };

  const decreaseIndent = (items: MenuItem[], item: MenuItem) => {
    const [newItems, newSelectedItem] = cloneItems(item, items);
    if (newSelectedItem) {
      const parent = getParent(newItems, newSelectedItem);
      if (parent) {
        const parentChildren = parent?.children ?? newItems;
        const indexItem = parentChildren.indexOf(newSelectedItem);
        parentChildren.splice(indexItem, 1);
        (newSelectedItem.children = newSelectedItem.children || []).push(...parentChildren.splice(indexItem));

        const parentsParent = parent && getParent(newItems, parent);
        const parentsChildren = parentsParent?.children ?? newItems;
        const indexParent = parentsChildren.indexOf(parent);
        parentsChildren.splice(indexParent + 1, 0, newSelectedItem);

        setSelectedItem(newSelectedItem);
        updateMenu(newItems);
      }
    }
  };

  const setDeletedFlag = (item: MenuItem, deleted?: boolean) => {
    item.deleted = deleted;
    item.children?.forEach((x) => {
      setDeletedFlag(x, deleted);
      x.deleted = deleted;
    });
  };

  const deleteItem = (items: MenuItem[], item: MenuItem) => {
    const [newArray, newSelectedItem] = cloneItems(item, items);
    if (newSelectedItem) {
      setDeletedFlag(newSelectedItem, true);
      setSelectedItem(newSelectedItem);
      updateMenu(newArray);
    }
  };

  const restoreItem = (items: MenuItem[], item: MenuItem) => {
    const [newArray, newSelectedItem] = cloneItems(item, items);
    if (newSelectedItem) {
      setDeletedFlag(newSelectedItem);
      setSelectedItem(newSelectedItem);
      updateMenu(newArray);
    }
  };

  const addNewItem = (items: MenuItem[], item: MenuItem) => {
    const newSelectedItem = { ...item };
    const newArray = [...items, newSelectedItem];
    setSelectedItem(newSelectedItem);
    updateMenu(newArray);

    setAddEditItemOptions({
      show: false,
      addEdit: undefined,
    });
  };

  const updateItem = (items: MenuItem[], item: MenuItem) => {
    if (selectedItem) {
      const [newArray, newSelectedItem] = cloneItems(selectedItem, items);

      if (newSelectedItem) {
        newSelectedItem.title = item.title;
        newSelectedItem.url = item.url;
        newSelectedItem.openNewTab = item.openNewTab;
      }
      setSelectedItem(newSelectedItem);
      updateMenu(newArray);
      setAddEditItemOptions({
        show: false,
        addEdit: undefined,
      });
    }
  };

  const closeNewEditDialog = () =>
    setAddEditItemOptions({
      show: false,
      addEdit: undefined,
    });

  const renderItemInEditMode = (item: MenuItem, level: number): JSX.Element => (
    <div
      className={classNames(styles["nav-item"], styles["menu-item"], {
        [styles.selected]: selectedItem === item,
        [styles.deleted]: item.deleted,
      })}
      onClick={() => {
        updateMenu(menuItems);
        setSelectedItem(item);
      }}
    >
      <span style={{ paddingLeft: level * 20 }}>{item.title}</span>
    </div>
  );

  const renderItemsInEditMode = (item: MenuItem, index: number, level: number = 0): JSX.Element => (
    <>
      {renderItemInEditMode(item, level)}
      {item.children?.map((c, i) => (
        <Fragment key={`menu_item_${index}_${i}`}>{renderItemsInEditMode(c, index, level + 1)}</Fragment>
      ))}
    </>
  );

  const renderItemInDispMode = (item: MenuItem, level: number): JSX.Element => {
    const target = getTarget(item.openNewTab);
    const classes: Argument[] = [styles["nav-item"], { [styles["menu-item"]]: item.url }];

    const url = item.url && !item.url.startsWith("http") ? item.url : undefined;
    const element = <CurrentNavigationItem classNamesArgs={classes} indentLevel={level} title={item.title} url={url} />;

    return item.url ? (
      item.url.startsWith("http") ? (
        <a href={item.url} target={target}>
          {element}
        </a>
      ) : (
        <Link to={item.url} target={target}>
          {element}
        </Link>
      )
    ) : (
      element
    );
  };

  const renderItemsInDispMode = (item: MenuItem, index: number, level: number = 0): JSX.Element => (
    <>
      {renderItemInDispMode(item, level)}
      {item.children?.map((c, i) => (
        <Fragment key={`menu_item_${index}_${i}`}>{renderItemsInDispMode(c, index, level + 1)}</Fragment>
      ))}
    </>
  );

  return (
    <>
      {isEditMode && (
        <div className={styles.controls}>
          <div
            title={"Nach oben verschieben"}
            className={classNames({ [styles.disabled]: !selectedItem || selectedItem === menuItems[0] })}
          >
            <div onClick={() => selectedItem && moveItemUp(menuItems, selectedItem)}>
              <ArrowUp />
            </div>
          </div>
          <div
            title={"Nach unten verschieben"}
            className={classNames({
              [styles.disabled]:
                !selectedItem || selectedItem === getLastItem(menuItems) || selectedItem === menuItems.slice(-1)[0],
            })}
          >
            <div onClick={() => selectedItem && moveItemDown(menuItems, selectedItem)}>
              <ArrowDown />
            </div>
          </div>
          <div
            title={"Einzug vergrößern"}
            className={classNames({
              [styles.disabled]: !selectedItem || !getPreviousSibling(menuItems, selectedItem),
            })}
          >
            <div onClick={() => selectedItem && increaseIndent(menuItems, selectedItem)}>
              <IndentIncrease />
            </div>
          </div>
          <div
            title={"Einzug verkleinern"}
            className={classNames({
              [styles.disabled]: !selectedItem || !getParent(menuItems, selectedItem),
            })}
          >
            <div onClick={() => selectedItem && decreaseIndent(menuItems, selectedItem)}>
              <IndentDecrease />
            </div>
          </div>
          <div title={strings.add}>
            <div
              onClick={() =>
                setAddEditItemOptions({
                  show: true,
                  addEdit: "new",
                })
              }
            >
              <Add />
            </div>
          </div>
          <div title={strings.edit} className={classNames({ [styles.disabled]: !selectedItem })}>
            <div
              onClick={() =>
                selectedItem &&
                setAddEditItemOptions({
                  show: true,
                  addEdit: "edit",
                })
              }
            >
              <Edit />
            </div>
          </div>
          {!selectedItem?.deleted && (
            <div title={strings.delete} className={classNames({ [styles.disabled]: !selectedItem })}>
              <div onClick={() => selectedItem && deleteItem(menuItems, selectedItem)}>
                <Bin />
              </div>
            </div>
          )}
          {selectedItem?.deleted && (
            <div title={strings.restore}>
              <div onClick={() => selectedItem && restoreItem(menuItems, selectedItem)}>
                <Restore />
              </div>
            </div>
          )}
        </div>
      )}

      {menuItems.map((item: MenuItem, itemIndex: number) => {
        return (
          <Fragment key={`menu_item_${itemIndex}_0`}>
            {isEditMode ? renderItemsInEditMode(item, itemIndex) : renderItemsInDispMode(item, itemIndex)}
          </Fragment>
        );
      })}

      {addEditItemOptions.show && (
        <Modal isOpen={addEditItemOptions.show} onClose={closeNewEditDialog}>
          <NewEditMenuItem
            onAddOrUpdate={(item: MenuItem) => {
              (addEditItemOptions.addEdit === "new" ? addNewItem : updateItem)(menuItems, item);
            }}
            onCancel={closeNewEditDialog}
            item={addEditItemOptions.addEdit === "edit" ? selectedItem : undefined}
          />
        </Modal>
      )}
    </>
  );
};

export default EditableMenu;
