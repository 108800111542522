import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Actions from "../../../components/controls/Actions";
import Errors, { getString } from "../../../components/errors/Errors";
import CreatedModifiedInfo from "../../../components/forms/CreatedModifiedInfo";
import FormData from "../../../components/forms/FormData";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import ReferencedItemsView from "../../../components/listview/ReferencedItemsView";
import Title from "../../../components/Title";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { Functionaries } from "../../../listsSettings/functionaries";
import { ErrorCode, IAction, SearchResult, UserPermissions } from "../../../services/types";
import { getCloseAction, getCurrentUrlAsSource, getSelectedItems, getSourceFromUrl } from "../../../services/utils";
import { getUserPermissions } from "../../../services/webapi";
import strings from "../../../strings";
import Add from "../../../svg/Add.svg?react";
import Bin from "../../../svg/Bin.svg?react";
import Edit from "../../../svg/Edit.svg?react";
import * as AreaFields from "../areas/Fields";
import { AreaFull } from "../areas/types";
import { editForm as editFormAreas, newForm as newFormAreas } from "../areas/urls";
import { deleteAreas } from "../areas/webapi";
import * as Fields from "./Fields";
import { DistrictFull } from "./types";
import { allItems, editForm } from "./urls";
import { deleteDistrict, getDistrict, getDistrictAreas, getDistrictAreasFilters } from "./webapi";

const DispForm: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const [abortController, resetAbortController] = useAbortController();
  const [state, setState] = useState<DistrictFull>();
  const [areas, setAreas] = useState<SearchResult<AreaFull> | null>(null);
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [areaPermissions, setAreaPermissions] = useState<UserPermissions>();
  const statusBar = useStatusBarState();
  const navigateTo = useNavigate();
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    id && initializeState(Number(id));

    return () => {
      abortController && abortController.abort();
    };
  }, [id]);

  const initializeState = async (id: number) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Functionaries.Lists.Districts.InternalName,
      Functionaries.InternalName,
      abortController.signal
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const [areaPermissions, result] = await Promise.all([
          getUserPermissions(
            Functionaries.Lists.Areas.InternalName,
            Functionaries.InternalName,
            abortController.signal
          ),
          getDistrict(id, abortController.signal),
        ]);
        if (!abortController.signal.aborted) {
          result.error && setErrorCode(result.error.code);

          if (result.data) {
            setAreaPermissions(areaPermissions);
            setState(result.data);
            loadAreas(result.data.id, pagination, abortController);
          }
        }
      }
    }
  };

  const onDelete = async () => {
    if (state && confirm(strings.confirmDeleteItem)) {
      const abortController = resetAbortController();
      const error = await deleteDistrict(state.id, abortController.signal);
      if (!abortController.signal.aborted) {
        error && statusBar.addError(getString(error));
        !error && navigateTo(getSourceFromUrl() ?? allItems);
      }
    }
  };

  const listviewId = "district-areas";

  const pagination = getPagination(listviewId) || {
    ...DefaultPagination,
  };

  const loadAreas = async (areaId: number, pagination: Pagination, abortController: AbortController) => {
    const result = await getDistrictAreas(areaId, pagination, abortController.signal);
    if (!abortController.signal.aborted) {
      result.error && statusBar.addError(getString(result.error));
      result.data && setAreas(result.data);
    }
  };
  const editAction: IAction = {
    title: strings.edit,
    icon: Edit,

    onClick: () => state && navigateTo(`${editForm}/${state.id}?${getCurrentUrlAsSource()}`),
    disabled: !permissions || !permissions.listPermissions.includes("edit-all"),
  };
  const closeAction: IAction = getCloseAction(navigateTo, allItems);
  const deleteAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: onDelete,
    disabled: !permissions || !permissions.listPermissions.includes("delete-all"),
  };

  const addAreaAction: IAction = {
    title: strings.new,
    icon: Add,
    onClick: () => navigateTo(`${newFormAreas}?districtId=${state?.id}&${getCurrentUrlAsSource()}`),
    disabled:
      !areaPermissions?.listPermissions.includes("create") || !permissions?.listPermissions.includes("read-all"),
  };

  const editAreaAction: IAction = {
    title: strings.edit,
    icon: Edit,
    onClick: () => {
      const selectedItem = getSelectedItems(areas)[0];
      selectedItem && navigateTo(getSourceFromUrl() ?? `${editFormAreas}/${selectedItem.id}`);
    },
    disabled: getSelectedItems(areas).length !== 1 || !permissions?.listPermissions.includes("edit-all"),
  };

  const deleteAreaAction: IAction = {
    title: strings.delete,
    icon: Bin,
    onClick: async () => {
      if (state) {
        const selectedItems = getSelectedItems(areas);
        if (selectedItems.length > 0 && confirm(strings.confirmDeleteItems)) {
          const abortController = resetAbortController();
          const result = await deleteAreas(
            selectedItems.map((x) => x.id),
            abortController.signal
          );
          if (!abortController.signal.aborted) {
            if (result.error) {
              statusBar.addError(getString(result.error));
            } else if (result.data) {
              const s = selectedItems.filter((x) => x.id && result.data?.[x.id]);

              if (s.length != selectedItems.length) {
                statusBar.addWarning(strings.notAllElementsDeleted);
              }
              if (s.length > 0) {
                await loadAreas(state.id, pagination, abortController);
              }
            }
          }
        }
      }
    },
    disabled: getSelectedItems(areas).length === 0 || !permissions?.listPermissions.includes("delete-all"),
  };

  const actions = [addAreaAction, editAreaAction, deleteAreaAction];

  useEffect(() => {
    if (state) {
      setListName(Functionaries.Lists.Districts.Title);
      setPage(state.name);
    }
  }, [state]);

  return (
    <>
      {!errorCode && state && (
        <>
          <Title text={"Bezirk"} />
          <Actions actions={[editAction, closeAction, deleteAction]} />
          <FormTable>
            <FormTr>
              <FormLabel>{Fields.name.title}</FormLabel>
              <FormData disp>{state.name}</FormData>
            </FormTr>
            <FormTr>
              <FormLabel>{Fields.order.title}</FormLabel>
              <FormData disp>{state.order}</FormData>
            </FormTr>
          </FormTable>
          <CreatedModifiedInfo info={state} />

          <ReferencedItemsView
            itemId={state.id}
            id={listviewId}
            actions={actions}
            title={Functionaries.Lists.Areas.Title}
            fields={[AreaFields.name]}
            allowSelectRows
            reload={(districtId, pagination) => {
              const abortController = resetAbortController();
              return loadAreas(districtId, pagination, abortController);
            }}
            searchResult={areas}
            getFilters={getDistrictAreasFilters}
            pagination={pagination}
            updateItems={(items) =>
              areas &&
              setAreas({
                ...areas,
                items: items,
              })
            }
          />
        </>
      )}
      {errorCode && <Errors errorCode={errorCode} />}
    </>
  );
};

export default DispForm;
