import { FC, PropsWithChildren, useRef } from "react";
import { Position } from "./types";

interface MoveWrapperProps {
  className?: string;
  style?: React.CSSProperties;
  onChange: (position: Position) => void;
}

const MoveWrapper: FC<PropsWithChildren<MoveWrapperProps>> = ({ className, style, onChange, children }) => {
  const divRef = useRef<HTMLDivElement>(null);

  const clamp = (value: number, max: number, min: number) => (value > max ? max : value < min ? min : value);

  const move = (e: React.MouseEvent | MouseEvent): void => {
    if (divRef.current) {
      const { current: div } = divRef;
      const { width, height, left, top } = div.getBoundingClientRect();

      const x = clamp(e.clientX - left, width, 0);
      const y = clamp(e.clientY - top, height, 0);

      onChange({ x, y });
    }
  };

  const onMouseDown = (e: React.MouseEvent): void => {
    if (e.button !== 0) return;

    move(e);

    const onMouseMove = (e: MouseEvent): void => {
      move(e);
    };

    const onMouseUp = (e: MouseEvent): void => {
      document.removeEventListener("mousemove", onMouseMove, false);
      document.removeEventListener("mouseup", onMouseUp, false);

      move(e);
    };

    document.addEventListener("mousemove", onMouseMove, false);
    document.addEventListener("mouseup", onMouseUp, false);
  };

  return (
    <div ref={divRef} className={className} style={style} onMouseDown={onMouseDown}>
      {children}
    </div>
  );
};

export default MoveWrapper;
