import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Functionaries } from "../../../listsSettings/functionaries";
import { FileInfo, MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  deleteItem,
  deleteItems,
  exportExcel,
  functionaries,
  getFilters,
  getItem,
  getItems,
  insertItem,
  insertItems,
  updateItem,
} from "../../../services/webapi";
import { FunctionaryFull, FunctionaryPost, OrganizationChart } from "./types";

const functionariesUrl: string = `${functionaries}/${Functionaries.Lists.Functionaries.InternalName}`;
export const insertFunctionary = async (
  functionary: FunctionaryPost,
  signal: AbortSignal
): Promise<Result<FunctionaryFull | null>> => insertItem(functionariesUrl, functionary, signal);

export const insertFunctionaries = async (
  functionaries: FunctionaryPost[],
  signal: AbortSignal
): Promise<Result<boolean | null>> => insertItems(functionariesUrl, functionaries, signal);

export const updateFunctionary = async (
  functionaryId: number,
  functionary: FunctionaryPost,
  signal: AbortSignal
): Promise<Result<FunctionaryFull | null>> => updateItem(`${functionariesUrl}/${functionaryId}`, functionary, signal);

export const deleteFunctionary = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${functionariesUrl}/${id}`, signal);

export const deleteFunctionaries = (
  ids: number[],
  signal: AbortSignal
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(functionariesUrl, ids, signal);

export const getFunctionary = async (id: number, signal: AbortSignal): Promise<Result<FunctionaryFull | null>> =>
  getItem<FunctionaryFull>(`${functionariesUrl}/${id}`, signal);

export const getFunctionaries = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<FunctionaryFull> | null>> =>
  getItems<FunctionaryFull>(functionariesUrl, pagination, signal);

export const exportFunctionariesExcel = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<FileInfo | null>> => exportExcel(functionariesUrl, pagination, signal);

export const getFunctionaryFilters = (
  fieldName: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(functionariesUrl, fieldName, signal);

export const getOrganizationChart = (signal: AbortSignal): Promise<Result<OrganizationChart | null>> =>
  getItem<OrganizationChart>(`${functionariesUrl}/organization_chart`, signal);
