import { TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { $getNearestBlockElementAncestorOrThrow } from "@lexical/utils";
import classNames from "classnames";
import {
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  TextFormatType,
} from "lexical";
import { FC, Fragment, useCallback } from "react";
import { TextFormatButtonType } from "../types";
import DropDownItem from "./DropDownItem";
import styles from "./TextFormattingTools.module.css";

export interface TextFormattingToolsProps {
  editor: LexicalEditor;
  options?: TextFormatButtonType[];
  isBold: boolean;
  isCode: boolean;
  isItalic: boolean;
  isLink: boolean;
  isStrikethrough: boolean;
  isSubscript: boolean;
  isSuperscript: boolean;
  isUnderline: boolean;
  isDropDown?: boolean;
}

interface ITextFormattingToolsButton {
  title: string;
  type: TextFormatButtonType;
  onClick: () => void;
  iconClassName: string;
  isActive?: boolean;
}

const TextFormattingTools: FC<TextFormattingToolsProps> = ({
  editor,
  options,
  isLink,
  isBold,
  isItalic,
  isUnderline,
  isCode,
  isStrikethrough,
  isSubscript,
  isSuperscript,
  isDropDown,
}) => {
  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const clearFormatting = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        selection.getNodes().forEach((node) => {
          if ($isTextNode(node)) {
            node.setFormat(0);
            node.setStyle("");
            $getNearestBlockElementAncestorOrThrow(node).setFormat("");
          }
          if ($isDecoratorBlockNode(node)) {
            node.setFormat("");
          }
        });
      }
    });
  }, [editor]);

  const dispatchCommand = (type: TextFormatType) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, type);
  };

  const buttons: ITextFormattingToolsButton[] = [
    {
      onClick: () => dispatchCommand("bold"),
      isActive: isBold,
      iconClassName: styles.bold,
      title: "Fett",
      type: "bold",
    },
    {
      onClick: () => dispatchCommand("italic"),
      isActive: isItalic,
      iconClassName: styles.italic,
      title: "Kursiv",
      type: "italic",
    },
    {
      onClick: () => dispatchCommand("underline"),
      isActive: isUnderline,
      iconClassName: styles.underline,
      title: "Unterstrichen",
      type: "underline",
    },
    {
      onClick: () => dispatchCommand("strikethrough"),
      isActive: isStrikethrough,
      iconClassName: styles.strikethrough,
      title: "Durchgestrichen",
      type: "strikethrough",
    },
    {
      onClick: () => dispatchCommand("subscript"),
      isActive: isSubscript,
      iconClassName: styles.subscript,
      title: "Tiefgestellt",
      type: "subscript",
    },
    {
      onClick: () => dispatchCommand("superscript"),
      isActive: isSuperscript,
      iconClassName: styles.superscript,
      title: "Hochgestellt",
      type: "superscript",
    },
    {
      onClick: () => dispatchCommand("code"),
      isActive: isCode,
      iconClassName: styles.code,
      title: "Code",
      type: "code",
    },
    {
      onClick: insertLink,
      isActive: isLink,
      iconClassName: styles.link,
      title: "Link",
      type: "link",
    },
    {
      onClick: clearFormatting,
      iconClassName: styles.clear,
      title: "Formattierung löschen",
      type: "clear",
    },
  ];

  const renderDroopDownItems = () => (
    <>
      {buttons
        .filter((x) => options?.includes(x.type))
        .map((x) => (
          <Fragment key={x.title}>
            <DropDownItem
              onClick={x.onClick}
              className={classNames({ [styles.active]: x.isActive })}
              title={x.title}
              icon={{ className: x.iconClassName, isOpaque: true }}
            ></DropDownItem>
          </Fragment>
        ))}
    </>
  );

  const renderToolbar = () => (
    <>
      {buttons
        .filter((x) => !options?.includes(x.type))
        .map((x) => (
          <Fragment key={x.title}>
            <button
              onClick={x.onClick}
              className={classNames(styles.button, { [styles.active]: x.isActive })}
              title={x.title}
            >
              <i className={classNames(styles.icon, x.iconClassName)} />
            </button>
          </Fragment>
        ))}
    </>
  );

  return isDropDown ? renderDroopDownItems() : renderToolbar();
};

export default TextFormattingTools;
