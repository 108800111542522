import { FC, useEffect, useMemo, useRef, useState } from "react";
import styles from "./ColorPicker.module.css";
import MoveWrapper from "./MoveWrapper";
import { Color, HSV, Position, RGB } from "./types";

interface ColorPickerProps {
  title?: string;
  color: string | null;
  onChange: (color: string | null) => void;
  showRemoveColor?: boolean;
}

const ColorPicker: FC<ColorPickerProps> = ({ title, color, onChange, showRemoveColor }) => {
  const toHex = (value: string): string => {
    if (!value.startsWith("#")) {
      const ctx = document.createElement("canvas").getContext("2d");

      if (!ctx) {
        throw new Error("2d context not supported or canvas already initialized");
      }

      ctx.fillStyle = value;

      return ctx.fillStyle;
    } else if (value.length === 4 || value.length === 5) {
      value = value
        .split("")
        .map((v, i) => (i ? v + v : "#"))
        .join("");

      return value;
    } else if (value.length === 7 || value.length === 9) {
      return value;
    }

    return "#000000";
  };

  const hex2rgb = (hex: string): RGB => {
    const rbgArr = (
      hex
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (_, r, g, b) => "#" + r + r + g + g + b + b)
        .substring(1)
        .match(/.{2}/g) || []
    ).map((x) => parseInt(x, 16));

    return {
      b: rbgArr[2] ?? 0,
      g: rbgArr[1] ?? 0,
      r: rbgArr[0] ?? 0,
    };
  };

  const rgb2hsv = ({ r, g, b }: RGB): HSV => {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const d = max - Math.min(r, g, b);

    const h = d ? (max === r ? (g - b) / d + (g < b ? 6 : 0) : max === g ? 2 + (b - r) / d : 4 + (r - g) / d) * 60 : 0;
    const s = max ? (d / max) * 100 : 0;
    const v = max * 100;

    return { h, s, v };
  };

  const hsv2rgb = ({ h, s, v }: HSV): RGB => {
    s /= 100;
    v /= 100;

    const i = ~~(h / 60);
    const f = h / 60 - i;
    const p = v * (1 - s);
    const q = v * (1 - s * f);
    const t = v * (1 - s * (1 - f));
    const index = i % 6;

    const r = Math.round(([v, q, p, p, t, v][index] ?? 0) * 255);
    const g = Math.round(([t, v, v, q, p, p][index] ?? 0) * 255);
    const b = Math.round(([p, p, t, v, v, q][index] ?? 0) * 255);

    return { b, g, r };
  };

  const rgb2hex = ({ b, g, r }: RGB): string => "#" + [r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("");
  const transformColor = <M extends keyof Color, C extends Color[M]>(format: M, color: C | null): Color | null => {
    if (!color) {
      return null;
    }
    if (format === "hex") {
      const value = color as Color["hex"];

      const hex = toHex(value);
      const rgb = hex2rgb(hex);
      const hsv = rgb2hsv(rgb);
      return { hex, hsv, rgb };
    } else if (format === "rgb") {
      const value = color as Color["rgb"];

      const rgb = value;
      const hex = rgb2hex(rgb);
      const hsv = rgb2hsv(rgb);
      return { hex, hsv, rgb };
    } else if (format === "hsv") {
      const value = color as Color["hsv"];

      const hsv = value;
      const rgb = hsv2rgb(hsv);
      const hex = rgb2hex(rgb);
      return { hex, hsv, rgb };
    } else {
      return null;
    }
  };

  const [selfColor, setSelfColor] = useState<Color | null>(transformColor("hex", color));
  const innerDivRef = useRef(null);

  const WIDTH = 214;
  const HEIGHT = 150;

  const saturationPosition: Position | null = useMemo(
    () =>
      selfColor
        ? {
            x: (selfColor.hsv.s / 100) * WIDTH,
            y: ((100 - selfColor.hsv.v) / 100) * HEIGHT,
          }
        : null,
    [selfColor?.hsv.s, selfColor?.hsv.v]
  );

  const huePosition: { x: number } | null = useMemo(
    () =>
      selfColor
        ? {
            x: (selfColor.hsv.h / 360) * WIDTH,
          }
        : null,
    [selfColor?.hsv]
  );

  const onMoveSaturation = ({ x, y }: Position) => {
    const h = selfColor?.hsv.h;
    const newHsv: HSV = {
      h: h ?? 0,
      s: (x / WIDTH) * 100,
      v: 100 - (y / HEIGHT) * 100,
    };
    const newColor = transformColor("hsv", newHsv);
    setSelfColor(newColor);
  };

  const onMoveHue = ({ x }: Position) => {
    const s = selfColor?.hsv.s;
    const v = selfColor?.hsv.v;
    const newHsv = {
      s: s ?? 0,
      v: v ?? 0,
      h: (x / WIDTH) * 360,
    };
    const newColor = transformColor("hsv", newHsv);

    setSelfColor(newColor);
  };

  useEffect(() => {
    // Check if the dropdown is actually active
    if (innerDivRef.current) {
      onChange(selfColor?.hex ?? null);
    }
  }, [selfColor, onChange]);

  useEffect(() => {
    if (!color) return;
    const newColor = transformColor("hex", color);
    setSelfColor(newColor);
  }, [color]);

  return (
    <div className={styles.wrapper} style={{ width: WIDTH }} ref={innerDivRef}>
      {title && (
        <div className={styles.title}>
          <b>{title}</b>
        </div>
      )}
      <MoveWrapper
        className={styles.saturation}
        style={{ backgroundColor: `hsl(${selfColor?.hsv.h} 100% 50%)` }}
        onChange={onMoveSaturation}
      >
        {selfColor && saturationPosition && (
          <div
            className={styles["saturation-cursor"]}
            style={{
              backgroundColor: selfColor.hex,
              left: saturationPosition.x,
              top: saturationPosition.y,
            }}
          />
        )}
      </MoveWrapper>
      <MoveWrapper className={styles.hue} onChange={onMoveHue}>
        {selfColor && huePosition && (
          <div
            className={styles["hue-cursor"]}
            style={{
              backgroundColor: `hsl(${selfColor.hsv.h} 100% 50%)`,
              left: huePosition.x,
            }}
          />
        )}
      </MoveWrapper>
      {showRemoveColor && (
        <div
          className={styles["remove-color"]}
          onClick={() => {
            setSelfColor(null);
          }}
        >
          Farbe entfernen
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
