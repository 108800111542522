import { $getListDepth, $isListItemNode, $isListNode } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  COMMAND_PRIORITY_HIGH,
  INDENT_CONTENT_COMMAND,
  RangeSelection,
} from "lexical";
import { FC, useEffect } from "react";

interface ListMaxIndentLevelProps {
  maxDepth: number;
}

const ListMaxIndentLevelPlugin: FC<ListMaxIndentLevelProps> = ({ maxDepth }) => {
  const [editor] = useLexicalComposerContext();

  const getElementNodesInSelection = (selection: RangeSelection) => {
    const nodesInSelection = selection.getNodes();

    return nodesInSelection.length === 0
      ? new Set([selection.anchor.getNode().getParentOrThrow(), selection.focus.getNode().getParentOrThrow()])
      : new Set(nodesInSelection.map((n) => ($isElementNode(n) ? n : n.getParentOrThrow())));
  };

  const isIndentPermitted = (maxDepth: number) => {
    const selection = $getSelection();

    if (!$isRangeSelection(selection)) {
      return false;
    }

    const elementNodesInSelection = getElementNodesInSelection(selection);

    let totalDepth = 0;

    for (const elementNode of elementNodesInSelection) {
      if ($isListNode(elementNode)) {
        totalDepth = Math.max($getListDepth(elementNode) + 1, totalDepth);
      } else if ($isListItemNode(elementNode)) {
        const parent = elementNode.getParent();
        if (!$isListNode(parent)) {
          throw new Error("ListMaxIndentLevelPlugin: A ListItemNode must have a ListNode for a parent.");
        }

        totalDepth = Math.max($getListDepth(parent) + 1, totalDepth);
      }
    }

    return totalDepth <= maxDepth;
  };

  useEffect(() => {
    return editor.registerCommand(
      INDENT_CONTENT_COMMAND,
      () => !isIndentPermitted(maxDepth ?? 7),
      COMMAND_PRIORITY_HIGH
    );
  }, [editor, maxDepth]);

  return null;
};

export default ListMaxIndentLevelPlugin;
