const Attachments = {
  InternalName: "attachments",
  Title: "Anhänge",
};
const Categories = {
  InternalName: "categories",
  Title: "Kategorien",
};
const Contacts = {
  InternalName: "contacts",
  Title: "Kontakte",
};
const Conventions_ = {
  InternalName: "conventions",
  Title: "Konventionen",
};
const Partners = {
  InternalName: "partners",
  Title: "Partner",
};

export const Conventions = {
  InternalName: "conventions",
  Title: "Konventionen",
  Lists: {
    Attachments: Attachments,
    Categories: Categories,
    Contacts: Contacts,
    Conventions: Conventions_,
    Partners: Partners,
  },
};
