import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { GlobalNavigationMenuItemPost } from "../../../home/administration/globalNavigationItems/types";
import {
  deleteGlobalNavigationItems,
  upsertGlobalMenuItems,
} from "../../../home/administration/globalNavigationItems/webapi";
import useAbortController from "../../../hooks/useAbortController";
import useGlobalState from "../../../hooks/useGlobalState";
import useStatusBarState from "../../../hooks/useStatusBar";
import { MenuItem } from "../../../services/types";
import { getSourceFromUrl } from "../../../services/utils";
import { getGlobalMenuItems } from "../../../services/webapi";
import Button from "../../Button";
import { getString } from "../../errors/Errors";
import LoadingSpinnerSmall from "../../LoadingSpinnerSmall";
import EditableMenu from "../common/EditableMenu";
import NavigationButtons from "../common/NavigationButtons";

const EditGlobalNavigation: FC = () => {
  const [abortController, resetAbortController] = useAbortController();
  const setPage = useGlobalState((state) => state.setPage);
  const statusBar = useStatusBarState();
  const [isSaving, setIsSaving] = useState(false);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const navigateTo = useNavigate();

  const loadItems = async () => {
    const abortController = resetAbortController();
    const result = await getGlobalMenuItems(abortController.signal);
    if (result.error) {
      statusBar.addError(getString(result.error));
    } else if (result.data) {
      setMenuItems(result.data);
    }
  };

  useEffect(() => {
    setPage("Globales Menü bearbeiten");
    loadItems();

    return () => abortController && abortController.abort();
  }, []);

  const getParentId = (items: MenuItem[], item: MenuItem): number | null => {
    for (let i = 0; i < items.length; i++) {
      const parent = items[i];
      if (parent?.children)
        for (let j = 0; j < parent?.children.length; j++) {
          if (parent.children[j] === item) {
            return parent.id ?? null;
          }
        }
    }
    return null;
  };

  const saveItems = async () => {
    setIsSaving(true);
    const data = menuItems.reduce(
      (
        rv: {
          itemsToUpsert: GlobalNavigationMenuItemPost[];
          itemsIdsToDelete: number[];
        },
        item
      ) => {
        if (item.deleted && item.id) {
          rv.itemsIdsToDelete.push(item.id);
        } else if (!item.deleted) {
          const itemPost = {
            id: item.id,
            name: item.title,
            url: item.url,
            openNewTab: item.openNewTab,
            order: item.order,
            parentId: getParentId(menuItems, item),
          };
          rv.itemsToUpsert.push(itemPost);
        }
        return rv;
      },
      {
        itemsToUpsert: [],
        itemsIdsToDelete: [],
      }
    );
    const abortController = resetAbortController();
    const [errorUpsert, upsertDelete] = await Promise.all([
      upsertGlobalMenuItems(data.itemsToUpsert, abortController.signal),
      deleteGlobalNavigationItems(data.itemsIdsToDelete, abortController.signal),
    ]);
    errorUpsert && statusBar.addError(getString(errorUpsert));
    upsertDelete.error && statusBar.addError(getString(upsertDelete.error));
    await loadItems();

    setIsSaving(false);
  };

  const cancelEditMode = () => {
    navigateTo(getSourceFromUrl() ?? "/");
  };

  return (
    <div>
      <EditableMenu menuItems={menuItems} updateMenu={setMenuItems} isEditMode={true} />
      <hr />
      <NavigationButtons>
        <Button onclick={cancelEditMode}>Abbrechen</Button>
        <div>&nbsp;</div>
        <Button onclick={saveItems} disabled={isSaving}>
          Speichern
        </Button>
        {isSaving && (
          <>
            <div>&nbsp;</div>
            <LoadingSpinnerSmall />
          </>
        )}
      </NavigationButtons>
    </div>
  );
};

export default EditGlobalNavigation;
