import Link from "../../../components/Link";
import { IListviewField } from "../../../services/types";
import { getCurrentUrlAsSource } from "../../../services/utils";
import { CountryFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<CountryFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: CountryFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
};

export const code: IListviewField<CountryFull> = {
  title: "Kode",
  fieldName: "code",
  isSortable: true,
  renderListValue: (item: CountryFull) => item.code,
};

export const phoneCode: IListviewField<CountryFull> = {
  title: "Vorwahl",
  fieldName: "phoneCode",
  isSortable: true,
  renderListValue: (item: CountryFull) => item.phoneCode,
};
