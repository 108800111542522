import Link from "../../../components/Link";
import { IDialogField, IListviewField } from "../../../services/types";
import { getCurrentUrlAsSource } from "../../../services/utils";
import { dispUrl, SchemaFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<SchemaFull> & IDialogField<SchemaFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: SchemaFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
  renderDialogValue: (item: SchemaFull) => item.name,
};

export const internalName: IListviewField<SchemaFull> & IDialogField<SchemaFull> = {
  title: "Interner-Name",
  fieldName: "internalName",
  isSortable: true,
  renderListValue: (item: SchemaFull) => item.internalName,
  renderDialogValue: (item: SchemaFull) => item.internalName,
};

export const url: IListviewField<SchemaFull> & IDialogField<SchemaFull> = {
  title: "Url",
  fieldName: "url",
  renderListValue: (item: SchemaFull) => dispUrl(item),
  renderDialogValue: (item: SchemaFull) => dispUrl(item),
};
