import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Functionaries } from "../../../listsSettings/functionaries";
import { FileInfo, MenuOptionValue, PostPolicy, Result, SearchResult } from "../../../services/types";
import {
  createFileUploadUrl,
  deleteItem,
  deleteItems,
  exportExcel,
  functionaries,
  getFilters,
  getItem,
  getItems,
  insertItem,
  searchItems,
  updateItem,
} from "../../../services/webapi";
import { ChapterFull, ChapterPost } from "./types";

const chaptersUrl: string = `${functionaries}/${Functionaries.Lists.Chapters.InternalName}`;
export const insertChapter = async (chapter: ChapterPost, signal: AbortSignal): Promise<Result<ChapterFull | null>> =>
  insertItem(chaptersUrl, chapter, signal);

export const updateChapter = async (
  chapterId: number,
  chapter: ChapterPost,
  signal: AbortSignal
): Promise<Result<ChapterFull | null>> => updateItem(`${chaptersUrl}/${chapterId}`, chapter, signal);

export const deleteChapter = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${chaptersUrl}/${id}`, signal);

export const deleteChapters = (
  ids: number[],
  signal: AbortSignal
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(chaptersUrl, ids, signal);

export const getChapter = async (id: number, signal: AbortSignal): Promise<Result<ChapterFull | null>> =>
  getItem<ChapterFull>(`${chaptersUrl}/${id}`, signal);

export const getChapters = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<ChapterFull> | null>> => getItems<ChapterFull>(chaptersUrl, pagination, signal);

export const exportChaptersExcel = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<FileInfo | null>> => exportExcel(chaptersUrl, pagination, signal);

export const searchChapters = async (
  searchValue: string,
  signal?: AbortSignal
): Promise<Result<SearchResult<ChapterFull> | null>> => searchItems<ChapterFull>(chaptersUrl, searchValue, signal);

export const getChapterFilters = (fieldName: string, signal: AbortSignal): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(chaptersUrl, fieldName, signal);

export const updateLogo = (id: number, filename: string, signal: AbortSignal): Promise<Result<string | null>> =>
  updateItem<{ filename: string }, string>(`${chaptersUrl}/${id}/logo`, { filename: filename }, signal);

export const deleteLogo = (id: number, signal: AbortSignal) => deleteItem(`${chaptersUrl}/${id}/logo`, signal);

export const createLogoUploadUrl = (
  signal: AbortSignal,
  chapterId: number,
  filename: string
): Promise<Result<PostPolicy | null>> =>
  createFileUploadUrl(`${chaptersUrl}/${chapterId}/logo`, signal, {
    filename: filename,
  });
