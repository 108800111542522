import { FC } from "react";
import CheckBox from "../../../components/forms/CheckBox";
import FormData from "../../../components/forms/FormData";
import FormError from "../../../components/forms/FormError";
import FormLabel from "../../../components/forms/FormLabel";
import FormTable from "../../../components/forms/FormTable";
import FormTr from "../../../components/forms/FormTr";
import Input from "../../../components/forms/Input";
import Lookup from "../../../components/forms/Lookup";
import NumberInput from "../../../components/forms/NumberInput";
import { Administration } from "../../../listsSettings/administration";
import { ValidationErrors } from "../../../services/types";
import * as ListFields from "../lists/Fields";
import { searchLists } from "../lists/webapi";
import * as Fields from "./Fields";
import { ViewForm } from "./types";

interface NewEditTableProps {
  state: ViewForm;
  setState: React.Dispatch<React.SetStateAction<ViewForm | undefined>>;
  errors: ValidationErrors;
}

const NewEditTable: FC<NewEditTableProps> = ({ state, setState, errors }: NewEditTableProps) => {
  return (
    <FormTable>
      <FormTr>
        <FormLabel required>{Fields.name.title}</FormLabel>
        <FormData>
          <Input
            value={state.name}
            onChange={(value) =>
              setState({
                ...state,
                name: value,
              })
            }
          />
          <FormError error={errors[Fields.name.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.internalName.title}</FormLabel>
        <FormData>
          <Input
            value={state.internalName}
            onChange={(value) =>
              setState({
                ...state,
                internalName: value,
              })
            }
          />
          <FormError error={errors[Fields.internalName.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.showSearchBox.title}</FormLabel>
        <FormData>
          <CheckBox
            value={!!state.showSearchBox}
            onChange={(value) =>
              setState({
                ...state,
                showSearchBox: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.itemsPerPage.title}</FormLabel>
        <FormData>
          <NumberInput
            value={state.itemsPerPage}
            onChange={(value) =>
              setState({
                ...state,
                itemsPerPage: value,
              })
            }
          />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel required>{Fields.list.title}</FormLabel>
        <FormData>
          <Lookup
            disabled={!!state.list?.id}
            value={state.list?.name ?? ""}
            onChange={(value) =>
              setState({
                ...state,
                list: value,
              })
            }
            searchItems={searchLists}
            searchDialogTitle={Administration.Lists.Lists.Title}
            searchDialogFields={[ListFields.name, ListFields.schema]}
            getTitle={(item) => item.name ?? ""}
          />
          <FormError error={errors[Fields.list.fieldName]} />
        </FormData>
      </FormTr>
      <FormTr>
        <FormLabel>{Fields.schema.title}</FormLabel>
        <FormData disp>{state?.list?.schema?.name}</FormData>
      </FormTr>
    </FormTable>
  );
};

export default NewEditTable;
