import React, { FC } from "react";
import styles from "./Input.module.css";

interface EmailProps {
  value: string | null;
  onChange?: (x: string) => void;
}

const Email: FC<EmailProps> = (props: EmailProps) => {
  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  return <input type="text" defaultValue={props.value ?? ""} className={styles.input} onChange={update} />;
};

export default Email;
