import { FC } from "react";
import styles from "./TextInput.module.css";

type TextInputProps = Readonly<{
  label: string;
  onChange: (val: string) => void;
  value: string;
}>;

const TextInput: FC<TextInputProps> = ({ label, value, onChange }: TextInputProps) => {
  return (
    <div className={styles.wrapper}>
      <label className={styles.label}>{label}</label>
      <input type="text" className={styles.input} value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  );
};

export default TextInput;
