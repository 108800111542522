import { ErrorType } from "./services/types";

const strings = {
  genericText: "Es ist ein Fehler augetreten.",
  invalidText: "Bitte einen gültigen Wert für dieses Feld angeben.",
  notFoundText: "Die von Ihnen gewünschte Ressource wurde nicht gefunden.",
  forbiddenText: "Sie sind nicht berechtigt auf die von Ihnen gewünschte Ressource zuzugreifen.",
  notFound: "Element nicht gefunden",
  required: "Bitte einen Wert für dieses Pflichtfeld angeben.",
  duplicate: "Element mit diesem Wert schon vorhanden",
  deleteItemRestrictedByRelatedElements: `Dieses Element kann nicht gelöscht werden, da es von anderen Listen referenziert wird.`,
  deleteItemsRestrictedByRelatedElements: `Einige Element können nicht gelöscht werden, da sich andere auf diese beziehen.`,
  getMessageFromTypeError: (errorType: ErrorType) => {
    switch (errorType) {
      case "duplicate_value":
        return strings.duplicate;
      case "invalid_value":
        return strings.invalidText;
      case "missing_value":
        return strings.required;
      case "reference_not_found":
        return strings.notFound;
      default:
        return strings.genericText;
    }
  },
  add: "Hinzufügen",
  new: "Erstellen",
  edit: "Bearbeiten",
  editMetadata: "Metadaten Bearbeiten",
  delete: "Löschen",
  restore: "Wiederherstellen",
  remove: "Entfernen",
  close: "Schließen",
  cancel: "Abbrechen",
  save: "Speichern",
  excelExport: "Nach Excel exportieren",
  columnNotFilterable: "Diese Spalte kann nicht gefiltert werden.",
  columnNotSortable: "Diese Spalte kann nicht sortiert werden.",
  confirmDeleteItem: "Wollen Sie das Element wirklich löschen?",
  confirmDeleteItems: "Wollen Sie die Elemente wirklich löschen?",
  confirmRemoveItems: "Wollen Sie die referenzierten Elemente wirklich entfernen?",
  filteredItems: "Einige Elemente sind möglicherweise ausgeblendet. Beziehen Sie diese in Ihre Suche ein.",
  loading: "Lädt...",
  noItems: "In dieser Ansicht sind keine Elemente anzeigbar.",
  newElement: "Neues Element",
  notAllElementsDeleted: "Nicht alle Elemente konnten gelöscht werden",
};

export default strings;
