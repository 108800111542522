import { CustomError } from "../../../components/errors/Errors";
import { Pagination } from "../../../components/listview/Pagination";
import { Administration } from "../../../listsSettings/administration";
import { MenuOptionValue, Result, SearchResult } from "../../../services/types";
import {
  administration,
  deleteItem,
  deleteItems,
  getFilters,
  getItem,
  getItems,
  insertItem,
  putReference,
  removeReferencedItems,
  searchItems,
  updateItem,
  updateWithoutResult,
} from "../../../services/webapi";
import { GroupFull } from "../groups/types";
import { UserFull } from "../users/types";
import { GlobalNavigationItemFull, GlobalNavigationItemPost, GlobalNavigationMenuItemPost } from "./types";

const globalNavigationItemsUrl: string = `${administration}/${Administration.Lists.GlobalNavigationItems.InternalName}`;
export const insertGlobalNavigationItem = async (
  globalNavigationItem: GlobalNavigationItemPost,
  signal: AbortSignal
): Promise<Result<GlobalNavigationItemFull | null>> =>
  insertItem(globalNavigationItemsUrl, globalNavigationItem, signal);

export const updateGlobalNavigationItem = async (
  globalNavigationItemId: number,
  globalNavigationItem: GlobalNavigationItemPost,
  signal: AbortSignal
): Promise<Result<GlobalNavigationItemFull | null>> =>
  updateItem(`${globalNavigationItemsUrl}/${globalNavigationItemId}`, globalNavigationItem, signal);

export const upsertGlobalMenuItems = async (
  globalNavigationItems: GlobalNavigationMenuItemPost[],
  signal: AbortSignal
): Promise<CustomError | null> =>
  await updateWithoutResult<GlobalNavigationMenuItemPost[]>(globalNavigationItemsUrl, globalNavigationItems, signal);

export const deleteGlobalNavigationItem = (id: number, signal: AbortSignal): Promise<CustomError | null> =>
  deleteItem(`${globalNavigationItemsUrl}/${id}`, signal);

export const deleteGlobalNavigationItems = (
  ids: number[],
  signal: AbortSignal
): Promise<Result<{ [key: number]: boolean } | null>> => deleteItems(globalNavigationItemsUrl, ids, signal);

export const getGlobalNavigationItem = async (
  id: number,
  signal: AbortSignal
): Promise<Result<GlobalNavigationItemFull | null>> =>
  getItem<GlobalNavigationItemFull>(`${globalNavigationItemsUrl}/${id}`, signal);

export const getGlobalNavigationItems = async (
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<GlobalNavigationItemFull> | null>> =>
  getItems<GlobalNavigationItemFull>(globalNavigationItemsUrl, pagination, signal);

export const getGlobalNavigationItemFilters = (
  fieldName: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> => getFilters(globalNavigationItemsUrl, fieldName, signal);

export const searchGlobalNavigationItems = async (
  searchValue: string,
  signal: AbortSignal
): Promise<Result<SearchResult<GlobalNavigationItemFull> | null>> =>
  searchItems<GlobalNavigationItemFull>(globalNavigationItemsUrl, searchValue, signal);

export const getGlobalNavigationItemUsers = async (
  globalNavigationItemId: number,
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<UserFull> | null>> =>
  getItems<UserFull>(`${globalNavigationItemsUrl}/${globalNavigationItemId}/users`, pagination, signal);

export const getGlobalNavigationItemUsersFilters = (
  globalNavigationItemId: number,
  fieldname: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(`${globalNavigationItemsUrl}/${globalNavigationItemId}/users`, fieldname, signal);

export const getGlobalNavigationItemGroups = async (
  globalNavigationItemId: number,
  pagination: Pagination,
  signal: AbortSignal
): Promise<Result<SearchResult<GroupFull> | null>> =>
  getItems<GroupFull>(`${globalNavigationItemsUrl}/${globalNavigationItemId}/groups`, pagination, signal);

export const getGlobalNavigationItemGroupsFilters = (
  globalNavigationItemId: number,
  fieldname: string,
  signal: AbortSignal
): Promise<Result<MenuOptionValue[] | null>> =>
  getFilters(`${globalNavigationItemsUrl}/${globalNavigationItemId}/groups`, fieldname, signal);

export const addUser = (
  globalNavigationItem: GlobalNavigationItemFull,
  user: UserFull,
  signal: AbortSignal
): Promise<CustomError | null> => {
  const url = `${globalNavigationItemsUrl}/${globalNavigationItem.id}/user/${user.id}`;
  return putReference(url, signal);
};

export const addGroup = (
  globalNavigationItem: GlobalNavigationItemFull,
  group: GroupFull,
  signal: AbortSignal
): Promise<CustomError | null> => {
  const url = `${globalNavigationItemsUrl}/${globalNavigationItem.id}/group/${group.id}`;
  return putReference(url, signal);
};

export const removeGroups = (
  globalNavigationItem: GlobalNavigationItemFull,
  groups: GroupFull[],
  signal: AbortSignal
): Promise<Result<{ [key: string]: boolean } | null>> => {
  const url = `${globalNavigationItemsUrl}/${globalNavigationItem.id}/groups`;
  return removeReferencedItems(
    url,
    groups.map((x) => x.id),
    signal
  );
};

export const removeUsers = (
  globalNavigationItem: GlobalNavigationItemFull,
  users: UserFull[],
  signal: AbortSignal
): Promise<Result<{ [key: string]: boolean } | null>> => {
  const url = `${globalNavigationItemsUrl}/${globalNavigationItem.id}/users`;
  return removeReferencedItems(
    url,
    users.map((x) => x.id),
    signal
  );
};
