import classNames from "classnames";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { NavigateFunction } from "react-router-dom";
import useGlobalState from "../../hooks/useGlobalState";
import useStatusBarState from "../../hooks/useStatusBar";
import {
  IAction,
  IListviewField,
  Item,
  ListviewOptions,
  ListviewParameters,
  MenuOptionValue,
  Result,
  SearchResult,
} from "../../services/types";
import { getListviewOptions as getViewOptions } from "../../services/webapi";
import strings from "../../strings";
import Check from "../../svg/Check.svg?react";
import DataDiv from "../DataDiv";
import FileIcon from "../FileIcon";
import Title from "../Title";
import Actions from "../controls/Actions";
import styles from "./Listview.module.css";
import ListviewSearchBox from "./ListviewSearchBox";
import ListviewViews from "./ListviewViews";
import Message from "./Message";
import Navigation from "./Navigation";
import { DefaultPagination, FilterParam, Pagination, setPagination } from "./Pagination";
import TH from "./TH";
import ToggleAllHeader from "./ToggleAllHeader";

export interface ListviewBaseProps<T extends Item> {
  id: string;
  title?: string;
  documentLibraryOptions?: {
    field: IListviewField<T>;
  };
  allowSelectRows?: boolean;
  fields: IListviewField<T>[];
  searchResult: SearchResult<T> | null;
  pagination: Pagination;
  updateItems: (items: T[]) => void;
  isItemSelectable?: (item: T) => boolean;
  actions?: IAction[];
  otherActions?: IAction[];
  isLoading?: boolean;
  hidePaging?: boolean;
}

interface ListviewProps<T extends Item> extends ListviewBaseProps<T> {
  viewProperties?: {
    schema?: string;
    list: string;
    name: string;
    setListAndPage?: true;
  };
  reload: (pagination: Pagination) => void;
  getFilters?: (fieldname: string, abortSignal: AbortSignal) => Promise<Result<MenuOptionValue[] | null>>;
  additionalItemRowSettings?: {
    allowSelect?: boolean;
    renderRow: (item: T, rowIndex: number) => JSX.Element;
  };
}

const Listview = <T extends Item>(props: ListviewProps<T>) => {
  const setPage = useGlobalState((state) => state.setPage);
  const setListName = useGlobalState((state) => state.setListName);
  const statusBar = useStatusBarState();
  const navigateTo: NavigateFunction = useNavigate();
  const cellCheck = useRef<HTMLTableCellElement>(null);
  const currentSchema = useGlobalState((state) => state.schema);
  const [listviewOptions, setViewOptions] = useState<{
    loaded: boolean;
    data: ListviewOptions | null;
  }>({
    loaded: false,
    data: null,
  });

  const setFilters = (fieldName: string, options: MenuOptionValue[]) => {
    const params = props.pagination.filters
      ? props.pagination.filters.filter((x: FilterParam) => x.fieldname !== fieldName)
      : [];
    const param: FilterParam = {
      fieldname: fieldName,
      values: options.map((x) => ({ value: x })),
    };
    const newParams = [...params, param].filter((x: FilterParam) => x.values && x.values?.length > 0);

    const pagination: Pagination = {
      ...props.pagination,
      page: 1,
      filters: newParams,
    };
    updateHistory(pagination);
  };

  const updateHistory = (pagination: Pagination) => {
    setPagination(navigateTo, props.id, pagination);
    props.reload(pagination);
  };

  const navigateToPage = (page: number) => {
    const pagination: Pagination = {
      ...props.pagination,
      page: page,
    };
    updateHistory(pagination);
  };

  const setOrder = (order: string, desc: boolean) => {
    const pagination: Pagination = {
      ...props.pagination,
      orderByDescending: desc,
      orderBy: order,
    };
    updateHistory(pagination);
  };

  const toggleAll = () => {
    if (props.allowSelectRows && props.searchResult) {
      const oldItems = props.searchResult.items;
      const allCount = oldItems.length;
      const selectedCount = oldItems.filter((x) => x.selected).length;
      const allSelected = allCount === selectedCount ? false : true;

      const items = oldItems.map((x) => {
        return {
          ...x,
          selected: allSelected && (!props.isItemSelectable || props.isItemSelectable(x)),
        };
      });
      props.updateItems(items);
    }
  };

  const updateSelectedItems = (item: T, func: (x: T, y: T) => boolean | undefined): void => {
    if (props.allowSelectRows && props.searchResult) {
      const newArray: T[] = props.searchResult.items.map((x) => {
        return {
          ...x,
          selected: func(item, x) && (!props.isItemSelectable || props.isItemSelectable(x)),
        };
      });

      props.updateItems(newArray);
    }
  };

  const selectSingleItem = (item: T): void => {
    return updateSelectedItems(item, (item: T, x: T) => item === x && !x.selected);
  };

  const toggleItem = (item: T): void => {
    return updateSelectedItems(item, (item: T, x: T) => (item === x ? !item.selected : x.selected));
  };

  const search = async (searchKey: string) => {
    const pagination: Pagination = {
      ...props.pagination,
      page: 1,
      searchKey: searchKey,
    };
    updateHistory(pagination);
  };

  const clearSearch = () => {
    const pagination: Pagination = {
      ...props.pagination,
      searchKey: "",
    };
    updateHistory(pagination);
  };

  // the first data loaded is the listviewOptions (views, title, showSearchBox, itemsPerPage, ...)
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      if (props.viewProperties) {
        const viewProperties: ListviewParameters = {
          schema: props.viewProperties.schema || currentSchema.item?.internalName || null,
          list: props.viewProperties.list,
          view: props.viewProperties.name,
        };

        try {
          const viewOptions = await getViewOptions(viewProperties, abortController.signal);
          !abortController.signal.aborted &&
            viewOptions &&
            setViewOptions({
              loaded: true,
              data: viewOptions,
            });
          if (props.viewProperties.setListAndPage) {
            setPage(viewOptions?.view.name ?? null);
            setListName(viewOptions?.list.name ?? null);
          }
        } catch (e) {
          console.error(e);
          statusBar.addError(strings.genericText);
        }
      } else {
        setViewOptions({
          loaded: true,
          data: null,
        });
      }
    })();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      {listviewOptions.loaded && (
        <div className={styles.container} id={`$listview_${props.id}`}>
          {props.title && <Title text={props.title} />}
          <div className={styles.bar}>
            <Actions actions={props.actions} otherActions={props.otherActions} />
            {listviewOptions.data?.view.showSearchBox && (
              <ListviewSearchBox defaultValue={props.pagination.searchKey} clearSearch={clearSearch} search={search} />
            )}
            {listviewOptions.data && <ListviewViews options={listviewOptions.data} />}
          </div>
          <div>
            <table cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  {props.allowSelectRows && <ToggleAllHeader toggleAll={toggleAll} />}
                  {props.documentLibraryOptions && (
                    <TH
                      key={props.documentLibraryOptions.field.fieldName}
                      title={props.documentLibraryOptions.field.title}
                      isFilterable={props.documentLibraryOptions.field.isFilterable}
                      isSortable={props.documentLibraryOptions.field.isSortable}
                      fieldName={props.documentLibraryOptions.field.fieldName}
                      order={props.pagination.orderBy}
                      orderByDescending={props.pagination.orderByDescending}
                      selectedOptions={
                        (props.pagination.filters &&
                          props.pagination.filters
                            .find((y) => y.fieldname === props.documentLibraryOptions?.field.fieldName)
                            ?.values?.map((x) => x.value)) ||
                        []
                      }
                      setOrder={setOrder}
                      getFilters={props.getFilters}
                      setFilters={setFilters}
                      formatter={props.documentLibraryOptions.field.renderMenuOptionValue}
                      icon={<FileIcon extension={"generic"}></FileIcon>}
                    />
                  )}
                  {props.fields.map((x: IListviewField<T>) => {
                    const selectedOptions =
                      (props.pagination.filters &&
                        props.pagination.filters
                          .find((y) => y.fieldname === x.fieldName)
                          ?.values?.map((x) => x.value)) ||
                      [];
                    return (
                      <TH
                        key={x.fieldName}
                        title={x.title}
                        isFilterable={x.isFilterable}
                        isSortable={x.isSortable}
                        fieldName={x.fieldName}
                        order={props.pagination.orderBy}
                        orderByDescending={props.pagination.orderByDescending}
                        selectedOptions={selectedOptions}
                        setOrder={setOrder}
                        getFilters={props.getFilters}
                        setFilters={setFilters}
                        formatter={x.renderMenuOptionValue}
                      />
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {props.searchResult?.items.map((item: T, index: number) => (
                  <Fragment key={`${props.id}_tr_${index}`}>
                    <tr
                      className={classNames(styles.row, { [styles["item-selected"]]: item.selected })}
                      onClick={(event) => {
                        if (!cellCheck.current?.contains(event.target as Node)) {
                          selectSingleItem(item);
                        }
                      }}
                    >
                      {props.allowSelectRows && (
                        <td
                          key={`${props.id}_td_toggle_${index}`}
                          className={styles["cell-check"]}
                          onClick={(event) => {
                            toggleItem(item);
                            event.stopPropagation();
                          }}
                          ref={cellCheck}
                        >
                          <div>
                            <Check />
                          </div>
                        </td>
                      )}
                      {props.documentLibraryOptions && (
                        <td key={`${props.id}_td_item_${props.documentLibraryOptions.field.fieldName}`}>
                          <div className={styles.icon}>
                            {props.documentLibraryOptions.field.renderListValue &&
                              props.documentLibraryOptions.field.renderListValue(item)}
                          </div>
                        </td>
                      )}
                      {props.fields.map((x: IListviewField<T>) => (
                        <td key={`${props.id}_td_item_${x.fieldName}`}>
                          <DataDiv>{x.renderListValue && x.renderListValue(item)}</DataDiv>
                        </td>
                      ))}
                    </tr>
                    {props.additionalItemRowSettings && props.additionalItemRowSettings.renderRow(item, index)}
                  </Fragment>
                ))}
              </tbody>
            </table>
            {props.searchResult && props.pagination.searchKey && <div>{strings.filteredItems}</div>}
            {props.hidePaging ||
              (props.searchResult &&
                props.searchResult.items.length > 0 &&
                props.searchResult.total > (props.pagination.itemsPerPage ?? DefaultPagination.itemsPerPage ?? 30) && (
                  <Navigation
                    id={props.id}
                    navigateToPage={navigateToPage}
                    pagination={props.pagination}
                    searchResult={props.searchResult}
                  ></Navigation>
                ))}

            {props.isLoading && <Message>{strings.loading}</Message>}

            {!props.pagination.searchKey && props.searchResult && props.searchResult?.items.length === 0 && (
              <Message>{strings.noItems}</Message>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Listview;
