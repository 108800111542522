import { FC, useEffect } from "react";
import { Route } from "react-router";
import { Routes } from "react-router-dom";
import NotFound from "../../components/errors/NotFound";
import useGlobalState from "../../hooks/useGlobalState";
import { Functionaries } from "../../listsSettings/functionaries";
import { getSchemaByInternalName } from "../../services/webapi";
import AllItemsAreas from "./areas/AllItems";
import DispFormAreas from "./areas/DispForm";
import EditFormAreas from "./areas/EditForm";
import NewFormAreas from "./areas/NewForm";
import AllItemsChapterElections from "./chapterElections/AllItems";
import NewFormChapterElections from "./chapterElections/NewForm";
import AllItemsChapterEvents from "./chapterEvents/AllItems";
import CalendarChapterEvents from "./chapterEvents/Calendar";
import DispFormChapterEvents from "./chapterEvents/DispForm";
import EditFormChapterEvents from "./chapterEvents/EditForm";
import NewFormChapterEvents from "./chapterEvents/NewForm";
import AllItemsChapterEventsCategories from "./chapterEventsCategories/AllItems";
import DispFormChapterEventsCategories from "./chapterEventsCategories/DispForm";
import EditFormChapterEventsCategories from "./chapterEventsCategories/EditForm";
import NewFormChapterEventsCategories from "./chapterEventsCategories/NewForm";
import AllItemsChapters from "./chapters/AllItems";
import DispFormChapters from "./chapters/DispForm";
import EditFormChapters from "./chapters/EditForm";
import NewFormChapters from "./chapters/NewForm";
import Default from "./Default";
import AllItemsDistricts from "./districts/AllItems";
import DispFormDistricts from "./districts/DispForm";
import EditFormDistricts from "./districts/EditForm";
import NewFormDistricts from "./districts/NewForm";
import AllItemsFunctionaries from "./functionaries/AllItems";
import DispFormFunctionaries from "./functionaries/DispForm";
import EditFormFunctionaries from "./functionaries/EditForm";
import InsertBatch from "./functionaries/InsertBatch";
import NewFormFunctionaries from "./functionaries/NewForm";
import AllItemsLegislatures from "./legislatures/AllItems";
import DispFormLegislatures from "./legislatures/DispForm";
import EditFormLegislatures from "./legislatures/EditForm";
import NewFormLegislatures from "./legislatures/NewForm";
import AllItemsRoles from "./roles/AllItems";
import DispFormRoles from "./roles/DispForm";
import EditFormRoles from "./roles/EditForm";
import NewFormRoles from "./roles/NewForm";

const Home: FC = () => {
  const setCurrentSchema = useGlobalState((state) => state.setCurrentSchema);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      const schema = await getSchemaByInternalName(Functionaries.InternalName, abortController.signal);
      schema.data && setCurrentSchema(schema.data);
    })();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Default></Default>} />

      <Route path={`_lists/${Functionaries.Lists.ChapterEvents.InternalName}`}>
        <Route index element={<AllItemsChapterEvents />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsChapterEvents />} />
          <Route path="calendar" element={<CalendarChapterEvents />} />
          <Route path="new" element={<NewFormChapterEvents />} />
          <Route path="disp/:id" element={<DispFormChapterEvents />} />
          <Route path="edit/:id" element={<EditFormChapterEvents />} />
        </Route>
      </Route>

      <Route path={`_lists/${Functionaries.Lists.Areas.InternalName}`}>
        <Route index element={<AllItemsAreas />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsAreas />} />
          <Route path="new" element={<NewFormAreas />} />
          <Route path="disp/:id" element={<DispFormAreas />} />
          <Route path="edit/:id" element={<EditFormAreas />} />
        </Route>
      </Route>

      <Route path={`_lists/${Functionaries.Lists.ChapterEventsCategories.InternalName}`}>
        <Route index element={<AllItemsChapterEventsCategories />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsChapterEventsCategories />} />
          <Route path="new" element={<NewFormChapterEventsCategories />} />
          <Route path="disp/:id" element={<DispFormChapterEventsCategories />} />
          <Route path="edit/:id" element={<EditFormChapterEventsCategories />} />
        </Route>
      </Route>

      <Route path={`_lists/${Functionaries.Lists.Chapters.InternalName}`}>
        <Route index element={<AllItemsChapters />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsChapters />} />
          <Route path="new" element={<NewFormChapters />} />
          <Route path="disp/:id" element={<DispFormChapters />} />
          <Route path="edit/:id" element={<EditFormChapters />} />
        </Route>
      </Route>

      <Route path={`_lists/${Functionaries.Lists.Districts.InternalName}`}>
        <Route index element={<AllItemsDistricts />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsDistricts />} />
          <Route path="new" element={<NewFormDistricts />} />
          <Route path="disp/:id" element={<DispFormDistricts />} />
          <Route path="edit/:id" element={<EditFormDistricts />} />
        </Route>
      </Route>

      <Route path={`_lists/${Functionaries.Lists.Roles.InternalName}`}>
        <Route index element={<AllItemsRoles />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsRoles />} />
          <Route path="new" element={<NewFormRoles />} />
          <Route path="disp/:id" element={<DispFormRoles />} />
          <Route path="edit/:id" element={<EditFormRoles />} />
        </Route>
      </Route>

      <Route path={`_lists/${Functionaries.Lists.Functionaries.InternalName}`}>
        <Route index element={<AllItemsFunctionaries />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsFunctionaries />} />
          <Route path="new" element={<NewFormFunctionaries />} />
          <Route path="insertBatch" element={<InsertBatch />} />
          <Route path="disp/:id" element={<DispFormFunctionaries />} />
          <Route path="edit/:id" element={<EditFormFunctionaries />} />
        </Route>
      </Route>

      <Route path={`_lists/${Functionaries.Lists.Legislatures.InternalName}`}>
        <Route index element={<AllItemsLegislatures />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsLegislatures />} />
          <Route path="new" element={<NewFormLegislatures />} />
          <Route path="disp/:id" element={<DispFormLegislatures />} />
          <Route path="edit/:id" element={<EditFormLegislatures />} />
        </Route>
      </Route>

      <Route path={`_lists/${Functionaries.Lists.ChapterElections.InternalName}`}>
        <Route index element={<AllItemsChapterElections />} />
        <Route path="_pages">
          <Route path="all_items" element={<AllItemsChapterElections />} />
          <Route path="new" element={<NewFormChapterElections />} />
        </Route>
      </Route>

      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default Home;
