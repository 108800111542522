import { AutoLinkPlugin, LinkMatcher } from "@lexical/react/LexicalAutoLinkPlugin";
import { FC } from "react";

interface Match {
  index: number;
  length: number;
  text: string;
  url: string;
}

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const EMAIL_MATCHER =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

const CustomAutoLinkPlugin: FC = () => {
  const getFirstMatch = (match: RegExpExecArray | null): string => match?.[0] ?? "";

  const MATCHERS: Array<LinkMatcher> = [
    (text: string): Match | null => {
      const match: RegExpExecArray | null = URL_MATCHER.exec(text);
      const firstMatch = getFirstMatch(match);
      return (
        match && {
          index: match.index,
          length: firstMatch.length,
          text: firstMatch,
          url: firstMatch,
        }
      );
    },
    (text: string): Match | null => {
      const match: RegExpExecArray | null = EMAIL_MATCHER.exec(text);
      const firstMatch = getFirstMatch(match);
      return (
        match && {
          index: match.index,
          length: firstMatch.length,
          text: firstMatch,
          url: `mailto:${firstMatch}`,
        }
      );
    },
  ];
  return <AutoLinkPlugin matchers={MATCHERS} />;
};

export default CustomAutoLinkPlugin;
