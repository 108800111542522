import { FC } from "react";
import styles from "./LoadingSpinner.module.css";

const LoadingSpinner: FC = () => (
  <div className={styles["lds-default"]}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default LoadingSpinner;
