import { FC, useEffect } from "react";
import useGlobalState from "../../hooks/useGlobalState";
import strings from "../../strings";
import { getTitleString } from "./Errors";

const Forbidden: FC = () => {
  const setPage = useGlobalState((state) => state.setPage);

  useEffect(() => {
    setPage(getTitleString(403));
  }, []);

  return <div>{strings.forbiddenText}</div>;
};
export default Forbidden;
