import { FC, useEffect, useState } from "react";
import Errors from "../../../components/errors/Errors";
import Listview from "../../../components/listview/Listview";
import { DefaultPagination, getPagination, Pagination } from "../../../components/listview/Pagination";
import useAbortController from "../../../hooks/useAbortController";
import { Administration } from "../../../listsSettings/administration";
import { ErrorCode, IListviewField, SearchResult, UserPermissions } from "../../../services/types";
import { getUserPermissions } from "../../../services/webapi";
import * as Fields from "./Fields";
import { OfficeFull } from "./types";
import { getOfficeFilters, getOffices } from "./webapi";

const AllItems: FC = () => {
  const listviewId = "offices-all-items";
  const fields: IListviewField<OfficeFull>[] = [Fields.name];

  const [offices, setOffices] = useState<SearchResult<OfficeFull> | null>(null);
  const [abortController, resetAbortController] = useAbortController();
  const [errorCode, setErrorCode] = useState<ErrorCode>();
  const [permissions, setPermissions] = useState<UserPermissions>();

  const pagination = getPagination(listviewId) || {
    ...DefaultPagination,
  };

  useEffect(() => {
    loadOffices(pagination);

    return () => {
      abortController && abortController.abort();
    };
  }, []);

  const loadOffices = async (pagination: Pagination) => {
    const abortController = resetAbortController();
    const permissions = await getUserPermissions(
      Administration.Lists.Offices.InternalName,
      Administration.InternalName,
      abortController.signal
    );
    if (!abortController.signal.aborted) {
      setPermissions(permissions);
      const canRead = permissions.schemaPermission && permissions.listPermissions.includes("read-all");
      !canRead && setErrorCode(403);
      if (canRead) {
        const result = await getOffices(pagination, abortController.signal);
        if (!abortController.signal.aborted) {
          result.data && setOffices(result.data);
          result.error && setErrorCode(result.error.code);
        }
      }
    }
  };

  return (
    <>
      {permissions &&
        (!errorCode ? (
          <Listview
            id={listviewId}
            viewProperties={{
              schema: Administration.InternalName,
              list: Administration.Lists.Offices.InternalName,
              name: "all_items",
              setListAndPage: true,
            }}
            fields={fields}
            reload={loadOffices}
            searchResult={offices}
            getFilters={getOfficeFilters}
            pagination={pagination}
            updateItems={(items) =>
              offices &&
              setOffices({
                ...offices,
                items: items,
              })
            }
          />
        ) : (
          errorCode && <Errors errorCode={errorCode} />
        ))}
    </>
  );
};

export default AllItems;
