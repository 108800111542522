import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import { SchemaFull } from "../schemas/types";
import * as Fields from "./Fields";

export interface CurrentNavigationItemFull extends CreationInformationItem, CurrentNavigationItemForm {
  id: number;
}

export interface CurrentNavigationItemPost {
  name: string;
  url: string | null;
  order: number | null;
  openNewTab: boolean;
  parentId: number | null;
  schemaId: number | null;
}

export interface CurrentNavigationMenuItemPost extends CurrentNavigationItemPost {
  id?: number;
}

export interface CurrentNavigationItemForm {
  name: string;
  url: string | null;
  order: number | null;
  openNewTab: boolean;
  parent: CurrentNavigationItemFull | null;
  schema: SchemaFull | null;
}

export const mapToPOST = (item: CurrentNavigationItemForm): CurrentNavigationItemPost => {
  return {
    name: item.name || "",
    url: item.url,
    order: item.order,
    openNewTab: !!item.openNewTab,
    parentId: item.parent?.id || null,
    schemaId: item.schema?.id || null,
  };
};

export const validate = (
  state: CurrentNavigationItemForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.name) {
    errors[Fields.name.fieldName] = strings.required;
  }
  if (state.order && (state.order > 2147483647 || state.order < 0)) {
    errors[Fields.order.fieldName] = strings.invalidText;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};
