import { FC } from "react";
import LoadingSpinner from "../LoadingSpinner";
import styles from "./LoadingDialog.module.css";
import Modal from "./Modal";

interface LoadingDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const LoadingDialog: FC<LoadingDialogProps> = ({ isOpen, onClose }: LoadingDialogProps) => (
  <>
    {isOpen && (
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className={styles.container}>
          <LoadingSpinner />
          <div>Bitte warten</div>
        </div>
      </Modal>
    )}
  </>
);

export default LoadingDialog;
