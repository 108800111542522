import { CreationInformationItem, ValidationErrors } from "../../../services/types";
import strings from "../../../strings";
import { ListFull } from "../lists/types";
import * as Fields from "./Fields";

export interface ViewFull extends CreationInformationItem {
  id: number;
  name: string;
  internalName: string;
  showSearchBox: boolean;
  showTitle: boolean;
  itemsPerPage: number | null;
  list: ListFull;
}

export interface ViewForm {
  name: string | null;
  internalName: string | null;
  showSearchBox: boolean;
  itemsPerPage: number | null;
  list: ListFull | null;
}

export interface ViewPost {
  name: string;
  internalName: string;
  showSearchBox: boolean;
  itemsPerPage: number;
  listId: number;
}

export const mapToPOST = (item: ViewForm): ViewPost => {
  return {
    name: item.name || "",
    internalName: item.internalName || "",
    showSearchBox: !!item.showSearchBox,
    itemsPerPage: item.itemsPerPage ?? 0,
    listId: item.list?.id || 0,
  };
};

export const validate = (
  state: ViewForm,
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>
): boolean => {
  const errors: ValidationErrors = {};
  if (!state.name) {
    errors[Fields.name.fieldName] = strings.required;
  }
  if (!state.internalName) {
    errors[Fields.internalName.fieldName] = strings.required;
  }
  if (!state.list?.id) {
    errors[Fields.list.fieldName] = strings.required;
  }
  setErrors(errors);
  return Object.keys(errors).length === 0;
};
